.application {
  footer{
    margin-top: 0;
  }
  .copy {
    p {
      color: $gray-1;
    }
  }
  #snippet-form-modal-launch{
    width: 100%;
    margin-bottom: 8px;

    & + .btn{
      width: 100%;
    }
  }
  .related-products {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .image-gallery {
    .carousel-slide {
      width: 100%;
      .img {
        height: 500px;
        margin-bottom: 10px;
      }
    }
    .carousel-nav {
      align-items: flex-start;
      margin: 32px -8px 0 -8px;
    }
    .carousel-cell {
      width: 33.32%;
      padding: 1px 8px;
      .img{
        width: 100%;
        height: 180px;
        transition: all .25s ease-in-out;
        margin-bottom: 10px;
        &:hover{
          box-shadow: inset 0 0 0 2px #00221F, inset 0 0 0 4px #fff;
        }
        &.active{
          box-shadow: inset 0 0 0 2px #00221F, inset 0 0 0 4px #fff;
        }
      }
    }
    .flickity-slider.left{
      transform: translateX(0%) !important;
    }
  }
  @media(min-width:992px) and (max-width:1301px){
    .image-gallery + div{
      .btn{
        margin-right: 1rem!important;
        width: 50%;
        position: relative;
        right: -1rem;
      }
    }
  }
  @media(max-width:992px){
    .image-gallery + div{
      .btn{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-right: 15%;
      }
    }
  }
  @media(min-width: 767px){
    .flickity-prev-next-button{
      transform: scale(.6);
      top: 35%;
      &.previous{
        left: -40px;
      }
      &.next{
        right: -41px;
      }
    }
    .flickity-page-dots{
      bottom: 0px;
    }
  }
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: 450px;
  column-gap: 32px;
  row-gap: 0;
  margin-top: 32px;
  .grid-item {
    grid-column-end: span 4;
    grid-row-end: span 1;

    @media(max-width:992px){
      grid-column-end: span 12 !important;
      grid-row-end: span 1 !important;
    }
    &.grid-item-thin {
      grid-column-end: span 3;
    }
    &.grid-item-wide {
      grid-column-end: span 6;
    }
    .img {
      width: 100%;
      height: calc(100% - 150px);
    }
    h3 {
      color: $gray-1;
      margin: 32px 0 8px 0;
    }
  }
}
.related-applications {
  margin-top:  2*$space-xl;
  margin-bottom: 2*$space-xl;
  h2{
    margin-bottom: $space-lg;
  }
  .img{
    width: 100%;
    height: 300px;
    border: 1px solid $gray-6;
    margin-bottom: 8px;
    transition: all .25s ease-in-out;
  }
  a{
    font: $weight-regular 22px/27px $beausite;
    transition: all .25s ease-in-out;
  }
}