@charset "UTF-8";

//AMQ
$blue: #039EFF;
$green: #00c05f;
$yellow: #f4ff39;
$orange: #ff5721;
$black: #00221f;
$cream: #ded8be;
$lightcream: #EFECDF;
$lightgray: #ebedec;
$navy: #03354C;
$darkgreen: #00553E;

// greys
$white: #ffffff;
$gray-1: #243332;
$gray-2: #495B55;
$gray-3: #78857F;
$gray-4: #9EACA5;
$gray-5: #BEC8C3;
$gray-6: #D3DCD7;
$gray-7: #EBEDEC;
$gray-8: #F7F7F7;

// other
$primary-color: $blue;
$text-color: $gray-2;
$text-color-alt: $gray-2;
$title-color: $black;
$title-color-alt: $gray-2;
$heading-color: $navy;
$link-color: $gray-2;
$link-hover-color: $primary-color;
$header-nav-link-color: $navy;
$body-background: $white;
$body-background-alt: $lightcream;
$acqua: $blue;
$error: #f14609;
$brand-gray-3: $gray-3;
$block-heading-color: #58595B;
$sidebar-heading-color: #333;
$border-color: #DCDCDC;
$input-border-color: $gray-4;
$form-submit-color: $navy;
$svg-default: #808285;
$svg-primary: $blue;

$sand: $cream;
$brand-gray-3: $gray-3;
$brand-gray-1: #1B3733;
$brand-gray-2: #495B55;

// spacing
$space-xs: 4px;
$space-sm: 8px;
$space-md: 16px;
$space-lg: 32px;
$space-lg2: 48px;
$space-xl: 64px;


$colors : (
  "primary": $primary-color,
  "blue": $blue,
  "green": $green,
  "yellow": $yellow,
  "orange": $orange,
  "black": $black,
  "cream": $cream,
  "lightgray": $lightgray,
  "navy": $navy,
  "darkgreen": $darkgreen,
  "white": $white,
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "gray-3": $gray-3,
  "gray-4": $gray-4,
  "gray-5": $gray-5,
  "gray-6": $gray-6,
  "gray-7": $gray-7,
  "gray-8": $gray-8
);

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
};

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
};