
@use "../base/typography";
@use "../base/variables";

.recommendations{

  h6{
    margin-bottom: 0;
    color: variables.$heading-color;
  }
  p{
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.4px;
    color: variables.$brand-gray-3;
  }
  h2{
    text-align: left;
    @include typography.h1;
  }
  .info{
    .btn{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      svg,img{
        margin-right: 8px;
        width: 25px;
        margin-top: -10px;
      }
      margin-bottom: 40px;
      min-width: 284px;
      min-height: 60px;
      position: relative;
  
      .dealer-spinner{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        $offset: 187;
        $duration: 1.4s;
        .path {
          stroke-dasharray: $offset;
          stroke-dashoffset: 0;
          transform-origin: center;
          animation:
            dash $duration ease-in-out infinite, 
            colorWhite ($duration*4) ease-in-out infinite;
        }
        
        @keyframes colorWhite {
          0% { stroke: #fff; }
          25% { stroke: #fff; }
          50% { stroke: #fff; }
          75% { stroke: #fff; }
          100% { stroke: #fff; }
        }
      }
  
    }
  }

  .example{
    p{
      text-transform: uppercase;
      color: variables.$navy;
      font-weight: 400px;
      letter-spacing: .5px;
      font-size: 14px;
    }
    position: relative;
    & > div{
      cursor: pointer;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 15px;
      img{
        width: 100%;
        opacity: 0;
      }
    }

    @media(max-width:992px){
      display: none;
    }
  }

  .recommendations-results{
    padding: 32px 0;
    background-color: variables.$lightcream;

    // & + .container{
    //   padding-top: 64px;
    // }

    h2{
      margin-bottom: 32px;
      border-bottom: 1px solid #C8C8C8;
      padding-bottom: 16px;
    }

    .product-card {
      background-color: variables.$white;
      padding: 8px;
      overflow: hidden;
      position: relative;
      margin-bottom: 32px;
      // @include hover-scale(1.03);
      // @include hover-shadow;
      .product-card__img {
        height: 200px;
        width: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
      }
      .product-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: flex-end;
        .product-footer-content {
          display: flex;
          flex-direction: row;
          padding: 9px 11px 9px 11px;
        }
        span {
          font: typography.$weight-regular 11px/13.2px typography.$beausite;
          color: variables.$gray-1;
          background: rgba(255,255,255,0.7);
          backdrop-filter: blur(3px);
          border-radius: 2px;
          margin-left: 2px;
          padding: 2px 10px;
        }

        .new-label {
          background-color: variables.$orange;
          padding: 4px 7px 4px;
          font: typography.$weight-bold 10px/10px typography.$beausite;
          color: variables.$white;
          border-radius: 2px;
          flex: 0;
        }
      }

      .product-card__name {
        padding: 16px 8px;
        padding-bottom: 0px;

        h4 {
          font-size: 16px;
          letter-spacing: .4px;
          line-height: 19px;
          color: #333333;
          margin-bottom: 0;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        span {
          color: variables.$gray-5;
          font-size: 14px;
          letter-spacing: .2px;
          line-height: 17px;
          min-height: 47px;
          display: block;
        }
      }

      transition: all .5s ease-in-out;
      &:hover{
        transform: scale(1.03);
        box-shadow: 0 20px 20px 0 rgba(0 ,0, 0, .1);
      }
    }

    .btn{
      &:hover{
        background-color: variables.$navy !important;
      }
    }
  }

  .recommendations-modal{
    background-color: rgba(0, 0, 0, .5);
    padding: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    opacity: 0;
    z-index: -1;
    transform: scale(0);
    transition: all .5s ease-in-out;
    @media(max-width: 768px){
      padding: 0px;
    }
    #close {
      position: absolute;
      z-index: 99;
      top: variables.$space-xl;
      right: variables.$space-xl;
      color: variables.$white;
      transform: scale(2);
      cursor: pointer;
      @media(max-width: 1024px){
        top: variables.$space-lg;
        right: variables.$space-lg;
      }
      @media(max-width: 768px){
        top: variables.$space-md;
        right: variables.$space-md;
      }
      svg {
        width: 14px;
        height: 14px;
        fill: #58595B;
  
        g,path {
          fill: #58595B;
        }
      }
    }
  
    &>div {
      width: 742px;
      height: 362px;
      background-color: variables.$white;
      margin: auto;
      padding: 40px 60px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      @media(max-width: 992px){
        width: 100%;
        height: fit-content;
      }
      @media(min-width:992px){
        margin-top: 150px;
      }
      .btn{
        padding-left: 20px;
        padding-right: 20px;
        min-width: 284px;
        min-height: 60px;
        max-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-right: 8px;
          width: 25px;
          margin-top: -18px;
          transform: translateY(5px);
          &.dealer-spinner{
            margin-top: 0;
          }
        }
      }
      h4 {
        font-size: 32px;
        letter-spacing: .4px;
        line-height: 40px;
        min-height: 80px;
        padding: 0 16px;
        margin: 0;
        @media(max-width: 768px){
          font-size: 18px;
          line-height: 19px;
        }
      }
  
      span {
        font-size: 20px;
        letter-spacing: .2px;
        color: variables.$gray-5;
        padding: 0 16px;
        display: block;
        margin-bottom: 32px;
        @media(max-width: 768px){
          font-size: 14px;
          line-height: 16px;
        }
      }
  
      img{
        width: 100%;
        margin-top: 32px;
        @media(min-width:992px){
          position: absolute;
          bottom: -50px;
          margin-top: 0;
        }
      }
  
    }
  
    &.active {
      opacity: 1;
      z-index: 999;
      transform: scale(1);
    }
  }
}