@use "base/mixins";
@use "base/typography";
@use "base/variables";

body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  background-color: variables.$body-background;

  &:not(.homepage){
    .nav-overlay{
      top: -16px;
    }
  }

  &>.wrapper {
    opacity: 0;
    position: relative;
  }

  .pace-progress{
    &.complete{
      transform: translate3d(100%, 0px, 0px) !important;
    }
  }

  h1 {
    text-align: left;
    margin-bottom: 10px;

    &+p {
      @include typography.h3;
      color: variables.$text-color;
      padding-bottom: variables.$space-md;
    }
  }

  pre {
    @include typography.p-base;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    color: variables.$orange;
  }
  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  .form-cta{
    @media(min-width:992px){
      margin-top: -64px;
    }
    h4{
      font-weight: 700;
      color:variables.$navy;
    }
    a{
      color: variables.$navy;
    }
  }
}

main {
  position: relative;
  min-height:  calc(100vh - 432px - 125px - 64px); // minus header and footer and margin on content

  // background-color: #f2f2f2;
  @media(min-width: 767px) {
    //margin-top: 64px;
  }

  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }
}

.carousel {
  img {
    width: 100%;
  }
}

form {
  margin-bottom: 2*variables.$space-xl;

  .freeform-row {
    .freeform-column {
      p {
        margin-bottom: 0;
        margin-top: variables.$space-md;
      }

      .freeform-instructions {
        color: variables.$gray-5 !important;
        font-style: normal !important;
      }

      padding: 10px 16px !important;
      margin: 0;

      h2 {
        padding-top: variables.$space-lg;
        margin-bottom: 0px;
      }

      h3 {
        padding-top: variables.$space-md;
        margin-bottom: 0px;
      }

      .freefrom-label {
        margin-top: variables.$space-sm;
      }

      // .errors{
      //   border-top: 2px solid #f14609;
      //   margin-top: 0;
      // }

      @media(max-width: 767px) {
        flex: 100% 0 0;
      }
    }

    @media(max-width: 767px) {
      flex-direction: column;
    }


    label {
      color: variables.$gray-3;
      font-size: 14px;
      line-height: 14px;
      display: flex !important;
      vertical-align: middle;
      align-items: baseline;

      &:after {
        color: variables.$orange !important;
      }

      &[for="form-input-adminEmail"]{
        display: none !important;

        & + input{
          display: none !important;
        }
      }
    }

    select{
      padding: 8px 10px;
      margin-top: 10px;
    }

    textarea {
      border: 1px solid variables.$gray-3;
      outline: none;
      margin-top: 10px;
      transition: all .25s ease-in-out;
      padding: 8px 10px;

      &.active {
        box-shadow: inset 0 0 4px 0 rgba(variables.$acqua, 0.75);
      }
    }

    label {
      color: variables.$gray-2;
      font-size: 15px;
      font-weight: normal !important;
      margin-bottom: 3px;

      &.active {
        color: variables.$acqua;
      }
    }

    input {
      margin-top: 10px;
      outline: none;
      border: 1px solid variables.$gray-3;
      padding: 8px 10px;
      transition: all .25s ease-in-out;
      width: 100%;

      &.active {
        border: 1px solid variables.$acqua;
        box-shadow: inset 0 0 4px 0 rgba(variables.$acqua, 0.75);
      }
    }

    button[type="submit"] {
      background-color: variables.$gray-3;
      color: variables.$white;
      padding: 15px 32px;
      border: none;
      text-transform: uppercase;
      letter-spacing: .5px;
      font-size: 12px;
      font-weight: typography.$weight-bold;
      cursor: pointer;
    }
  }
}

.aspect-ratio{
  position: relative;
  width: 100%;
  height: 0;
  margin-top: 16px;
  padding-bottom: 60%;
  
  iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; top: 0;
    border: none;
  }
}

.back-btn {
  display: flex;
  align-items: center;
  padding: 16px 0;
  svg {
    margin-right: 8px;
  }
}
input[type="text"],
input[type="password"] {
  padding: 8px 10px;
}

.flickity-page-dots .dot {
  background: variables.$white;

  &.is-selected {
    background: variables.$white;
  }
}

.flickity-button {
  background: transparent;
  top: 44%;

  &.next {
    img {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: transparent;

  }
}

.img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

#right-arrow {
  vertical-align: middle;
}

@-webkit-keyframes skrotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes skrotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

hr {
  height: 1px !important;
  color: variables.$border-color !important;
  margin: 20px 0px !important;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: variables.$navy;
  position: fixed;
  top: 50vh;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;

  -webkit-animation: skrotateplane 1.2s infinite ease-in-out;
  animation: skrotateplane 1.2s infinite ease-in-out;
  transition: all .5s ease-in-out;

  &.loaded {
    opacity: 0;
    display: none;
  }
}

/* Loading bar styline (Pace.js) */
.pace-activity {
  display: none !important;
}

.pace-progress {
  background: variables.$blue !important;
}

.pace-progress-inner {
  box-shadow: 0 0 10px variables.$acqua, 0 0 5px variables.$acqua !important;
}

.pagination {
  padding: variables.$space-md 0;
  margin: auto;
  margin-top: variables.$space-md;
  margin-bottom: variables.$space-lg;
  display: flex;
  justify-content: left;
  font-size: 18px;

  a,
  span {
    margin: auto 10px;
    position: relative;
    color: variables.$gray-4;
    transition: all .25s ease-in-out;

    &:hover {
      text-decoration: none;
      color: variables.$acqua;
    }

    &::before,
    &::after {
      top: -3px;
    }
  }

  .page-number {
    color: variables.$gray-3;
    position: relative;
    display: block;
    width: 30px;
    text-align: center;
    &:hover {
      background-color: variables.$gray-7;
    }

    &.current {
      color: variables.$acqua;
      border: 1px solid variables.$acqua;

      &::before {
        background-color: currentColor;
      }
    }
  }

  .prev-link::before {
    content: '';
    position: absolute;
    font-size: 0;
    line-height: 0;
  }

  .next-link::after {
    content: '';
    position: absolute;
    font-size: 0;
    line-height: 0;
  }
}

a.link-overlay {
  @include mixins.link-overlay;
}

#search {
  position: fixed;
  top: 0px;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all .25s ease-in-out;
  transform: translate(50%, -50%) scale(0, 0);
  opacity: 0;

  input[type="search"] {
    position: absolute;
    top: 50%;
    width: 25%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    font-size: 60px;
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    left: 0;
    right: 0;
  }

  input[type="submit"] {
    transform: scale(1.5);
    border-radius: 2px solid variables.$gray-2;
  }

  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    margin-top: 61px;
    margin-left: -70px;
    background-color: transparent;
  }

  .close {
    position: fixed;
    top: 64px;
    right: 64px;
    color: #fff;
    background-color: transparent;
    border-color: variables.$acqua;
    opacity: 1;
    padding: 10px 17px;
    font-size: 27px;
  }

  &.open {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 999;
  }
}

.error {
  input {
    margin: 0;
  }

  main {
    ul.nav-list__desktop {
      @include mixins.list-no-style;
      display: flex;
      justify-content: space-between;
      margin: 64px 0;
      flex-wrap: wrap;

      li.nav-item {
        &>a {
          text-transform: uppercase;
          font-weight: typography.$weight-bold;
          margin-bottom: variables.$space-md;
          display: block;
        }

        a {
          transition: all .25s ease-in-out;

          &:hover {
            color: variables.$acqua;
          }
        }

        .desktop-submenu {
          padding: 0;
          padding-bottom: 25px;
        }
      }
    }
  }
}

/*Navigation*/
.nav-overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0px;
  right: 0;
  height: 100%;
  width: 100%;
  background:linear-gradient(
    to bottom,
    rgba(0,0,0,.2),
    rgba(0,0,0,0) 50%
  );
  z-index: 99;
  transition: visibility 0.3s, opacity 0.3s ease-out;


  &.active {
    visibility: visible;
    opacity: 1;
  }
}

a.back {
  @media (max-width: 768px) {
    margin-bottom: variables.$space-md;
    display: block;
  }
  
  svg {
    margin-left: 3px;
    padding-top: 1px;
    transition: all .25s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(3px);
      fill: variables.$acqua;
    }
  }

  &:active {
    svg {
      transform: translateX(6px);
    }
  }
}

// filters
.filters-row {
  margin: 64px 0;
  position: relative;
  border: 1px solid variables.$input-border-color;
  width: 300px;
  display: inline-block;
  margin-right: variables.$space-md;

  select {
    border: 1px solid #979797;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    font-weight: typography.$weight-bold;
    color: #58595B;
    font-size: variables.$space-md;
    line-height: 19px;

    option {
      padding: 10px 13px;
    }
  }
}

/* filters */

/*the container must be positioned relative:*/
.filters-row select,
.freeform-dropdown select {
  display: none !important;
  /*hide original SELECT element:*/
}

.select-selected {
  color: variables.$gray-2;
  width: 100%;
  display: inline-block;
  border: 1px solid variables.$input-border-color;

  &:after {
    position: absolute;
    content: "";
    top: 23px;
    right: 8px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: variables.$gray-3 transparent transparent transparent;
    transition: all .4s ease-in-out;
  }

  &:before {
    position: absolute;
    content: "";
    top: 5px;
    right: 8px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: variables.$gray-3 transparent transparent transparent;
    transition: all .4s ease-in-out;
    transform: rotate(180deg);
  }
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  padding: 8px variables.$space-md;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  font-size: variables.$space-md;
}

.select-items div {
  padding: 8px 24px;
}

/*style items (options):*/
.select-items {
  position: absolute;
  top: 93%;
  left: -1px;
  right: 0;
  z-index: 99999999;
  background-color: white;
  font-size: variables.$space-md;
  border: 1px solid variables.$gray-2;
  border-top: none;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
  width: 100.7%;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: variables.$gray-2;
  color: variables.$white !important;
}

@media (max-width: 768px) {

  .filters-row,
  .filters {
    flex-direction: column;
  }

  .filters-row {
    margin: 4px;
    width: 46%;
    display: inline-block;
    vertical-align: top;

    a {
      padding: 3px 20px;
    }
  }
}

.freeform-dropdown {
  position: relative;

  .select-selected {
    border: 1px solid variables.$input-border-color;
    position: relative;
  }

  .select-items {
    top: 73px;
    left: 0;
    width: 250px;
    border-top: none;
  }
}

.error {
  color: variables.$orange;
}

.notes{
  color: variables.$gray-3;
}

.thankYou {
  main {
    position: relative;
    p {
      font-size: 20px;
    }

    ul.nav-list__desktop {
      @include mixins.list-no-style;
      display: flex;
      justify-content: space-between;
      margin: 64px 0;
      margin-top: 128px;
      flex-wrap: wrap;

      li.nav-item {
        &>a {
          text-transform: uppercase;
          font-weight: typography.$weight-bold;
          margin-bottom: variables.$space-md;
          display: block;
        }
        a {
          transition: all .25s ease-in-out;
          &:hover {
            color: variables.$acqua;
          }
        }
        .desktop-submenu {
          padding: 0;
          padding-bottom: 25px;
        }
      }
    }
  }
}

.modal{
  background-color: rgba(0,0,0,.5);
  z-index: -1;
  opacity: 0;
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  .modal-dialog{
    max-width: 800px;
    .close {
      color: white;
      text-shadow: none;
    }
  }
  .modal-content{
    padding: 32px 16px;
    text-align: center;
    h2{
      line-height: 40px;
      margin-bottom: 16px;
    }
    .btn{
      color: variables.$gray-4;
    }
    button{
      position: absolute;
      right: -32px;
      top: -32px;
      transform: scale(2);
      @media(max-width: 767px) {
        right: 10px;
        top: -40px;
      }
    }
  }

  &.active{

    opacity: 1;
    z-index: 999;
  }

}

.blank-image{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
}

.freeform-errors{
  margin: 0;
    border-top: variables.$error solid 2px !important;
    margin: 0  !important;

 li{
      color: variables.$error !important;
    }
  
}

.freeform-form-has-errors {
  color: variables.$error !important;
}

.product-note{
  font-size: 12px;
  color: variables.$error;
  margin: 0;
  line-height: 14px;
  padding-bottom: 5px;
  @media(min-width: 767px){
    position: absolute;
    bottom: 0px;
    right: 15px;
    text-align: right;
  }
}

.breadcrumbs{
  color: variables.$gray-3;
}

.featured-product-lines{
  h2{
    margin-bottom: variables.$space-lg;
    text-align: center;
  }
  .wrapper{
    display: flex;
    justify-content: center;
    
    .wrapper-item{
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 220px;
      margin: 1px;
      position: relative;
      @media(min-width:992px){
        flex: 0 0 15%;
        max-width: 15%;
      }

      @media(max-width:1023px){
        height: 170px;
        margin-bottom: 5px;
      }
      &:before{
        content:"";
        @include mixins.overlay;
        background: linear-gradient(180deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0,0.31) 100%);
      }
      svg{
        margin-left: 8px;
        g{
          fill: variables.$white !important;
        }
      }
      span{
        text-align: left;
        position: absolute;
        bottom: 16px;
        left: 16px;
        color: variables.$white;
        width: 81%;
        @media(max-width: 1025px){
          bottom: 8px;
          left: 8px;
        }
      }
      
      .overlay{
        opacity: 0;
        background: linear-gradient(180deg, rgba(34,34,34,0.65) 0%, rgba(34,34,34,0.9) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .5s ease-in-out;
        padding: 16px;
        padding-top: 60px;
        z-index: 10;
        // span{
        //   color: $white;
        // }

        @media(max-width: 1025px){
          padding: 8px !important;
        }
        a{
          display: block;
          text-align: left;
          color: rgba( variables.$white, .7 );
          font-weight: typography.$weight-bold;
          &:hover {
            color: variables.$white;
          }
          svg{
            g{
              fill: variables.$acqua !important;
            }
          }
        }
      }
      &:hover{
        .overlay{
          opacity: 1;
        }
      }
    }

    @media(max-width:992px){
      flex-direction: column;
      .wrapper-item{
        flex-basis: auto;
        background-position: center;
      }
    }
  }
}

.collection-modal,
.embed-modal{
  background-color: rgba(0,0,0,.5);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  & > div{
    background-color: variables.$white;
    box-shadow: 0px 10px 20px rgba(0, 34, 31, 0.25);
    padding: 16px;
    position: absolute;
    overflow-y: scroll;
    top: 0%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 95vh;
    z-index: 9999;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #78857F; 
    }
    svg{
      position: absolute;
      top: 8px;
      right: 16px;
      transform: scale(.5);
      cursor: pointer;
    }
    .row{
      display: flex;
      flex-wrap: wrap;
  
      .config{
        width: 50%;
        padding: 16px;
        margin-bottom: 32px;
        label{
          text-align: center;
          text-transform: uppercase;
          // font-family: $swiss-light;
          color: #78857F;
          display: block;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.457px;
          margin: auto;
        }
        img{
          max-width: 100%;
          margin-bottom: 16px;
        }

        @media(min-width:992px){
          width: 20%;
        }
      }
    }
  
    @media(min-width:992px){
      max-width: 90%;
      min-height: 300px;
      max-height: 570px;
      padding: 40px 64px;
      top: 25%;
    }
  }

}

.embed-modal{
  & > div{
    .aspect-ratio{
      padding-bottom: 60%;
      margin-top: 16px;
    }
    @media(min-width:992px){
      top: 15%;
      max-height: 90%;
      // min-height: 70vh;
    }
  }
}

.grecaptcha-badge{
  display: none !important;
}

.polite-popup{
  padding: 22px;
  position: fixed;
  top: 50%;
  right: -150%;
  transition: all 1s ease-in-out;
  width: 400px;
  height: auto;
  z-index: 99;
  box-shadow: 0px 10px 20px rgba(0, 34, 31, 0.25);

  &.bg-green{
    background: variables.$green;
    p,a,span{
      color: variables.$white;
    }
  }

  &.bg-beige{
    background: variables.$lightcream;
    p,a,span{
      color: variables.$navy;
    }
  }

  &:hover{
    .close{
      opacity: 1;
    }
  }

  &.show{
    right: 5%;
  }

  a{
    border: 2px solid transparent;
    padding: 16px 12px;
  }

  span{
    float: right;
    margin-top: 0;
    cursor:pointer;
    font-weight: 400;
    display: block;
    padding: 16px 12px;
  }

  img{
    max-width: 100px;
    margin-bottom: 32px;
  }
  svg.close{
    float: right;
    cursor: pointer;
    top: 0px;
    right: 0;
    position: relative;
    opacity: 1 !important;
    stroke: #FFF;
    path{
      stroke: #FFF;
      opacity: 1;
    }
  }

  p{
    font: typography.$weight-bold 30px/34px typography.$beausite;
    margin-bottom: 64px;
  }

  &.product{
    background: variables.$lightcream;
    p,a,span{
      color: variables.$navy;
    }

    span{
      display: block;
      padding: 16px 12px;
    }

    a{
      position: relative;
      top: 16px;
    }

    svg.close{
      stroke: #D9D9D9;
      path{
        stroke: #D9D9D9;
      }
    }

    &:hover{
      a{
        background-color: variables.$white;
      }
    }
  }

  &.plan{
    background: variables.$green;
    p,a,span{
      color: variables.$white;
    }

    a{
      position: relative;
      top: 16px;
    }

    &:hover{
      a{
        border: 2px solid variables.$white;
      }
    }
  }

  &.form{
    a{
      bottom: -14px;
      position: relative;
      padding-left: 0;
    }
  }
}

$offset: 187;
$duration: 1.4s;

.dealer-spinner {
  animation: rotator $duration linear infinite;
  background: transparent;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
	0% { stroke: #00AEEF; }
  25% { stroke: #00AEEF; }
	50% { stroke: #00AEEF; }
	75% { stroke: #00AEEF; }
  100% { stroke: #00AEEF; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: calc($offset/4);
   transform:rotate(135deg);
 }
 100% {
  //  stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}

.error-popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  .error-popup-content{
    background: variables.$white;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    max-width: 500px;
    width: 100%;
    text-align: center;
    p{
      font-size: 16px;
      margin-bottom: 0;
      text-align: left;
    }
    button{
      margin-top: 32px;
      padding: 16px 32px;
      cursor: pointer;
      font-size: 16px;
    }
  }

  &.show{
    display: flex;
  }
}