@use "variables";

@font-face {
  font-family: 'Beausite Classic';
  src: url('/assets/fonts/BeausiteClassicWeb-Regular.woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Beausite Classic';
  src: url('/assets/fonts/BeausiteClassicWeb-Semibold.woff');
  font-weight: 600;
}

$beausite: 'Beausite Classic', Arial, Helvetica, sans-serif;

$weight-regular: 400;
$weight-bold: 600;

@mixin h1{
  font: $weight-bold 46px/55px $beausite;
  color: variables.$heading-color;
  letter-spacing: -.76px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 40px;
  }
}

@mixin h2{
  font: $weight-regular 30px/36px $beausite;
  letter-spacing: -0.34px;
  color: variables.$heading-color;
}

@mixin h3{
  font: $weight-regular 22px/27px $beausite;
  letter-spacing: -0.4px;
  color: variables.$title-color-alt;
}

@mixin h4{
  font: $weight-regular 19px/34px $beausite;
  letter-spacing: -0.34px;
  color: variables.$black;
}

@mixin h5{
  font: $weight-bold 14px/14px $beausite;
  text-transform: uppercase;
  letter-spacing: .7px;
}

@mixin h6{
  font: $weight-bold 13px/34px $beausite;
  letter-spacing: 0.4px;
  color: variables.$title-color-alt;
  text-transform: uppercase;
}

@mixin h7{
  font: 14px/18px $beausite;
  display: block;
  text-transform: initial;
}


@mixin p-base {
  font: $weight-regular 18px/28px $beausite;
  letter-spacing: .2px;
  color: variables.$text-color;
}

@mixin tout-title{
  font: $weight-bold 113px/112px $beausite;
  color: variables.$white;
  @media (max-width: 959px) {
    font-size: 35px;
    line-height: 38px;
  }
}

@mixin tout-copy{
  font: $weight-regular 22px/27px $beausite;
}

@mixin swiss-medium{
  font-weight: $weight-bold;
  font-size: 16px;
  color: variables.$gray-2;
  letter-spacing: .4px;
}

@mixin swiss-small {
  font-weight: $weight-regular;
  font-size: 16px;
  line-height: 22px;
  color: variables.$gray-4;
  letter-spacing: .2px;
}

h1,h2,h3,p,a,i{
  transition: all .25s ease-in-out;
}

h1, .h1{
  @include h1;
}

h2, .h2{
  @include h2;
}

h3, .h3{
  @include h3;
}

h4, .h4{
  @include h4;
}

h5, .h5{
  @include h5;
}

h6, .h6{
  @include h6;
}

h7, .h7{
  @include h7;
}

body, p, li, td, label, span {
  font-family: $beausite;
}
body main p a{
  text-decoration: underline;
}
p {
  @include p-base;
}
li {
  color: variables.$text-color;
}
b, strong {
  font-weight: $weight-bold;
}

.text-sm {
  font-size: 16px;
  line-height: 24px;
}
.text-xs {
  font-size: 14px;
}

a{
  color: variables.$gray-2;
  &:hover{
    text-decoration: none;
  }
}

main{
  p{
    a{
      color: variables.$link-color;
      text-decoration: underline;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
        color: variables.$link-hover-color;
      }
    }
  }
}

.copy a {
  position: relative;
  display: inline-block;
  color: variables.$gray-2;
  text-decoration: underline;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   width: 0%;
  //   height: 1px;
  //   bottom: 0;
  //   left: 0;
  //   opacity: 0.5;
  //   background-color: $gray-2;
  //   transition: ease-in-out 0.25s;
  
  // }
  &:hover{
    color: variables.$blue;
    // &:before {
    //   width: 100%
    // }
  }
}