@mixin bg-img($img, $size: cover, $posX: center, $posY: center, $repeat: no-repeat) {
  background: url("/assets/images/#{$img}") $posX $posY $repeat;
  background-size: $size;
}

@mixin block-fill-container {
  display: block;
  height: 100%;
  width: 100%;
}

@mixin absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@mixin bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin img-contain {
  img {
    max-width: 100%;
  }
}

@mixin keep-aspect-ratio($x, $y, $width) {
  position: relative;
  content: "";
  width: $width;
  display: inline-table;
  padding-top: ($y / $x) * $width;
  img {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
  }
}

@mixin list-no-style {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

@mixin overlay {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
}

@mixin link-style($color, $decor:none) {
  color: $color;
  text-decoration: $decor;
  &:hover,
  &:active,
  &:focus {
    color: $color;
    text-decoration: $decor;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin link-overlay{
  position: absolute;
  top:0; 
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

@mixin hover-fade{
  opacity: 0;
  transition: opacity .5s ease-in-out;
  &:hover{
    opacity: 1;
  }
}

@mixin hover-shadow{
  transition: all .5s ease-in-out;
  &:hover{
    box-shadow: 0 20px 20px 0 rgba(0,0,0,0.10);
  }
}

@mixin hover-scale($value){
  transition: all .5s ease-in-out;
  &:hover{
    transform: scale($value);
  }
}

@mixin iconStyle{
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li{
      width: 49%;
      padding: 2px 12px;
      justify-content: space-between;
      display: block !important;

      span{
        display: inline-block;
        width: 80%;
        line-height: 1.1em;

        &.price{
          text-align: right;
          width: 20%;
        }
      }

      &:before{
        content:"";
        width: 100%;
        height: 83px;
        margin-top: 8px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
      }
    }
  }
}