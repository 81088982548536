.typicalsPublic {

  // li{
  //   list-style: none !important;
  // }
  h2 {
    color: #333333;
    font-size: 30px;
  }

  .description {
    @include p-base();

    h6{
      font-size: .8rem;
    }

    ul {
      list-style-type: square
    }
  }

  .product-categories {
    h3 {
      text-transform: uppercase;
      margin-bottom: 8px;
      font-weight: $weight-bold;
      font-size: 13px;
      color: $gray-1;
      letter-spacing: .4px;
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      flex-wrap: wrap;

      li {
        margin-right: 27px;
        margin-bottom: 8px;

        a {
          color: $acqua;
          font-size: 18px;
          letter-spacing: .2px;
        }
      }
    }
  }

  .banner {
    .col-lg-5 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    ul {
      @include list-no-style;
      display: flex;
      padding: 34px 0;

      li {
        &:not(:first-child) {
          padding-left: 16px;
        }

        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: $weight-bold;
        letter-spacing: .4px;
        color: $gray-2;

        a {
          display: flex;
          align-items: center;
          font-size: 12px;

          svg {
            &:first-child {
              margin-right: 5px;
              transform: scale(.8);
            }

            &:last-child {
              margin-left: 8px;
            }
          }
        }
      }

      @media(min-width: 992px) {
        justify-content: flex-end;
      }
    }

    hr {
      margin: 0;
    }
  }

  .typical {
    .row {
      &>div {
        position: relative;

        @media(max-width: 767px) {
          &.specifications {
            margin-top: 100px;
          }
        }
      }
    }

    .carousel-main {
      position: relative;

      img {
        width: 100%;
      }

      @-webkit-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          opacity: 0.0;
        }

        50% {
          opacity: 1.0;
        }

        100% {
          -webkit-transform: scale(1.2, 1.2);
          opacity: 0.0;
        }
      }

      .help-dialog {
        max-height: 45px !important;
        height: auto;
        position: absolute;
        top: 8px;
        left: 8px;
        background-color: rgba(255, 255, 255, .87);
        border: 1px solid #00AEEF;
        padding: 8px;
        display: flex;
        align-items: center;
        box-shadow: 0 0 2px 2px #90E0FE;

        span {
          display: inline-block;
          height: 8px;
          width: 8px;
          margin-right: 8px;
          border-radius: 100%;
          background-color: #00AEEF;
          box-shadow: 0 0 1px 1px #90E0FE;
        }

        @media(max-width:767px) {
          bottom: -50px;
        }
      }

      .hotspot {
        .hotspot-target {
          height: 20px;
          width: 20px;
          background-color: #00AEEF;
          border: 1px solid #fff;
          border-radius: 100%;
          padding: 4px;
          position: absolute;
          top: 35px;
          z-index: 2;

          &:after {
            content: "";
            height: 24px;
            width: 24px;
            border: 2px solid #fff;
            border-radius: 100%;
            padding: 4px;
            position: absolute;
            top: -3px;
            left: -3px;
            z-index: 1;
            opacity: 0;
            box-shadow: 0 0 2px 2px rgba(255, 255, 255, 1);
          }

          &.active {
            box-shadow: 0 0 0px 4px rgba(255, 255, 255, 1);
          }
        }

        &:nth-child(odd) {
          a.hotspot-target {
            &:after {
              -webkit-animation: pulsate 3s ease-out;
              -webkit-animation-iteration-count: infinite;

            }
          }

        }

        &:nth-child(even) {
          a.hotspot-target {
            &:after {
              -webkit-animation: pulsate 2s ease-out;
              -webkit-animation-iteration-count: infinite;
            }
          }
        }
      }

      .hotspot-label {
        background-color: #fff;
        min-width: 280px;
        max-width: 280px;
        box-shadow: 0 0 4px 0 #00AEEF;
        padding: 26px 24px;
        color: $gray-2;
        border-radius: 4px;
        position: absolute;
        left: -6px;
        font-size: 18px;
        letter-spacing: .24px;
        line-height: 22px;
        display: block;
        z-index: 10;
        transform: translateY(-20%);
        transition: all .4s ease-in-out;
        opacity: 0;
        z-index: 1;

        @media(max-width:767px) {
          min-width: 100%;
          max-width: 100%;
          padding: 13px 12px;
          bottom: -85px;
          font-size: 16px;
        }

        &.active {
          transform: translateY(0%);
          opacity: 1;
          z-index: 10 !important;
        }

        h3 {
          font-size: 18px;
          line-height: 24px;
          font-weight: $weight-bold;
        }

        a {
          display: block;

          &:hover {
            color: inherit;
          }
        }

        &:after {
          @media(min-width:767px) {
            content: "";
          }

          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #fff;
          position: absolute;
          bottom: -8px;
          left: 8px;
          transform: translate(180deg);
          // box-shadow: 0 0 4px 0 #00AEEF;
        }
      }
    }

    .specifications {
      label {
        display: block;
        font-size: 12px;
        font-weight: $weight-bold;
        letter-spacing: 1.05px;
      }

      hr {
        width: 100%;
        height: 1px;
      }

      span {
        font-size: 12px;
      }

      .footprint,
      .list-price {
        font-size: 2.4rem;
        font-display: inline;

        .btn{
          width: 100%;
          padding: 8px 20px;
          white-space: initial;
          line-height: 22px;
          display: flex;
          /* text-align: left; */
          justify-content: space-between;
        }
      }

      .product-details {

        @media(min-width:767px) {
          // position: absolute;
          bottom: 0;
        }

        ul {
          li {
            position: relative;

            a {
              display: flex;
              align-items: flex-start;
              width: 95%;
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 1.05px;
              font-size: 12px;

              svg {
                display: inline-block;
                vertical-align: middle;

                &:first-child {
                  margin-left: 0;
                }

                &:last-child {
                  float: right;

                  @media(min-width: 767px){
                    position: absolute;
                    right: 8px;
                    top: 2px;
                    float: initial;
                  }
                }
              }
            }

            &:nth-child(even){
              svg {
                &:last-child {
                  @media(min-width: 767px){
                    right: -8px;
                  }
                }
              }
            }
          }
        }

        .btn{
          display: flex;
          align-items: center;
          padding: 4px 24px 8px 16px;
          margin: auto;
        }
      }

      .typical-downloads {
        svg {
          margin-left: 0;
        }
        ul{
          li{
            a{
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 1.05px;
              font-size: 12px;
            }
          }
        }
      }

      h5 {
        font-size: 12px;
        font-weight: $weight-bold;
        margin-bottom: $space-md;
        text-transform: uppercase;
        letter-spacing: 1.05px;
      }

      ul {
        list-style: none;
        flex-wrap: wrap;
        padding: 0;

        @media(min-width:767px) {
          display: flex;
        }

        li {
          height: 35px;

          a {
            display: flex;
            align-items: center;

            svg {
              margin-right: 8px;
              margin-left: 8px;
            }
          }

          @media(min-width: 767px) {
            width: 48%;
            height: auto;
          }

          margin-bottom: $space-md;
        }
      }
    }

  }

  .product-details {
    margin-top: $space-lg;

    .row {
      &>div {
        margin-bottom: 32px;
      }

      .specifications {
        margin-top: $space-xl;

        @media(min-width: 767px) {
          margin-top: 2*$space-xl;
          padding-right: 64px;
        }

        h2 {
          margin-bottom: $space-lg;
        }

        table {
          width: 100%;
          margin-bottom: 2*$space-xl;

          tr {
            border-bottom: 1px solid $gray-8;

            &:first-child {
              border-top: 1px solid $gray-8;
            }

            td {
              padding: 8px 0;

              &.property {
                font-weight: $weight-bold;
                color: $gray-2;
                font-size: 16px;
              }

              &.value {
                @include p-base;
                font-size: 16px;
                padding-left: 8px;
              }
            }
          }
        }

        &.configurator {
          margin: 0;
        }
      }

      .resources {
        h2 {
          margin-bottom: 31px;
        }

        hr {
          border-top: 1px solid $gray-8;
        }

        ul {
          @include list-no-style;

          li {
            margin-bottom: $space-lg;

            a {
              font-weight: $weight-bold;
              letter-spacing: .4px;
              font-size: 16px;
              display: flex;
              align-items: center;
              position: relative;
              padding-left: 32px;

              svg {
                &:first-child {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 24px;
                }
              }
            }

            svg {
              margin-right: 8px;
              vertical-align: middle;

              &:not(:first-child) {
                margin-left: 8px;

              }
            }
          }
        }
      }
    }

    // .spec-table {

    //   @media(min-width: 767px){
    //     margin: 32px 0;

    //     h5 {
    //       margin-bottom: 16px;
    //     }
    //   }

    //   table {
    //     width: 100%;

    //     th {
    //       border-bottom: 2px solid $gray-8;
    //       text-transform: uppercase;
    //       padding-bottom: 4px;
    //       font-size: 14px;
    //       color: $gray-4;
    //       font-weight: $weight-regular;
    //       width: 20%;

    //       &:nth-child(2),
    //       &:nth-child(3) {
    //         width: 15%;
    //       }
    //     }

    //     tr{
    //       border-bottom: 1px solid $gray-8;
    //     }
      

    //     td {
    //       padding: 4px 0;
    //       color: $gray-4;
    //       font-weight: $weight-regular;
    //       font-size: 14px;
    //       @media(max-width:767px){
    //         font-size: 12px;
    //       }
    //     }
    //   }


    //   @media(max-width:767px) {
    //     table {
    //       width: 100%;
    //       table-layout: fixed;
    //       border-collapse: collapse;
    //       margin: 0 auto;
    //     }

    //     /* Zebra striping */
    //     tr:first-child{
    //       border: 0;
    //     }
    //     // tr:nth-of-type(odd) {
    //     //   background: #f2f2f2;
    //     // }

    //     th {
    //       background: transparent;
    //       color: #fff;
    //       font-weight: 600;
    //       display: none;
    //     }

    //     td,
    //     th {
    //       padding: 12px;
    //       border: 1px solid #ccc;
    //       text-align: left;
    //       text-align: center
    //     }

    //     /*Mobile View*/
    //     @media only screen and (max-width: 760px) {

    //       td,
    //       tr {
    //         display: block;
    //       }

    //       /* Hide table headers (but not display: none;, for accessibility) */
    //       thead tr {
    //         position: absolute;
    //         top: -9999px;
    //         left: -9999px;
    //       }

    //       tr+tr {
    //         margin-top: 1.5em;
    //       }

    //       td {
    //         /* make like a "row" */
    //         border: none;
    //         border-bottom: 1px solid #ccc;;
    //         position: relative;
    //         padding-left: 50% !important;
    //         background-color: transparent;
    //         text-align: left;
    //       }

    //       /*
    //       Label the data
    //       */
    //       td:after {
    //         /* Now like a table header */
    //         position: absolute;
    //         /* Top/left values mimic padding */
    //         left: -3px;
    //         top: -3px;
    //         width: 45%;
    //         padding: 8px;
    //         padding-right: 10px;
    //         white-space: nowrap;
    //         font-size: 12px;
    //         font-weight: $weight-bold;
    //       }

    //       td:nth-of-type(1):after {
    //         content: "COMPONENT";
    //       }

    //       td:nth-of-type(2):after {
    //         content: "SKU";
    //       }

    //       td:nth-of-type(3):after {
    //         content: "QTY";
    //       }

    //       td:nth-of-type(4):after {
    //         content: "UNIT";
    //       }

    //       td:nth-of-type(5):after {
    //         content: "TOTAL LIST PRICE";
    //       }


    //       td:before {
    //         content: attr(data-label);
    //         display: inline-block;
    //         line-height: 1.5;
    //         margin-left: -100%;
    //         width: 100%;
    //         white-space: nowrap;
    //       }
    //     }
    //   }

    // }
  }

  .related-products {
    margin-top: 0;
    margin-bottom: 2*$space-xl;

    h2 {
      text-align: center;
      margin-bottom: $space-lg;
    }

    .col-12 {
      padding: 0 8px;
      margin-bottom: $space-lg;
    }

    .img {
      width: 100%;
      height: 250px;
      border: 1px solid $gray-6;
      margin-bottom: 8px;
      transition: all .25s ease-in-out;
    }

    a {
      transition: all .25s ease-in-out;

      &:hover {
        color: $acqua;
      }

      .img {
        &:hover {
          border: 1px solid $acqua;
        }
      }
    }
  }

  .bundle {
    margin-top: 85px;
    padding: 0;

    hr {
      border-top: 1px solid $gray-8;
      margin: 32px 0 !important;
    }

    .bundle-icons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin: 32px 0;

      .savings {
        text-align: center;
        color: $orange;
        font-size: 32px;
        font-weight: $weight-regular;
        margin-top: -10px;

        span {
          &:last-child {
            display: block;
            padding: 2px 10px;
            background-color: $orange;
            color: $white;
            font-size: 16px;
            font-weight: $weight-bold;
            letter-spacing: .5px;
            text-transform: uppercase;
          }
        }
      }
    }

    img {
      width: 100px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }

    a {
      text-decoration: underline;
      transition: all .25s ease-in-out;

      &:hover {
        color: $acqua;
      }
    }
  }

  #myModal {
    background-color: rgba(0, 0, 0, .5);
    padding: 70px;

    @media(max-width: 768px) {
      padding: 0px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    opacity: 0;
    z-index: -1;
    transform: scale(0);
    transition: all .5s ease-in-out;

    #close {
      position: absolute;
      top: $space-xl;
      right: $space-xl;

      @media(max-width: 1024px) {
        top: $space-lg;
        right: $space-lg;
      }

      @media(max-width: 768px) {
        top: $space-md;
        right: $space-md;
      }

      color: $white;
      transform: scale(2);
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
        fill: $white;

        g,
        path {
          fill: $white;
        }
      }
    }

    &>div {
      width: 90%;
      height: 900px;

      @media(max-width: 768px) {
        width: 100%;
      }

      @media(max-height:900px) {
        width: 900px;
        height: 700px;
      }

      @media(max-height:750px) {
        width: 750px;
        height: 550px;
      }

      background-color: $white;
      margin: auto;
      padding: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .img {
        height: 100%;
        width: 100%;
        margin: auto;
        margin-bottom: 32px;
        background-size: contain;
        background-repeat: no-repeat;
      }

    }

    &.active {
      opacity: 1;
      z-index: 999;
      transform: scale(1);
    }
  }

  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
}

body.typicals {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #58595B;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #B3AFB3;
  }

  ::-webkit-scrollbar-track {
    background: #DCDCDC;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }

  .products-main {
    @media (max-width: 767px){
      .filter-form{
        display: none;
      }
    }
    .content {
      .finish-card {
        background-color: $white;
        padding: 0;
        opacity: 0;
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width:767px){
          margin-bottom: 8px;
        }

        img {
          max-width: 100%;
          margin: 0;
          transition: box-shadow .4s ease-in-out !important;
          aspect-ratio: 4 / 3;
          object-fit: contain;
        }

        &:hover {
          // box-shadow: none;
          // transform: scale(1);
          // background-color: transparent;
        }
      }
    }

    .category-nav {
      h3 {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.open {
          i {
            transform: rotate(180deg);
          }

          &+.filter-group {
            height: auto;
          }
        }
      }

      i {
        float: right;
        font-weight: 100;
        color: $gray-5;
      }

      .filter-group {
        height: 0;
        max-height: 250px;
        overflow-y: scroll;
        // padding: 8px 0;
        position: relative;

        &.disabled{
          pointer-events: none;
          opacity: 0.5;
        }

        // &:after{
        //   content:"";
        //   height: 10px;
        //   width: 100%;
        //   position: absolute;
        //   left: 0;
        //   width: 100%;
        //   background: linear-gradient(0%, #F5F5F5, 100%, #F2F2F2);
        // }
      }

      .clear-filters {
        cursor: pointer;
        float: right;
        display: block;
        margin-bottom: 16px;
      }

      .clear-tag {
        padding: 4px 8px;
        border: 1px solid $gray-5;
        cursor: pointer;
        text-transform: capitalize;
        margin: 4px 0;
        margin-right: 8px;
        display: inline-block;

        .clear {
          font-weight: 700;
        }
      }
    }
  }
}