@use "../base/typography";
@use "../base/variables";

body.sideBars{
  .body-content{
    padding-right: 64px;
    & > p{
      @include typography.h3;
      color: variables.$text-color;
    }
    img{
      width: 100%;
      margin-bottom: 64px;
    }
  }

  footer{
    margin-top: 0;
  }
}