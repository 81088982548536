@use "../base/variables";

.module{
  &.copyWithVideo{
    background: variables.$gray-7;
    padding: 32px 0;
    padding-bottom: 80px;
    margin-bottom: 32px;
    h2{
      margin-bottom: 32px;
      font-size: 46px;
      font-weight: 600;
      letter-spacing: -0.76px;
    }
    .video{
      video{
        max-width: 100%;
      }
    }
    .col-lg-4{
      display: flex;
      align-items: center;
      .copy{
        padding: 40px;
        h2{
          font-size: 30px;
          letter-spacing: -0.34px;
          margin-bottom: 20px;
          font-weight: 500;
        }
        p{
          font-size: 19px;
          line-height: 34px;
          letter-spacing: -0.34px;
          color: variables.$navy;
        }
        .btn{
          text-decoration: none;
        }
      }
    }

  }
}