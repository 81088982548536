@use "variables";

.blue-bg{
  background-color: variables.$blue;
}
.gray-bg{
  background-color: variables.$gray-4;
}
.green-bg{
  background-color: variables.$green;
}
.orange-bg{
  background-color: variables.$orange;
}
.navy-bg{
  background-color: variables.$navy;
}
.yellow-bg{
  background-color: variables.$yellow;
  h2,p,a{
    color: variables.$gray-2 !important;
  }
  svg{
    #Home-1B{
      fill: variables.$gray-2 !important;
    }
  }
}

.cream-bg{
  background-color: variables.$cream;
  h2,p,a{
    color: variables.$gray-2 !important;
  }
  svg{
    #Home-1B{
      fill: variables.$gray-2 !important;
    }
  }
}

.lightGray-bg{
  background-color: variables.$lightgray;
  h2,p,a{
    color: variables.$gray-2 !important;
  }
  svg{
    #Home-1B{
      fill: variables.$gray-2 !important;
    }
  }
}

.darkGreen-bg{
  background-color: variables.$darkgreen
}

.bg-white{
  background-color: variables.$white;
  h2,p,a{
    color: variables.$gray-2 !important;
  }
  svg{
    #Home-1B{
      fill: variables.$gray-2 !important;
    }
  }
} 
.bg-dark-gray{
  background-color: variables.$gray-3;
  h3{
    color: variables.$gray-7;
    margin-bottom: 32px;
  }
  p, strong, a{
    color: variables.$gray-7;
  }
} 
.bg-grey{
  background-color: variables.$gray-7;
  h2,p, strong,a, li{
    color: variables.$gray-3;
  }
}
.bg-orange {
  background-color: variables.$orange;
  h2, h3,p, strong,a, li{
    color: variables.$white;
  }
  a.btn {
    background-color: transparent !important;
  }
}
.bg-navy {
  background-color: variables.$navy;
  h2, h3,p, strong,a, li{
    color: variables.$white;
  }
  a.btn {
    background-color: transparent !important;
  }
}

.disabled{
  pointer-events: none !important;
  opacity: .25 !important;
}

.hide{
  display: none !important;
}

.link-overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}
svg {
  color: variables.$svg-default;
}
svg.primary {
  color: variables.$svg-primary;
}

.visually-hidden{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.back-link{
  i{
    padding-right: 8px;
    color: variables.$blue;
  }
}

.sr-only {
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.loader {
  width: 2em;
  height: 2em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #34402C;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 64px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.hide-mobile{
  @media (max-width: 767px) {
    display: none !important;
  }
}