
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

.sidebar-content{
  .block{
    margin-top: 24px;
    margin-bottom: 8px;

    &.textBlock{
      padding: 32px;
      h3{
        font-weight: typography.$weight-regular;
        font-size: 30px;
      }
      p{
        margin: 0;
        font-size: 18px;
        &:empty{
          margin-bottom: 16px;
        }
      }
    }
    &.linkList{
      padding: 32px;
      h2{
        color: variables.$sidebar-heading-color;
        margin-bottom: 32px;
        font-weight: typography.$weight-regular;
        font-size: 30px;
        opacity: .73;
      }
      ul{
        @include mixins.list-no-style;
        li{
          vertical-align: middle;
          border-top: 1px solid variables.$border-color;
          transition: all .25s ease-in-out;
          &:last-child{
            border-bottom: 1px solid variables.$border-color;
          }
          &:hover{
            background-color: variables.$gray-7;
          }
          a{
            display: flex;
            align-items: center;
            @include typography.swiss-medium;
            padding: 16px 0;
          }
          svg{
            width: 50px;
            margin-right: 8px;
          }
        }
      }
    }
    &.copyWithButton{
      padding: 32px;

      ul{
        @include mixins.list-no-style;
        margin-top: 16px;

        li{
          font-weight: typography.$weight-regular;
          font-size: 18px;
          letter-spacing: .2px;
          line-height: 26px;
        }
      }
      a.btn{
        margin-top: 16px;
        display: block;
        background-color: variables.$white;

        &:hover{
          background-color: variables.$gray-2;
          color: variables.$white;
        }
      }
      & + .textBlock{
        margin-top: -32px;
        margin-bottom: 0;
        p{
          line-height: 20px;
          letter-spacing: .18px;
          font-size: 16px;
          color: variables.$gray-3;
          a{
            text-decoration: underline;
          }
        }

      }
    }
    &.imageLink{
      .img{
        height: 270px;
        position: relative;

        a{
          background-color: variables.$white;
          position: absolute;
          bottom: 32px;
          left: 16px;
          color: variables.$gray-2;
          border-color: variables.$gray-2;
          margin: auto;
          text-align: center;
          width: 100%;
          display: inline-block;
          width: auto;
          &:hover{
            background-color: variables.$gray-2;
            color: variables.$white;
          }
        }
      }
    }
  }
}