
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";


  .block{
    padding-bottom: 16px;
    &:first-child{
      padding-top: 32px;
    }
    @media(max-width:767px){
      &:last-child{
        padding-bottom: 0;

      }
    }
    &.textBlock{
      ul{
        li{
          list-style-type:square;
          @include typography.p-base;
        }
      }
    }
    &.imageLinks{
      // .row{
      //   justify-content: space-around;
      // }
      .link-block{
        margin-bottom: variables.$space-lg;
        position: relative;
        svg{
          margin-left: 8px;
        }
        .img{
          height:220px;
          margin-bottom: 8px;
        }
        span{
          display: block;
        }
        a{
          @include mixins.link-overlay;
        }
      }
    }
    &.gridCopy{
      h2{
        margin-bottom: 16px;
        color: variables.$block-heading-color
      }
      .col-12{
        p{
          margin: 0px;
          &:empty{
            margin-bottom: 16px;
          }
        }
      }
     a{
       text-decoration: underline;
     }

     border-top: 4px solid variables.$border-color;
     padding: 32px 0;
    }
    &.form{
      h2{
        margin-bottom: 32px;
      }
      form{
        @media(min-width: 767px){
          width: 60%;
          padding-left: 32px;
        }
        button{
          color: variables.$gray-2;
          font-size: 18px;
        }
      }
    }

    &.carouselGallery{

      .images-carousel{
        padding: variables.$space-lg 0;
        h2{
          text-align: center;
          margin-bottom: variables.$space-lg;
          color: #333333;
        }
        .img{
          width: 100%;
          height: 700px;
          // margin-left: 16px;
        }
        .flickity-prev-next-button{
          // top: 32%;
          &.next{
            right: -64px;
          }
          &.previous{
            left: -64px;
          }
        }
      }
    }

    &.masonaryGallery {
      padding: 64px 0;
  
      .grid {
  
        .grid-sizer {
          width: 33%;
  
          @media(max-width:992px) {
            width: 100%;
          }
        }
  
        .grid-item {
  
          a {
            padding: 4px;
            display: block;
            width: 100%;
            transition: all .5s ease-in-out;
            height: 100%;

            .img{
              background-size: cover;
            }
            &:after{
              // content:"";
              // background-color: $white;
              // position: absolute;
              // width: 100%;
              // height: 100%;
              // top:0;
              // left:0;
              // transition: all .5s ease-in-out;
              // z-index: 100;
              // transform: rotate(180deg);
            }
            &.active{
              // &:after{
              //   height: 0%;
              // }
            }
            &:hover {
              .team-card .bio {
                opacity: 1;
              }
            }
          }
  
          &.large {
            width: 66%;
            height: 476px;
            .img{
              background-position: 50% 20% !important;
            }
          }
  
          &.small {
            width: 33%;
            height: 206px;
            @media(max-width: 767px){
              height: 400px;
            }
          }
  
          &.tall {
            width: 33%;
            height: 476px;
            // @media(max-width: 767px){
            //   height: 476px;
            // }
          }
  
          @media(max-width: 992px) {
            width: 100% !important;
          }
        }
      }
    }

    &.video {
      margin-top: 64px !important;
      margin-bottom: 64px !important;
      margin: auto;
  
      &:nth-child(2) {
        margin-top: 0 !important
      }
  
      .img,
      video {
        width: 100%;
      }
  
      .img {
        min-height: 600px;
        background-size: cover;
        background-position: center;
  
        &.h-500 {
          min-height: 500px;
        }
  
        &.h-300 {
          min-height: 300px;
        }
  
        &.h-200 {
          min-height: 200px;
        }
      }
  
      video::cue {
        background: transparent;
        font-weight: typography.$weight-bold;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: -0.16px;
        text-align: center;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.50);
        max-width: 50% !important;
        width: 50% !important;
        margin: auto !important;
        display: inline-block !important;
  
        @media(min-width: 767px) {
          font-size: 28px;
          letter-spacing: -0.28px;
        }
      }
  
      // video::-webkit-media-text-track-display-backdrop {
      //   background: linear-gradient(180deg, rgba(0,0,0,0.53) 0%, rgba(0,0,0,0.00) 18%);
      // }
  
      // video::-webkit-media-text-track-display {
      //   background: linear-gradient(180deg, rgba(0,0,0,0.53) 0%, rgba(0,0,0,0.00) 18%);
      // }
  
      video::-webkit-media-text-track-container {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.00) 18%);
      }
  
      video {
        &.no-btn {
          &+.muted {
            display: none;
          }
        }
      }
  
      figcaption {
        color: variables.$gray-3;
        font-size: 14px;
        margin-top: 4px;
      }
  
      .muted {
        position: absolute;
        top: 16px;
        left: 32px;
        min-width: 141px;
        border: 1px solid #00AEEF;
  
        @media(max-width:992px) {
          min-width: initial;
          padding: 8px;
          top: 8px;
          left: 8px;
  
          img {
            width: 15px;
          }
        }
      }
  
      @media(max-width: 1300px) {
        .col-12 {
          padding: 0 !important;
          margin-bottom: 16px;
        }
      }
  
      @media(min-width:992px) {
        padding: 0;
      }
    }

    &.featuredProducts{
      margin-top: 32px;
      padding: 32px 0;
      h2{
        margin-bottom: 32px;
        a{
          color: variables.$navy;
        }
        svg{
          fill: variables.$navy;
          path{
            fill: variables.$navy;
          }
        }
      }
      .new{
        background-color: variables.$orange;
        color: variables.$white !important;
        display: inline-block;
        font-size: 11px;
        font-weight: typography.$weight-regular;
        letter-spacing: .4px;
        padding: 0 8px;
        border-radius: 3px;
        line-height: 17px;
        position: relative;
        margin-right: 3px;
        position: absolute;
        top: 65%;
        left: 20px;
      }
      background-color: #F5F5F5;
      .grid{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        gap: 10px 20px;
        
        .product{
          position: relative;
          background-color: variables.$white;
          padding: 20px;
          img{
            object-fit: contain;
            min-height: 185px;
            margin-bottom: 16px;
            max-width: 100%;
          }
          h4{
            margin-bottom: 0px;
            line-height: 22px;
            font-weight: 700;
          }
          span{
            font-size: 12px;
            color: variables.$gray-3;
          }
        }

        @media(min-width: 768px){
          grid-template-columns: 1fr 1fr;
        }

        @media(min-width: 992px){
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 20px 40px;
        }
      }

    }

    &.imageCopy{
      img{
        max-width: 100%;
      }

      .row{
        &.image-right{
          flex-direction: row-reverse;
        }

        .copy{
          display: flex;
          align-items: center;
        }
      }
    }
  }
