@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.product,
.configurator{
  iframe[title="reCAPTCHA"],
  .grecaptcha-badge{
    display: none !important;
  }
  // li{
  //   list-style: none !important;
  // }
  h2{
    color: #333333;
    font-size: 30px;
  }
  &:not(.zilo){
    [data-category-name="Congfigurations"]{
      ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        li{
          width: 49%;
          padding: 2px 12px;
          justify-content: space-between;
          display: block !important;
          &:before{
            content: "";
            width: 100%;
            height: 83px;
            margin-top: 8px;
            position: relative;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
          }
  
          .price{
            display: none;
          }
  
          span{
            display: inline-block;
            width: 80%;
            line-height: 1.1em;
  
            &.price{
              text-align: right;
              width: 20%;
            }
          }
        }
      }
    }
  }
  .description{
    @include p-base();
    ul{
      list-style-type:square
    }
  }
  .banner{
    .col-lg-5{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    ul{
      @include list-no-style;
      display: flex;
      padding: 34px 0;
      li{
        &:not(:first-child){
          padding-left: 16px;
        }
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: $weight-bold;
        letter-spacing: .4px;
        color: $gray-2;
        a{
          display: flex;
          align-items: center;
          font-size: 12px;
          svg{
            &:first-child{
              margin-right: 5px;
              transform: scale(.8);
            }
            &:last-child{
              margin-left: 8px;
            }
          }
        }
      }
      @media(min-width: 992px){
        justify-content: flex-end;
      }
    }
    hr{
      margin: 0;
    }
  }
  .product{
    @media(min-width: 767px){
      margin-bottom: 2*$space-xl;

    }
    .row{
      & >div{
        position: relative;
      }
    }
    span{
      padding: 8px 0;
      display: block;
    }
    .flickity-slider{
      display: flex;
      align-items: center;
    }


    .flickity-button {
      color: rgba($gray-4, 0.6);
      height: auto;
      transition: all .25s linear;
      &:hover {
        color: rgba($gray-4, 1)
          }
      &:disabled {
        opacity: 0;
        }
      }

    .flickity-prev-next-button .flickity-button-icon {
      width: 60px;
      height: 100px;
      left: 0%;
      transition: all .25s linear;

          // Hide arrows on mobile
          @media(max-width: 767px){
            display: none;
          }
        }

    .flickity-page-dots {
      bottom: 100px;
      width: 100%;

      .dot{
        background-color: $gray-4!important;
        height: 4px;
        width: 40px;
        margin: 0;
        border-radius: 0;
      }
      .dot.is-selected {
        background-color: rgba($gray-4, 0.1);
      }
    }

    .carousel-main{
      img{
        // padding: 0 32px;
        width: auto;
        max-height: 250px;
        margin: 0 16px;
        @media(min-width: 767px){
          max-height: 650px;

        }
      }
    }
    .carousel-nav{
      display: flex;
      flex-wrap: wrap;
      justify-content: end;

      .flickity-viewport{
        width: 110%;
        height: 200px !important;
      }
      .carousel-cell{
        width: 25%;
        height: 111px;
        display: flex;
        .img{
          width: 100%;
          height: 100%;
          display: inline-block;
          cursor: pointer;
          margin-left: auto;
          background-size: contain;
          transition: all .5s ease-in-out;
          &:hover{
            box-shadow: 0px 0px 0 1px $acqua;
          }
          &.active{
            box-shadow: 0px 0px 0 1px $acqua;
          }
        }
      }

      @media(min-width: 767px){
        .carousel-cell{
          // margin-left: 10px;
          margin-right: 0;
        }
        position: absolute;
        top: 229px;
        left: -175px;
        width: 295%;
        transform: rotate(90deg);
        .flickity-prev-next-button{
          transform: scale(.6);
          top: 35%;
          &.previous{
            left: -40px;
          }
          &.next{
            right: -41px;
          }
        }

        .img{
          transform: rotate(270deg);
        }
      }
    }
    .flickity-page-dots{
      bottom: 0px;
    }
  }

  .images{
    background-color: $gray-7;
    padding: $space-lg 0;
    margin: $space-xl 0;
    margin-bottom: 2*$space-xl;
    h2{
      text-align: center;
      margin-bottom: $space-lg;
      color: #333333;
    }
    .img{
      width: 100%;
      height: 250px;

      @media(min-width: 767px){
        width: 24%;
        margin-left: 16px;
        height: 190px;
      }
    }
    .flickity-prev-next-button{
      transform: scale(.5);
      top: 32%;
      &.next{
        right: -64px;
      }
      &.previous{
        left: -64px;
      }
      @media(max-width: 767px){
        display: none;
      }
    }
  }

  .product-details{
    margin-top: $space-xl;
    @media(min-width: 767px){
      margin-top: 2*$space-xl;
    }
    .row{
      & > div{
        margin-bottom: 32px;
      }
      .specifications{
        margin-top: $space-xl;
        @media(min-width: 767px){
          margin-top: 2*$space-xl;
          padding-right: 64px;
        }
        h2{
          margin-bottom: $space-lg;
        }
        table{
          width: 100%;
          margin-bottom: 2*$space-xl;
          tr{
            border-bottom: 1px solid $gray-6;
            &:first-child{
              border-top: 1px solid $gray-6;
            }
            td{
              padding: 8px 0;

              &.property{
                font-weight: $weight-bold;
                color: $gray-2;
                font-size: 16px;
              }
              &.value{
                @include p-base;
                font-size: 16px;
                padding-left: 8px;
              }
            }
          }
        }
        &.configurator{
          margin: 0;
        }
      }
      .resources{
        h2{
          margin-bottom: 31px;
        }
        hr{
          border-top: 1px solid $gray-6;
        }
        ul{
          @include list-no-style;
          li{
            margin-bottom: $space-md;
            a{
              font-weight: $weight-bold;
              letter-spacing: .4px;
              font-size: 16px;
              display:flex;
              align-items: center;
              position: relative;
              padding-left: 32px;
              svg{
                &:first-child{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 24px;
                }
              }
            }
            svg{
              margin-right: 8px;
              vertical-align: middle;
              &:not(:first-child){
                margin-left: 8px;

              }
            }
          }
        }
      }

      .application{
        padding: 5px;
        background-color: #F0F0F0;
        margin-top: 32px;
        .application-wrapper{
          img{
            max-width: 50%;
            margin-bottom: 16px;
            mix-blend-mode: multiply;
            margin: auto;
            display: block;
          }
          ul{
            background: $white;
            width: 100%;
            list-style: none;
            padding: 0;
            overflow: hidden;
            cursor: pointer;
            margin-bottom: 0;
            li{
              margin-bottom: 0px;
              padding: 12px 16px;
                  
              cursor: pointer;
              border-bottom: 1px solid $gray-6;
              width: 100%;        
              margin: 0;
              i,svg{
                margin-right: 8px;
              }
    
              span{
                float: right;
              }
              &:hover{
                background-color: #EBEDEC;
              }
              
      
              &:last-child{
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .bundle{
    margin-top: 85px;
    padding: 0;
    hr{
      border-top: 1px solid $gray-8;
      margin: 32px 0 !important;
    }
    .bundle-icons{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin: 32px 0;
      .savings{
        text-align: center;
        color: $orange;
        font-size: 32px;
        margin-top: -10px;
        span{
          &:last-child{
            display: block;
            padding: 2px 10px;
            background-color: $orange;
            color: $white;
            font-size: 16px;
            font-weight: $weight-bold;
            letter-spacing: .5px;
            text-transform: uppercase;
          }
        }
      }
    }
    img{
      width: 100px;
    }
    p{
      font-size: 16px;
      line-height: 22px;
    }
    a{
      text-decoration: underline;
      transition: all .25s ease-in-out;
      &:hover{
        color: $acqua;
      }
    }
  }

  #myModal{
    background-color: rgba(0, 0, 0, .5);
    padding: 70px;
    @media(max-width: 768px){
      padding: 0px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    opacity: 0;
    z-index: -1;
    transform: scale(0);
    transition: all .5s ease-in-out;

    #close {
      position: absolute;
      top: $space-xl;
      right: $space-xl;
      @media(max-width: 1024px){
        top: $space-lg;
        right: $space-lg;
      }
      @media(max-width: 768px){
        top: $space-md;
        right: $space-md;
      }
      color: $white;
      transform: scale(2);
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
        fill: $white;

        g,path {
          fill: $white;
        }
      }
    }

    &>div {
      width: 90%;
      height: 900px;
      @media(max-width: 768px){
        width: 100%;
      }
      @media(max-height:900px){
        width: 900px;
        height: 700px;
      }
      @media(max-height:750px){
        width: 750px;
        height: 550px;
      }
      background-color: $white;
      margin: auto;
      padding: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .img {
        height: 100%;
        width: 100%;
        margin: auto;
        margin-bottom: 32px;
        background-size: contain;
        background-repeat: no-repeat;
      }

    }

    &.active {
      opacity: 1;
      z-index: 999;
      transform: scale(1);
    }
  }
  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  a.btn.btn-navy{
    svg{
      width: 36px !important;
      margin-right: 2px !important;
      path{
        &:first-child{
          fill: rgb(255, 87, 33) !important;
        }
        &:nth-child(2){
          fill: $white !important;
        }
      }
    }
  }
}

body.product{
  main{
    a.btn.btn-navy{
      min-height: 60px;
      svg{
        width: 50px !important;
        position: absolute;
        left: 9%;
      }
    }
  }
}

.related-products{
  margin-top:  2*$space-xl;
  margin-bottom: 2*$space-xl;
  h2{
    text-align: center;
    margin-bottom: $space-lg;
  }
  .col-12{
    padding: 0 8px;
    margin-bottom: $space-lg;
  }
  .img{
    width: 100%;
    height: 250px;
    border: 1px solid $gray-6;
    margin-bottom: 8px;
    transition: all .25s ease-in-out;
  }
  a{
    transition: all .25s ease-in-out;
    &:hover{
      color: $acqua;
    }
    .img{
      &:hover{
        border: 1px solid $acqua;
      }
    }
  }
}

body.configurator{
  hr{
    margin-top: 0 !important;
  }
  .container.product{

    ul.icon{ // loading animation for icons only, not other option types
      li{
        &.loading{

          span,
          &:before{
            opacity: .25 !important;
          }
  
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -30px;  
            margin-left: -30px;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            border: 5px solid transparent;
            border-top-color: $blue;
            animation: loading 1s linear infinite;
          }
        }
      }
    }

    li{
      position: relative;
      pointer-events: none;
      &.enabled{
        pointer-events: initial;
        opacity: 1 !important;

        &.disabled{
          opacity: .25 !important;
        }
      }

      &.temp-disabled{
        pointer-events: none !important;
      } 
    }
    .row{
      border-bottom: 1px solid #C8C8C8;
      margin-bottom: 32px;

      &:last-child{
        border-bottom: none;
      }

      @media(min-width: 992px){
        flex-wrap: unset;
        max-height: 550px;
      }
      .configurator-view{
        iframe{
          height: 500px;
          width: 100%;
          border: none;

          @media(min-width: 992px){
            height: 100%;
          }
        }
      }
      .configurator-controls{
        padding: 0;

        .selections{
          height: 0%;
          padding: 0 18px;
          display: none;
          .section{
            display: none;
          }
          span{
            font-weight: $weight-bold;
            &.current-price{
              text-align: right;
              margin-top: -41px;
              display: block;
            }
          }
        }

        ul.options{
          @include list-no-style;
          display: flex;
          justify-content: left;

          padding: 0 18px;
          overflow-y: hidden;
          overflow-x: auto;
          overflow: auto;

          &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #A0A0A0;
            border-radius: 20px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 20px;
          }

          li{
            background: rgba($gray-8, .2);
            border-radius: 3px;
            border: 1px solid rgba($gray-6, .5);
            margin: 0px 3px;
            height: 90%;
            transition: all .25s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: .4;
            &:hover{
              background: rgba($white, 0.5);
              border: 1px solid $acqua;
              a {
                color: $gray-1;
              }
            }


            &:last-child{
              a{
                margin-right: 0;
              }
            }
            a{
              color: $gray-3;
              cursor: pointer;
              font-size: .9em;
              letter-spacing: -.2px;
              padding: 6px 20px;
              height: 2.5em;
              line-height: 16px;
              display: block;
              height: auto;

              &:hover{
                color: $gray-1;
              }
            }
            &.active{
              a{
                color: $gray-1;
                position: relative;

              }
            }

            &.cable-basket{
              a{
                width: 125px;
              }
            }
          }
          @media(min-width: 992px){
            height: 10%;
          }
        }
        .category-wrapper,
        #custom-revi{
          overflow-y: scroll;
          height: 500px;
          padding: 0 24px;
          background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
          @media(min-width: 992px){
            height: 90%;
          }
          &::-webkit-scrollbar {
            width: 6px;

          }

          &::-webkit-scrollbar-thumb {
            background: #A0A0A0;
            border-radius: 20px;


          }

          &::-webkit-scrollbar-track {
            border-radius: 20px;

          }

          .category{
            position: relative;
            & > li[data-configuration-name="HIDDEN"] {
              border-radius: 50px;
              list-style: none;
              width: 128px;
              height: 31px;
              margin: 1px;
              margin-bottom: 8px;
              border: 1px solid #DCDCDC;
              cursor: pointer;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                padding: 13px 0;
                text-align: center;
                font-size: 14px;
                font-weight: $weight-bold;
                color: #999;
              }
              &.include{
                background-color: $acqua;
                span{
                  color: $white;
                }
              }
            }
            & > li[data-group-name="Modesty Panel"]{
              position: relative;
              z-index: 10;
              span{
                display: none;
              }
              & + ul{
                position: absolute;
                right: 0;
                top: 56px;
                z-index: 1;
                width: 100%;
                li{
                  background-color: transparent !important;
                  box-shadow: none !important;
                  color: initial !important;
                  pointer-events: none !important;
                }
              }
            }
            &.category-default{
              h5 {
                font-size: 14px;
                text-transform: uppercase;
              }
              ul{
                display: flex;
                flex-wrap: wrap;
                @include list-no-style;
                margin-bottom: 32px;
                li{
                  width: 19%;
                  height: 19%;
                  margin: 1px;
                  margin-bottom: 1px;
                  border: 1px solid #DCDCDC;
                  cursor: pointer;
                  position: relative;
                  img{
                    height: 100%;
                    width: 100%;
                    pointer-events: none;
                  }
                  &.active{
                    font-weight: $weight-bold;
                    &:after{
                      content: "";
                      width: 100%;
                      height: 3px;
                      border-bottom: 3px solid $acqua;
                      position: absolute;
                      bottom: 0px;
                      left: 0;
                    }
                  }
                  &[data-configuration-label="HIDDEN"],
                  &[data-configuration-label="Hide"]{
                    display: none
                  }
                }
              }
              span{
                display: block;
                color: #333333;
                i{
                  color: $acqua;
                  float: right;
                  cursor: pointer;
                  margin-top: 20px;
                }
                &.current-price{
                  text-align: right;
                  margin-top: -41px;
                  display: block;
                  font-weight: $weight-bold;
                }
                &.current-selected{
                  font-weight: $weight-bold;
                  font-size: 15px;
                  letter-spacing: .2px;
                }
              }
              &:last-child{
                padding-bottom: 25%;
              }

              //button toggle
              ul.button-toggles{
                margin-bottom: 16px;
                li{
                  display: inline-block;
                  border: 1px solid #DCDCDC;
                  padding: 4px 12px;
                  background-color: #fafafa;
                  cursor: pointer;
                  width: auto;
                  &:first-child{
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                    border-right: 0;
                  }
                  &:last-child{
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                    border-left: 0;
                  }
                  &.active{
                    background-color: $acqua;
                    color: white;
                    font-weight: $weight-bold;
                  }
                }
              }

            }
            &.category-list,
            &.category-custom-44161-model,
            &.category-custom-44161-style,
            &.category-custom-85401-model{
              >ul{
                @include list-no-style;
                margin-bottom: 32px;

                &.icon{
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;

                  li{
                    width: 49%;
                    padding: 2px 12px;
                    justify-content: space-between;
                    display: block;

                    span{
                      display: inline-block;
                      width: 80%;
                      line-height: 1.1em;

                      &.price{
                        text-align: right;
                        width: 20%;
                      }
                    }
                    &:before{
                      content:"";
                      width: 100%;
                      height: 83px;
                      margin-top: 8px;
                      position: relative;
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      display: inline-block;
                    }
  
                    &[data-configuration-name="Bookcase"],
                    &[data-style-name="Pedestal"]{
                      &:before{
                        // background-image: url('/assets/images/REVI-Bookcase.svg');
                        background-image: url('/assets/images/REVI-Single-Drawer-Pedestal@2x.png');
                      }
                    }
  
                    &[data-configuration-name="Personal Locker"]{
                      &:before{
                        // background-image: url('/assets/images/REVI-Personal-Locker.svg');
                        background-image: url('/assets/images/REVI-Personal-Locker@2x.png');
                      }
                    }
  
                    &[data-configuration-name="Single Drawer Pedestal"]{
                      &:before{
                        // background-image: url('/assets/images/REVI-Single-Drawer-Pedestal.svg');
                        background-image: url('/assets/images/REVI-Drawer-File@2x.png');
                      }
                    }
  
                    &[data-configuration-name="Dual Drawer Pedestal"]{
                      &:before{
                        // background-image: url('/assets/images/REVI-Dual-Drawer-Pedestal.svg');
                        background-image: url('/assets/images/REVI-Dual-Drawer-Pedestal@2x.png');
                      }
                    }
  
                    &[data-configuration-name="Drawer File Pedestal"]{
                      &:before{
                        // background-image: url('/assets/images/REVI-Drawer-File.svg');
                        background-image: url('/assets/images/REVI-Bookcase@2x.png');
                      }
                    }

                    &[data-style-name="Undermount"]{
                      &:before{
                        background-image: url('/assets/images/REVI UB.svg');
                      }
                    }

                    &[data-configuration-name="Personal Bin"]{
                      &:before{
                        background-image: url('/assets/images/REVI UB.svg');
                      }
                    }

                    &[data-configuration-name="Console Left Hand Door"]{
                      &:before{
                        background-image: url('/assets/images/REVI UCLH.svg');
                      }
                    }

                    &[data-configuration-name="Console Right Hand Door"]{
                      &:before{
                        background-image: url('/assets/images/REVI UCRH.svg');
                      }
                    }
                    //amobi
                    &[data-style-name="Desk"]{
                      &:before{
                        background-image: url('/assets/images/Amobi - Desk One B.png');
                      }
                    }

                    &[data-style-name="Team"]{
                      &:before{
                        background-image: url('/assets/images/Amobi Team Wall.png');
                      }
                    }

                    &[data-configuration-name="Desk-One"]{
                      &:before{
                        background-image: url('/assets/images/Amobi - Desk One B.png');
                      }
                    }

                    &[data-configuration-name="Desk-Focus"]{
                      &:before{
                        background-image: url('/assets/images/Desk Focus A.png');
                      }
                    }

                    &[data-configuration-name="Wall"]{
                      &:before{
                        background-image: url('/assets/images/Amobi Team Wall.png');
                      }
                    }

                    &[data-configuration-name="Wall Hub"]{
                      &:before{
                        background-image: url('/assets/images/AmobiTeamHalfWall.png');
                      }
                    }

                    &[data-configuration-name="Wall Storage"]{
                      &:before{
                        background-image: url('/assets/images/AmobiTeamHalfWallStorage.png');
                      }
                    }

                    &[data-configuration-name="Wall Storage Digital Display"]{
                      &:before{
                        background-image: url('/assets/images/Amobi_Team_Digital_Display_Cart@2x.png');
                      }
                    }

                    &[data-configuration-name="Display"]{
                      &:before{
                        background-image: url('/assets/images/Amobi Team Display.png');
                      }
                    }

                    &[data-configuration-name="Display Storage"]{
                      &:before{
                        background-image: url('/assets/images/Amobi Team Display Storage.png');
                      }
                    }

                    &[data-configuration-name="Storage"]{
                      &:before{
                        background-image: url('/assets/images/Amobi Team Storage Cart.png');
                      }
                    }

                    &[data-configuration-name="Utility Cart"]{
                      &:before{
                        background-image: url('/assets/images/Amobi Team Utility Cart.png');
                      }
                    }
                    // lucas
                  }
                }

                li{
                  margin-bottom: 5px;
                  font-size: 0.9em;
                  border-radius: 10px;
                  cursor: pointer;
                  color: $gray-2;
                  border-radius: 7px;
                  transition: all .5s ease-in-out;
                  padding: 2px 12px;
                  display: flex;
                  justify-content: space-between;
                  position: relative;

                  // &[data-configuration-name="Modesty Panel"]{
                  //   display: none;
                  // }
                  
                  .label {
                  }
                  .price {
                  }
                  &:hover, &.active  {
                    box-shadow: 0px 0px 0px 2px rgba($gray-4, 0.2);
                    background-color: $white;
                  }
                  &:hover  {
                    box-shadow: 0px 0px 0px 2px rgba($gray-4, 0.2);
                    background-color: rgba($white, 0.5);
                  }
                  &.active  {
                    box-shadow: 0px 0px 0px 2px rgba($acqua, 1.0);
                    color: $gray-1;
                    span {
                      font-weight: $weight-bold !important;
                    }
                  }
                  &.show{
                    display: flex !important;
                  }
                }
              }
            }
            &.category-hidden {
              display: none;
            }
            &.category-disabled {
              opacity: 0.25;
              pointer-events: none;

              li, li[data-configuration-name="HIDDEN"]{
                pointer-events: none !important;
              }
            }
            .section {
              font-size: 19px;
              margin-bottom: 16px;
              border-bottom: 1px solid #DCDCDC;
            }
            &#category-drafting,
            &#category-accessories,
            &#category-modesty-panel,
            &#category-castors,
            &#category-handset,
            &#category-cable-basket,
            &.toggle{
              ul{
                li{
                  width: 100%;
                  height: 41px;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
                margin-bottom: 0;
              }

              span.price{
                display: inline;
                float: right;
                margin-right: 12px;
                color: $gray-2;
                font-size: 14.4px;
              }
            }
            &.category-hidden {
              display: none;
            }
            &.category-disabled {
              opacity: 0.25;
              pointer-events: none;
            }
            .disabled-message{
              line-height: 1.2;
              margin-bottom: 16px;
            }
            .section {
              font-size: 19px;
              margin-bottom: 16px;
              border-bottom: 1px solid #DCDCDC;
            }
            .current-color{
              font-weight: $weight-bold;
              & + .current-price{
                font-weight: $weight-bold;
                text-align: right;
                margin-top: -41px;
              }
            }
          }
          .finish-collection{
            ul{
              li{
                transition: all .25s ease-in-out;
                &:hover{
                  transform: scale(1.1);
                  z-index: 999;
                  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.10);
                }
              }
            }
          }
        }
      }
    }
  }
  div.tool-tip{
    display: flex;
    margin-bottom: 5px;
    padding: 2px 12px;
    justify-content: space-between;
    font-size: 0.9em;
    color: $white;
    border-radius: 10px;
    cursor: pointer;
    // float
    position: absolute;
    bottom: 16px;
    width: 90%;
    left: 18px;
    z-index: 999;
    background-color: rgba(0,0,0,.8);
    span {
      font-weight: $weight-bold !important;
    }
  }
  #product-configurator-summary{
    padding: 0;
    & > a{
      display: flex !important;
      align-items: center;
      svg{
        float: left;
      }

      &.price-summary-download{
        svg.dealer-spinner{
          position: absolute;
          left: 0;
          right: 0;
          opacity: 0;
          margin: auto;
          z-index: 10;
        }
        &.button--loading{
          svg,span{
            opacity: 0;
            visibility: hidden;
          }

          svg.dealer-spinner{
            opacity: 1;
            visibility: initial;
          }

          // &:after{
          //   content: "";
          //   position: absolute;
          //   width: 30px;
          //   height: 30px;
          //   top: 0;
          //   left: 0;
          //   right: 0;
          //   bottom: 0;
          //   margin: auto;
          //   border: 8px solid transparent;
          //   border-top-color: $blue;
          //   border-radius: 50%;
          //   animation: button-loading-spinner 1s ease infinite;
          // }
        }
      }
    }
    .certifications{
      display: none;
      ul{
        @include list-no-style;
      }
    }
    .price-summary{
      display: flex;
      padding: 10px 20px 30px;
      align-items: baseline;
      justify-content: space-between;
      .price-summary-label{
        font-size: 0.9em;
        font-weight: bold;
        text-transform: uppercase;
      }
      .price-summary-value{
        font-size: 1.8em;
        font-weight: bold;
      }
    }
    a {
      display: block;
      padding: 14px;
      margin-bottom: 6px;
      background-color: $gray-8;
      color: $gray-2;
      position: relative;
      text-decoration: none;
      pointer-events: none;

      &.btn-navy{
        width: 100%;
        background: $navy;
        color: $white;

        &:hover{
          background-color: $blue;
          border-color: $blue;
          color: $white;
        }
      }
      
      svg{
        margin-right: 16px;
        vertical-align: middle;
        display: inline;
        width: 24px;
        g,path{
          fill: $gray-2;
        }
      }
      span {
        display: inline;
      }
      input {
        opacity: 0;
        position: absolute;
        font-size: 0.8em;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      &.enabled{
        pointer-events: initial;
      }
      &.disabled {
        opacity: 0.5;
      }
      &:hover {
        background-color: $navy;
        color: $gray-8;
        svg{
          g, path{
            fill: $gray-8;
          }
        }
      }
    }

  }
  .fully-assembled{
    display: flex;
    justify-content: space-between;
    padding: 10px 32px;
    padding-top: 0;
    margin-bottom: 32px;
    font-size: 14px;
    color: $gray-4;
    position: relative;
    &>div{
      display: flex;
      align-items: center;
      vertical-align: middle;
      label{
        margin-bottom: 0;
      }
    }
    input{
      margin-right: 8px;
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid $gray-5;
      padding: 9px;
      border-radius: 0px;
      display: inline-block;
      position: relative;
      outline: 0;

      &:checked {
        &+span+span.clear {
          opacity: 1;
          cursor: pointer;
        }

        background-color: $acqua;
        border: 2px solid $gray-3;
        color: $acqua;
        box-shadow: inset 0 0 0 2px #DCDCDC;

        &+span {
          font-weight: $weight-bold;
        }
      }

      &:checked:active {
        background-color: $acqua;
      }
    }
  }

  //specific products styles
  .bixby{
    #category-8, #category-9, #category-10{
      .disabled-message {
        position: absolute;
        top: 55px;
        span{
          padding: 0;
          font-size: 10px;
          color: $gray-5;
        }
      }
      [data-configuration-name="HIDDEN"]{
        margin-top: 20px;
      }
      ul {
        li{
          display: inline-block;
          height: 50px;
          width: 50px;
          margin-right: 5px;
          cursor: pointer;
          box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);

          &[data-configuration-skus="BIXBY-TRAY.PC3"], &[data-configuration-skus="BIXBY-CAP.PC3"]{
            background-color: #99989D;
          }
          &[data-configuration-skus="BIXBY-TRAY.PC1"], &[data-configuration-skus="BIXBY-CAP.PC1"]{
            background-color: #000;
          }
        }
      }
    }

    #category-8{
      .current-selected, ul{
        display: none;
      }
    }

    .category-default{
      &[data-category-label="Select Tablet"],
      &[data-category-label="Select Storage Tray"],
      &[data-category-label="Select Ganging Cap"]
      {
        .section{
          padding-bottom: 40px;

          i{
            margin-top: 0 !important;
          }
        }
      }
    }

    li[data-configuration-name*="Soft Felt"]{
      display: none !important;
    }
  }

  .revi{
    li[data-group-name="Door Right Hand"][data-configuration-material="HIDDEN"],
    li[data-group-name="Door Left Hand"][data-configuration-material="HIDDEN"]{
      opacity: 0 !important;
    }

    li[data-group-name="Expandable Bag Drop Castors"][data-configuration-material="HIDDEN"]{
      display: none !important;
    }
    
    li[data-group-name="Hinge"]{
      .price{
        display: none;
      }
    }

    #category-accessories{
      li{
        span{
          &.label{
            width: 80px;
          }
        }
        height: auto !important;
      }

    }
  }

  .amobi-product{
    [data-group-name="Size"]{
      .price{
        display: none;
      }
    }

    [data-category-name="Modesty PET"]{
      .section,.current-price{
        display: none !important;
      }
    }

    li[data-configuration-name="Whiteboard"]{
      display: none;
    }

    [data-category-name="Display Hood"]{
      .section{
        border-bottom: none !important;
        margin-bottom: 0 !important;
      }
      .current-selected,ul,.fa{
        display: none !important;
      }
    }

    [data-configuration-name="Wall Storage Digital Display w Hood"]{
      display: none !important;
    }

    [data-group-name="Style"] .price{
      transform: translateX(-10px);
    }

    .icon {
      li{
        &[data-configuration-name="Wall Storage Digital Display"]{
          .price{
            @media(min-width:992px) and  (max-width:1300px){
              transform: translateY(-20px);
            }
          }
        }
      }
    }

    #product-configurator-summary{
      .price-summary{
        align-items: center;
      }

      .price-summary-label{
        line-height: 1em;
      }
    }
  }

  .greenpoint{
    [data-category-name="Model"]{
      .price{
        display: none !important;
      }
    }
    [data-category-name="Return"]{
      span.label,
      span.price,
      ul{
        display: none !important;
      }
    }
    [data-category-name="Modesty"]
    {  

        .current-price{
          display: none !important;

        }
    }
    [data-category-name="center_cut_out"]
    {
      .current-selected,
      .current-price,
      ul{
        display: none !important;
      }
    }
    [data-configuration-name="Private Office Desk"]{
      &:before{
        background-image: url('/assets/images/Greenpoint - Desk.svg');
      }
    }
  }

  .slope{
    [data-category-name="Model"]{
      .price{
        display: none !important;
      }
    }

    [data-configuration-name="Guest"]{
      &:before{
        background-image: url('/assets/images/Slope - Guest Chair.svg');
      }
    }
    [data-configuration-name="Lounge"]{
      &:before{
        background-image: url('/assets/images/Slope - Lounge Chair.svg');
      }
    }
    [data-configuration-name="Stool"]{
      &:before{
        background-image: url('/assets/images/Slope - Stool.svg');
      }
    }
    [data-configuration-name="Conference"]{
      &:before{
        background-image: url('/assets/images/Slope - Conference Chair.svg');
      }
    }
    [data-configuration-name="Stacking"]{
      &:before{
        background-image: url('/assets/images/Slope - Stacking Chair.svg');
      }
    }
  }

  .sterling{
    [data-category-name="Model"]
    {
      .price{
        display: none !important;
      }
    }
    [data-configuration-name="Guest"]{
      &:before{
        background-image: url('/assets/images/Sterling - Guest Chair.svg');
      }
    }
    [data-configuration-name="Conference"]{
      &:before{
        background-image: url('/assets/images/Sterling - Conference Chair.svg');
      }
    }
    [data-configuration-name="Stool"]{
      &:before{
        background-image: url('/assets/images/Sterling - Bar Height Stool.svg');
      }
    }
  }

  .lucas{
    [data-configuration-name="Wire Chair"]{
      &:before{
        background-image: url('/assets/images/Lucas - Wire Chair.png');
      }
    }
    [data-configuration-name="Swivel Chair"]{
      &:before{
        background-image: url('/assets/images/Lucas - Swivel Chair.png');
      }
    }
    
    li[data-group-name="Model"] span.price{
      position: relative;
      right: 12px;
    }
  }

  .personality-plus{
    [data-configuration-name="Chair"],
    [data-group-name="Arms"]{
      .price{
        display: none !important;
      }
    }
    // personality plus
    [data-configuration-name="original"]{
      .price{
        display: none !important;
      }
      &:before{
        background-image: url('/assets/images/Personality_Plus@3x.png');
      }
    }
    [data-configuration-name="QEX7OK2KHB"]{
      .price{
        display: none !important;
      }
      &:before{
        background-image: url('/assets/images/Personality_Plus-RING@3x.png');
      }
    }

    // [data-group-name="Castors"]{
    //   background-color: $gray-7;
    // }
  }

  .concur{
    li[data-configuration-name="Modesty Panel"]{
      display: none !important;
    }
  }

  .mesa{
    li[data-group-name="Setup"] span.price,
    li[data-group-name="Arms"] span.price{
      display: none !important;
    }

    [data-configuration-name="Chaise Lounge"],
    [data-configuration-name="Individual"]{
      &:before{
        background-image: url('/assets/images/Mesa - Left Hand Chaise Lounge.svg');
      }
    }
    [data-configuration-name="Armless"]{
      &:before{
        background-image: url('/assets/images/Mesa - Armless Lounge.svg');
      }
    }
    [data-configuration-name="Corner"]{
      &:before{
        background-image: url('/assets/images/Mesa - Corner.svg');
      }
    }
    [data-configuration-name="Ottoman"]{
      &:before{
        background-image: url('/assets/images/Mesa - Ottoman.svg');
      }
    }
    [data-configuration-name="Table"]{
      &:before{
        background-image: url('/assets/images/Mesa - Coffee Table.svg');
      }
    }
    li[data-group-name="Model"] span.price{
      position: relative;
      right: 12px;
    }

    [data-configuration-name="Combo A"],
    [data-configuration-name="Combinations"]{
      &:before{
        background-image: url('/assets/images/Mesa - Config A.png');
      }
    }
    [data-configuration-name="Combo B"]{
      &:before{
        background-image: url('/assets/images/Mesa - Config B.png');
      }
    }
    [data-configuration-name="Combo C"]{
      &:before{
        background-image: url('/assets/images/Mesa - Config C.png');
      }
    }
    [data-configuration-name="Combo D"]{
      &:before{
        background-image: url('/assets/images/Mesa - Config D.png');
      }
    }
    [data-configuration-name="Combo E"]{
      &:before{
        background-image: url('/assets/images/Mesa - Config E.png');
      }
    }
    [data-configuration-name="Combo F"]{
      &:before{
        background-image: url('/assets/images/Mesa - Config F.png');
      }
    }
    li[data-group-name="Model"] span.price{
      position: relative;
      right: 12px;
    }
  }

  .brighton{
    li[data-configuration-name=HIDDEN]{
      top: 32px;
    }
    li[data-group-name="Model"] span.price{
      position: relative;
      right: 12px;
    }
    li[data-group-name="Setup"] span.price,
    li[data-group-name="Arms"] span.price,
    li[data-group-name="Tables"] span.price,
    li[data-group-name="Position"] span.price,
    [data-category-name="Tables"] span.current-price,
    li[data-group-name="Ganging"] span.price,
    [data-category-name="Ganging"] > li span.label,
    [data-category-name="Ganging Tables Attached"] span.section,
    [data-category-name="Ganging Tables Attached"] span.current-price,
    li[data-configuration-name="90 degree"],
    li[data-configuration-name="Straight"],
    li.ganging-tables-attached,
    li.ganging-end-tables-attached,
    li.tables,
    [data-configuration-label="Occasion Table"] span.price,
    [data-category-name="Ganging End Tables Attached"] .section,
    [data-category-name="Ganging End Tables Attached"] span.current-price,
    [data-category-name="Tables"] .section,
    [data-category-name="Tables"] span.current-price,
    [data-group-name="Table Top"] span.price,
    [data-category-name="Config A Laminate"] span.current-price,
    [data-category-name="Config B Laminate"] span.current-price,
    [data-category-name="Config C Laminate"] span.current-price,
    [data-category-name="Config D Laminate"] span.current-price
    {
      display: none !important;
    }
    [data-configuration-label="Individual Pieces"]{
      &:before{
        background-image: url('/assets/images/Brighton - Single Seat Lounge.svg');
      }
    }
    [data-configuration-label="One Seat"]{
      &:before{
        background-image: url('/assets/images/Brighton - Single Seat Lounge.svg');
      }
    }
    [data-configuration-label="Two Seat"]{
      &:before{
        background-image: url('/assets/images/Brighton - Two Seat Lounge.svg');
      }
    }
    [data-configuration-name="Ottoman"]{
      &:before{
        background-image: url('/assets/images/Brighton - Ottoman.svg');
      }
    }
    [data-configuration-label="Occasion Table"]{
      &:before{
        background-image: url('/assets/images/Brighton-Square-Occasional-Table.svg');
      }
    }

    [data-configuration-label="Combo 1"],
    [data-configuration-label="Combinations"]{
      &:before{
        background-image: url('/assets/images/Brighton - Config A.png');
      }
    }
    [data-configuration-label="Combo 2"]{
      &:before{
        background-image: url('/assets/images/Brighton - Config B.png');
      }
    }
    [data-configuration-label="Combo 3"]{
      &:before{
        background-image: url('/assets/images/Brighton - Config C.png');
      }
    }
    [data-configuration-label="Combo 4"]{
      &:before{
        background-image: url('/assets/images/Brighton - Config D.png');
      }
    }

    [data-category-name="Ganging Tables"]{
      margin-top: -32px;
    }

    @media (min-width:992px) and (max-width:1300px){
      .configurator-controls .category-wrapper .category.category-list>ul.icon li span.price{
        margin-left: -6px;
      }
    }
  }

  .bixby-lite-work{
    li[data-group-name="Model_"][data-configuration-name="Drafting"]:before{
      background-image: url('/assets/images/Bixby Lite Works Stool@3x.png');
    }
    li[data-group-name="Model_"][data-configuration-name="Chair"]:before{
      background-image: url('/assets/images/Bixby Lite Works@3x.png');
    }
    li[data-group-name="Model"][data-configuration-name="Drafting"]:before{
      background-image: url('/assets/images/Bixby Lite Works Stool@3x.png');
    }
    li[data-group-name="Model"][data-configuration-name="Chair"]:before{
      background-image: url('/assets/images/Bixby Lite Works@3x.png');
    }
    li[data-group-name="Model_"] span.price,
    li[data-group-name="Model"] span.price{
      display: none !important;
    }
    [data-category-name="Model_"]{
      @include iconStyle;
    }
    // .current-price{
    //   display: none !important;
    // }
  }

  .cluvo{
    li[data-configuration-name*="CLCASTERS"]{
      display: none !important;
    }
    [data-category-name="Cable Baskets"]{
      &> ul, span.current-selected{
       display: none !important;
      }
    }

    #category-cable-basket{
      padding-bottom: 64px;
    }

    [data-configuration-name="WS Linear HIDDEN"]{
      display: none !important;
    }
  }

  .uptake{
    li[data-group-name="Depth"] .price,
    li[data-group-name="Width"] .price,
    li[data-group-name="Height"] .price,
    li[data-group-name="Hinge"] .price,
    li[data-group-name="Model"] .price,
    li[data-group-name="Lock Options"] .price,
    li[data-group-name="Pull Color"] .price,
    li[data-group-name="Style"] .price,
    i{
      display: none !important;
    }

    [data-configuration-name="Single"]{
      &:before{
        background-image: url('/assets/images/Uptake - Single 1 Door@2x.png')
      }
    }

    [data-configuration-name="Double"]{
      &:before{
        background-image: url('/assets/images/Uptake -Double Wide 2 Door@2x.png')
      }
    }

    [data-configuration-name="Single - 1 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake - Single 1 Door@2x.png');
      }
    }
    [data-configuration-name="Single - 2 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake - Single 2 Door@2x.png');
      }
    }
    [data-configuration-name="Single - 3 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake - Single 3 Door@2x.png');
      }
    }
    [data-configuration-name="Single - 4 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake - Single 4 Door@2x.png');
      }
    }

    [data-configuration-name="Double - 2 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake -Double Wide 2 Door@2x.png');
      }
    }
    [data-configuration-name="Double - 4 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake -Double Wide 4 Door@2x.png');
      }
    }
    [data-configuration-name="Double - 6 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake -Double Wide 6 Door@2x.png');
      }
    }
    [data-configuration-name="Double - 8 Door"]{
      &:before{
        background-image: url('/assets/images/Uptake -Double Wide 8 Door@2x.png');
      }
    }
  }

  .zilo{
    [data-group-name="Congfigurations"] .price,
    [data-group-name="Arm Rest"] .price,
    [data-group-name="Backrest"] .price,
    [data-group-name="Model"] .price,
    [data-group-name="Seat Cushion"] .toggle-group,
    [data-category-name="Seat Cushion"] li[data-configuration-label="HIDDEN"]{
      display: none !important;
    } 
  }

  .siya{
    [data-group-name="Arm Rest"] .price{
      display: none !important;
    }
  }

  .bodi{
    [data-group-name="Arm Rest"] .price{
      display: none !important;
    }
  }

  .jaku{
    [data-group-name="Mechanism"] .price,
    [data-group-name="Arm Rest"] .price{
      display: none !important;
    }
  }

  .tizu-work,.tizu{
    [data-group-name="Style"] .price,
    [data-group-name="Arms"] .price,
    [data-group-name="Finish"] .price{
      display: none !important;
    }
  }
  
}

body.product{
  #snippet-form-modal-launch-dealer{
    margin-bottom: 31px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      path{
        fill: $white;
      }
    }
  }

  .dealer-locator{
    #hsForm_2acfb9ef-0602-4af9-8860-f06709b643af{
      padding-top: 130px !important;
    }
  }
}

.email-modal {
  background-color: rgba(0, 0, 0, .5);
  padding: 120px;
  opacity: 0;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow-y: scroll;

  @media(max-height: 850px){
    padding: 100px 64px;
  }

  @media(max-width: 768px){
    padding: 0px;
    padding-top: 120px;
  }

  #close {
    position: absolute;
    top: $space-xl;
    right: $space-xl;
    @media(max-width: 1024px){
      top: $space-lg;
      right: $space-lg;
    }
    @media(max-width: 768px){
      top: $space-md;
      right: $space-md;
    }
    color: $white;
    transform: scale(2);
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
      fill: $white;

      g,path {
        fill: $white;
      }
    }
  }

  #content {
    background-color: $white;
    margin: auto;
    padding: 40px 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 767px){
      padding: 20px 40px;

    }
    @media(max-height:850px){
      padding: 20px 40px;

    }
  }

  form{
    margin: 0;
    textarea{
      padding: 8px 10px;
    }
  }

  &.active {
    opacity: 1;
    z-index: 999;
    transform: scale(1);
  }
}

.material-modal,.video-modal {
  background-color: rgba(0, 0, 0, .5);
  padding: 70px;
  @media(max-width: 768px){
    padding: 0px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  opacity: 0;
  z-index: -1;
  transform: scale(0);
  transition: all .5s ease-in-out;

  #close {
    position: absolute;
    top: $space-xl;
    right: $space-xl;
    @media(max-width: 1024px){
      top: $space-lg;
      right: $space-lg;
    }
    @media(max-width: 768px){
      top: $space-md;
      right: $space-md;
    }
    color: $white;
    transform: scale(2);
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
      fill: $gray-2;

      g,path {
        fill: $gray-2;
      }
    }
  }

  .content {
    width: 1000px;
    min-height: 500px;
    position: relative;
    @media(max-width: 768px){
      width: 100%;
    }
    background-color: $white;
    margin: auto;
    padding: 40px 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2{
      margin-bottom: 32px;
    }

    .material-tabs{
      display: flex;
      margin-bottom: 32px;
      div.tab{
        margin-right: 16px;
        font-size: 24px;
        position: relative;
        opacity: .7;
        cursor: pointer;

        &.active{
          opacity: 1;
          &:after{
            content:"";
            position: absolute;
            border-bottom: 3px solid $acqua;
            left:0;
            bottom:-8px;
            width: 100%;
            height: 2px;
          }
        }
      }
    }
    .material-wrapper{
      min-height: 340px;
      width: 100%;
      .material{
        z-index:-1;
        opacity: 0;
        position: absolute;
        bottom: 0;
        left:0;
        width: 100%;
        padding: 0 80px;
        &.active{
          opacity: 1;
          z-index: 99;
        }
        .material-carousel{
          .flickity-prev-next-button.next{
            right: -64px;
          }
          .flickity-prev-next-button.previous{
            left: -64px;
          }
          .material-content{
            width: 100%;
            img{
              width: 100%;
            }
            span {
              font-size: 14px;
              font-weight: $weight-bold;
              letter-spacing: .2px;
              color: $gray-2;
              padding: 16px 0px;
              display: block;
              margin-bottom: 32px;
            }

            @media(min-width: 767px){
              width: 33%;
              margin-right: 8px;
            }
          }
        }

      }
    }
  }

  &.active {
    opacity: 1;
    z-index: 999;
    transform: scale(1);
  }
}

.product-gallery{
  background-color: #F2F2F2;
  padding-top: 32px;
  .category-nav{
    h1{
      font-size: 32px;
      word-break: break-all;
    }
  }
  .content {
    .col-12 {
      padding: 0 7px;
    }

    .image-card {
      padding: 8px;
      background-color: white;
      margin-bottom: 32px;
      cursor: pointer;
      @include hover-scale(1.03);
      @include hover-shadow;

      .img {
        width: 100%;
        height: 177px;
        margin-bottom: 16px;
      }

      h4 {
        font-size: 14px;
        letter-spacing: .4px;
        line-height: 19px;
        padding: 0 8px;
        min-height: 38px;
        word-break: break-all;
        color: $gray-2;
      }
    }
  }

  .image-modal {
    background-color: rgba(0, 0, 0, .5);
    padding: 70px;
    @media(max-width: 768px){
      padding: 0px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    opacity: 0;
    z-index: -1;
    transform: scale(0);
    transition: all .5s ease-in-out;

    #close {
      position: absolute;
      top: $space-xl;
      right: $space-xl;
      @media(max-width: 1024px){
        top: $space-lg;
        right: $space-lg;
      }
      @media(max-width: 768px){
        top: $space-md;
        right: $space-md;
      }
      color: $white;
      transform: scale(2);
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
        fill: $white;

        g,path {
          fill: $white;
        }
      }
    }

    &>div {
      width: 90vw;
      height: 80vh;
      @media(max-width: 768px){
        width: 100%;
      }
      background-color: $white;
      margin: auto;
      padding: 40px;
      @media(max-width: 768px){
        padding: 20px;
      }
      display: flex;
      justify-content: center;
      flex-direction: column;

      h4 {
        font-size: 32px;
        letter-spacing: .4px;
        line-height: 19px;
        min-height: 38px;
        padding: 0 16px;
        margin: 0;
        color: $gray-2;
        @media(max-width: 768px){
          font-size: 18px;
          line-height: 19px;
        }
      }
      a{
        padding: 0 16px;
      }
      .img{
        margin-bottom: 32px;
        background-size: contain;
      }

    }

    &.active {
      opacity: 1;
      z-index: 999;
      transform: scale(1);
    }
  }
}

/* Configurator styling for custom list types in specific entries */
.configurator .product .category-custom-293-style {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      display: inline-block;
      height: 120px;
      width: 48%;
      margin-right: 5px;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 25%;
      border-radius: 7px;
      position: relative;
      font-size: .9em;
      color: $gray-2;
      transition: all .25s ease-in-out;

      &:after{
        position: absolute;
        bottom: 18px;
        left: 41%;
        margin: auto;
      }
      &:hover{
        background-color: $white;
        opacity: .8!important;
        box-shadow: 0px 0px 0px 2px rgba($gray-4, 0.2);
        color: $gray-1;
      }

      &.active {
        // border: 3px solid $acqua;
        box-shadow: 0px 0px 0px 2px rgba(0, 174, 239,1);
        background-color: $white;
        font-weight: $weight-bold;
        color: $gray-1;
        opacity: 1.0!important;
      }
      &[data-configuration-name="Single"] {
        // background-color: #ff6666;
        background-image: url('/assets/images/DT-Riser_1screen.svg');
        background-size: 30%;
        opacity: 0.6;

        &:after{
          content:"Single";
          left: 39%;
        }
      }
      &[data-configuration-name="Dual"] {
        // background-color: #66ff66;
        background-image: url('/assets/images/DT-Riser_2screen.svg');
        background-size: 60%;
        opacity: 0.6;
        &:after{
          content:"Dual";
        }
      }
    }
    &.colors{
      display: block;
      li{
        border-radius: 50px;
        width: 80px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }
}
.category-custom-293-color,
.category-custom-79006-frame{
  ul {
    list-style: none;
    padding: 0;
  
    li {
      display: inline-block;
      height: 50px;
      width: 50px;
      margin-right: 5px;
      cursor: pointer;
      box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
  
      &.active {
        border-bottom: 3px solid $acqua;
      }
      &[data-configuration-label="White"] {
        background-color: white;
      }
      &[data-configuration-label="Black"] {
        background-color: black;
      }
    }
  }
}

/* Hide these elements by default, but they're there to override for customization if needed */
.configurator .product .category-custom {
  .label, .price {
    display: none;
  }
}
.configurator .product{
  .category-custom-1247-style,
  .category-custom-44161-model,
  .category-custom-44161-style
  {
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      li {
        display: inline-block;
        position: relative;
        height: auto;
        width: 48%;
        padding: 8px;
        margin-bottom: 8px;
        border-radius: 7px;
        cursor: pointer;
  
        &:before{
          content:"";
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 90%;
          height: 80px;
          display: block;
          margin: 10px;
        }
  
        .label{
          display: inline-block;
          font-size: .9em;
          line-height: 15px;
          width: 70%;
          line-height: 1.1em;
        }
  
        .price {
          display: inline-block;
          // position: absolute;
          padding: 0;
          top: 2px;
          right: 5px;
          font-size: 0.9em;
          width: 20%;
        }
  
        &.active {
          box-shadow: 0px 0px 0px 2px rgba(0, 174, 239,1);
          background-color: $white;
          .price, .label{
            font-weight: $weight-bold;
          }
          color: $gray-1;
          opacity: 1.0!important;
        }
        &[data-configuration-label="Armless with Hard Glides"] {
          &:before{
            background-image: url('/assets/images/BixbyArmlesswithGlides(Soft or Hard).png');
          }
        }
        &[data-configuration-label="Armless with Soft Felt Glides"] {
          &:before{
            background-image: url('/assets/images/BixbyArmlesswithGlides(Soft or Hard).png');
          }
        }
        &[data-configuration-label="Armless with Soft Castors"] {
          &:before{
            background-image: url('/assets/images/BixbyArmlesswithCastors.png');
          }
        }
        &[data-configuration-label="Fixed Arm with Hard Glides"] {
          &:before{
            background-image: url('/assets/images/BixbyFixedArmswithGlides(Soft or Hard).png');
  
          }
        }
        &[data-configuration-label="Fixed Arm with Soft Felt Glides"] {
          &:before{
            background-image: url('/assets/images/BixbyFixedArmswithGlides(Soft or Hard).png');
          }
        }
        &[data-configuration-label="Fixed Arm with Soft Castors"] {
          &:before{
            background-image: url('/assets/images/BixbyFixedArmwithCastors.png');
          }
        }
      }
    }
  }
}


.configurator .product .category-custom-1247-color {
  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      height: 50px;
      width: 50px;
      margin-right: 5px;
      cursor: pointer;
      box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);

      &.active {
        border-bottom: 3px solid $acqua;
      }
      &[data-configuration-label="White"] {
        background-color: white;
      }
      &[data-configuration-label="Platinum"] {
        background-color: #89878C;
      }
      &[data-configuration-label="Sterling Dark"] {
        background-color: #4F4E4E;
      }
      &[data-configuration-label="Black"] {
        background-color: black;
      }
      &[data-configuration-label="Merle"] {
        background-color: #33363a;
      }
    }
  }
}

.configurator .product {
  .category-custom-1215-frame,
  .category-custom-37085-frame_color{
    ul {
      list-style: none;
      padding: 0;
  
      li {
        display: inline-block;
        height: 50px;
        width: 50px;
        margin-right: 5px;
        cursor: pointer;
        box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
  
        &.active {
          border-bottom: 3px solid $acqua;
        }
        &[data-configuration-label="White"] {
          background-color: white;
        }
        &[data-configuration-label="Platinum"] {
          background-color: #89878C;
        }
        &[data-configuration-label="Sterling"] {
          background-color: #4F4E4E;
        }
        &[data-configuration-label="Black"] {
          background-color: black;
        }
        &[data-configuration-label="Merle"] {
          background-color: #33363a;
        }
      }
    }
  }
}

.configurator .product {
  .category-custom-1225-color, //revi
  .category-custom-1245-color,
  .category-custom-44161-frames, //amobi
  .category-custom-44161-color,
  .category-custom-85401-upholstery, // lucas
  .category-custom-79006-finish, // Greenpoint
  .category-custom-85429-return,
  .category-custom-85429-surface,
  .category-custom-85441-finish, // sterling
  .category-custom-85441-upholstery,
  .category-custom-85429-frame,
  .category-custom-85413-finish, // slope
  .category-custom-85413-upholstery,
  .category-custom-85413-conf_armless_seat,
  .category-custom-85413-conf_arms_seat,
  .category-custom-85413-guest_arms_seat,
  .category-custom-85413-guest_armless_seat,
  .category-custom-85413-stool,
  .category-custom-85413-lounge,
  .category-custom-85413-stacking,
  .category-custom-85413-stacking,
  .category-custom-85413-upholstery
  .category-custom-85429-frame, // greenpoint
  .category-custom-85429-desk_laminate,
  .category-custom-85389-upholstery, // mesa
  .category-custom-85389-finish,
  .category-custom-85389-table,
  .category-custom-85661-upholstery, // brighton
  .category-custom-134914-frame, // active 2.0
  .category-custom-134916-frame, // cluvo
  .category-custom-142314-case, // uptake
  .category-custom-142314-headset, //uptake
  .category-custom-20-frame, // zilo
  .category-custom-1231-frame, // jaku
  .category-custom-1233-frame, // siya
  .category-custom-1237-frame, // bodi
  .category-custom-138529-finish // persontality plus
  {
    .current-selected{
      font-weight: 600;
      font-size: 15px;
      letter-spacing: .2px;
    }
    // universal block styles
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
  
      li {
        display: inline-block;
        height: 19%;
        width: 19%;
        margin: 1px;
        border: 1px solid #DCDCDC;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        // box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
        // margin-bottom: 5px;
        background-size: cover;
  
        &.active {
          border-bottom: 3px solid $acqua;
        }
        
        // custom colors
        &[data-configuration-label="White"] {
          background-color: white;
        }
        &[data-configuration-label="Platinum"],
        &[data-configuration-label="Polished"] {
          background-color: #89878C;
        }
        &[data-configuration-label="Black"] {
          background-color: black;
        }
        &[data-configuration-label="Merle"] {
          background-color: #464646;
        }
      }
    }
  }
}


.configurator .product .category-custom-1215-worksurface {
  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      height: 50px;
      width: 50px;
      margin-right: 5px;
      cursor: pointer;
      box-shadow: 0 0 5px -2px rgba(0,0,0,0.3);
      background-size: cover;
      margin-bottom: 5px;

      &.active {
        border-bottom: 3px solid $acqua;
      }
      &[data-configuration-label="Milk White"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.1_MilkWhite.jpg');
      }
      &[data-configuration-label="Maple"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.2_Maple.jpg');
      }
      &[data-configuration-label="Teak"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.3_Teak.jpg');
      }
      &[data-configuration-label="Walnut"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.4_Walnut.jpg');
      }
      &[data-configuration-label="Arctic White"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.5_ArcticWhite.jpg');
      }
      &[data-configuration-label="Seagull"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.6_Seagull.jpg');
      }
      &[data-configuration-label="Ash Wenge"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.7_AshWenge.jpg');
      }
      &[data-configuration-label="Clay Wenge"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.8_ClayWenge.jpg');
      }
      &[data-configuration-label="Acacia"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.5_ArcticWhite.jpg');
      }
      &[data-configuration-label="Graphite Walnut"] {
        background-image: url('https://s3.us-west-1.amazonaws.com/amq-solutions/finishes/AMQ_Laminate_W2.10_GraphiteWalnut.jpg');
      }
    }
  }
}