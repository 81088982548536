#team-modules {
  .textBlock {
    padding: 0px 0px $space-xl 16px;

    h1 {
      text-align: left;
    }

    div {
      padding: 0;

      p {
        font-size: 28px;
        letter-spacing: -.2px;
        color: $gray-3;
        line-height: 36px;
      }
    }
  }

  .teamMemberCards {
    background-color: $gray-7;
    padding: 64px 0;

    .grid {

      .grid-sizer {
        width: 25%;

        @media(max-width:992px) {
          width: 100%;
        }
      }

      .grid-item {
        margin-bottom: 16px;
 

        @media(max-width:767px){
          .team-card__img{
            background-position: 50% 20% !important;
          }
        }

        a {
          padding: 8px;
          display: block;
          width: 100%;
          height: 100%;
          &:hover {
            .team-card .bio {
              opacity: 1;
            }
          }
        }

        &.large {
          width: 50%;
          height: 615px;
          .team-card__img{
            background-position: 50% 20% !important;
          }

          &+.grid-item {
            margin-bottom:16px;

            &+.grid-item {
              margin-bottom: 16px;
            }
          }
        }

        &.small {
          width: 25%;
          height: 300px;
          @media(max-width: 767px){
            height: 400px;
          }
        }

        &.tall {
          width: 25%;
          height: 616px;
          @media(max-width: 767px){
            height: 600px;
          }
        }

        .img {
          padding: 8px;
        }

        .team-card {
          background-color: $white;
          padding: 8px;
          overflow: hidden;
          position: relative;
          height: 100%;

          .new-label {
            position: absolute;
            height: 80px;
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-color: $orange;
            transform: rotate(45deg);
            top: -35px;
            right: -44px;

            span {
              color: $white;
              margin-bottom: 4px;
              font-size: 16px;
              letter-spacing: .26px;
              font-weight: $weight-bold;
            }
          }

          .team-card__img {
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;

            .bio {
              opacity: 0;
              background-color: rgba(0, 0, 0, .5);
              color: $white;
              padding: 16px;
              height: 100%;
              transition: all .25s ease-in-out;
      
              ul {
                @include list-no-style;
                li{
                  font-weight: $weight-regular;
                }
              }
      
              p {
                color: $white;
              }
            }
          }

          .team-card__name {
            padding: 16px 8px;
            bottom: 0px;
            position: absolute;
            width: 100%;
            background-color: $white;

            h4 {
              font-size: 16px;
              letter-spacing: .4px;
              line-height: 19px;
              color: #333333;
              font-weight: $weight-regular;
              margin-bottom: 0;
            }

            span {
              color: $gray-5;
              font-size: 14px;
              letter-spacing: .2px;
              line-height: 16px;
              font-weight: $weight-regular;
            }
          }
        }

        @media(max-width: 992px) {
          width: 100% !important;
        }
      }
    }
  }
}