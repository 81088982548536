
@use "../base/typography";
@use "../base/variables";

.btn {
  border: 2px solid variables.$navy;
  color: variables.$navy;
  font: typography.$weight-bold 12px/15px typography.$beausite;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 0;
  transition: all .3s ease-in-out;
  padding: 14px 42px;
  background: transparent;
  text-transform: uppercase;
  width: fit-content;
  svg {
    color: inherit;
    width: 38px;
  }

  &:hover {
    background-color: variables.$navy;
    border-color: variables.$navy;
    color: variables.$white;
  }
  &:before{
    display: none;
    opacity: 0;
  }

  &.btn-white{
    background-color: variables.$white;
    border-color: variables.$gray-3;
    color: variables.$black;
    &:hover{
      background-color: variables.$gray-3;
      color: variables.$white !important;
    }
  }

  &.btn-navy{
    background-color: variables.$navy;
    color: variables.$white;
    border-color: variables.$navy;
    &:hover{
      background-color: variables.$blue;
      border-color: variables.$blue;
      color: variables.$white;
    }
  }

  &.btn-blue{
    background-color: variables.$blue;
    color: variables.$white !important;
    border-color: variables.$blue;
    &:hover{
      background-color: variables.$navy;
      border-color: variables.$navy;
      color: variables.$white;
    }
  }

  &.btn-black{
    background-color: variables.$black;
    color: variables.$white;
    border-color: variables.$black;
    &:hover{
      background-color: variables.$gray-3;
      border-color: variables.$gray-3;
      color: variables.$white;
    }
    &-outline{
      background:transparent;
      color: variables.$black;

      &:hover{
        background-color: variables.$black;
        border-color: variables.$black;
        color: variables.$white;
      }
    }
  }
  &.btn-orange {
    background-color: variables.$orange;
    border: none;
    color: variables.$white;
    &:hover{
      background-color: #E31D1D;
      color: variables.$white;
    }
  }
  &.btn-lg {
    font-size: 17px;
    line-height: 20px;
  }
  &.btn-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(max-width: 958px){
      span {
        flex: 1;
        white-space: normal;
      }
    }
  }
  &.with-icon {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
    padding: 0 42px;
    svg {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
@keyframes delay {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes furnish {
  0% { opacity: 0; }
  15% { opacity: 0; }
  100% { opacity: 1; }
} 

$delaytime: 3;
$delayincrease: 0.6;

.btn-furnish {
  border-color: variables.$gray-3;
  color: variables.$navy !important;
  &:hover{
    color: variables.$white !important;
  }
  .furnish-icon {
    height: 11px;
    width: 24px;
    margin-right: 6px;
    .triangle {
      fill: variables.$navy;
      stroke: variables.$white;
      animation: 
        delay #{$delaytime}s,
        furnish 0.7s #{$delaytime}s ease-in normal;
    }
    .square {
      fill: variables.$navy;
      stroke: variables.$white;
      animation: 
        delay #{$delaytime+$delayincrease}s,
        furnish 0.7s #{$delaytime+$delayincrease}s ease-in normal;
    }
    .circle {
      fill: variables.$navy;
      stroke: variables.$white;
      animation: 
        delay #{$delaytime+($delayincrease*2)}s,
        furnish 0.7s #{$delaytime+($delayincrease*2)}s ease-in normal;
    }
  }
}
.btn-mobile-navigation {
  border-color: variables.$white;
  color: variables.$white;
  background-color: variables.$navy;
  &:hover {
    border-color: variables.$white;
  }
}