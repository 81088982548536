
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

#about-modules{
  .container{
    padding-bottom: variables.$space-xl;

    &.titleText{
      padding-bottom: 0;
    }
    &.carouselGallery{
      padding-top: 0;
      .flickity-page-dots{
        bottom:30px;
      }
      .carousel{

        .flickity-slider{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img{
          height: auto;
          // aspect-ratio: 16 / 10;
        }
        .carousel-cell {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;
          aspect-ratio: 16/10;
        }
        .overlay {
          position: absolute;
          z-index: 10;
          right: 42px;
          bottom: 42px;
          width: 265px;
          border-radius: 0;
          padding: 16px 24px;
          border: none;
          h2 {
            font: typography.$weight-bold 30px/34px typography.$beausite;
            color: variables.$white;
          }
          a {
            color: variables.$white;
            margin-bottom: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            svg {
              margin-left: 8px;
              width: 15px;
            }
            &:hover {
              color: variables.$white;
            }
          }
        }
      }
    }
    &.copyWithNav{
      .copy{
        p{
          &:last-child{
            @include typography.swiss-small;
          }
        }
        figure{
          float: left;
          padding-right: 24px !important; 
          width: 220px;
          margin: 8px !important;
          img{
            width: 100%;
          }
          &.small {
            width: 100px;
          }
        }
      }
      nav{
        margin-top: 32px;
        h3 {
          margin-bottom: 42px;
        }
        ul{
          @include mixins.list-no-style;
          border-top: 1px solid variables.$border-color;
          padding: 8px 0 8px 0;
          li{
            font: typography.$weight-regular 16px/18px typography.$beausite;
            vertical-align: middle;
            transition: all .25s ease-in-out;
            &:hover{
              background-color: variables.$gray-7;
            }
            a{
              display: flex;
              align-items: center;
              padding: 16px 0;
            }
            .arrow {
              display: flex;
              align-items: center;
              color: variables.$blue;
              margin-left: 8px;
            }
            .icon {
              width: 36px;
              display: flex;
              align-items: center;
              svg {
                color: variables.$gray-3;
              }
            }
          }
        }
      }
    }
    &.imageLinks{
      // .row{
      //   justify-content: space-around;
      // }
      .link-block{
        margin-bottom: variables.$space-lg;
        position: relative;
        svg{
          margin-left: 8px;
        }
        img{
          width: 100%;
          margin-bottom: 8px;
        }
        span{
          display: block;
        }
        a{
          @include mixins.link-overlay;
        }
      }
    }
    &.newsSnippets{
      h2{
        padding-bottom: variables.$space-lg;
      }
      .news-snippet{
        padding-bottom: variables.$space-lg;
        width: 100%;
        .img{
          div{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .copy-block{
          padding: 36px 48px;
          a{
            svg{
              margin-left: variables.$space-sm;
            }
          }
        }
      }
    }
  }
}
#about-modules.plan-design {
  .copyWithNav{
    .copy{
      figure{
        float: none;
        padding-right: 24px !important; 
        width: auto;
        margin: 0 !important;
        img{
          width: auto;
        }
      }
    }
  }
}