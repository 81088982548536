header {
  display: flex;
  flex-direction: column;
  padding-bottom: 9px;
  .logo {
    width: 100%;
    svg {
      color: $navy;
      width: 100%;

      @media(min-width: 767px){
        width: auto;
        margin-right: auto;
      }
    }
  }
  .site-header {
    min-height: 99px;
    display: flex;
    align-items: center;
    @media(min-width:769px){
      min-height: 129px;
    }
  }
  .row {
    align-items: center;

    .nav {
      ul.nav-list__desktop {
        @include list-no-style;
        margin-top: auto;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        position: relative;

        &:after{
          content:"";
          position: absolute;
          height: 85px;
          width: 100%;
          top: 16px;
          right: 0;
          z-index: 99;
        }

        .indicator {
          height: 3px;
          background-color: $primary-color;
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100px;
          transition: all .5s ease-in-out;
          opacity: 0;
        }

        .nav-item {
          text-align: right;
          margin-right: 22px;

          @media(max-width: 1300px){
            text-align: left;
            margin-right: 18px;
          }

          &>a {
            font: $weight-bold 14px/16.8px $beausite;
            letter-spacing: 0.5px;
            color: $header-nav-link-color;
            text-transform: uppercase;
            transition: all .5s ease-in-out;
            @media(max-width:1300px){
              line-height: 14px;
              font: $weight-bold 13px/16.8px $beausite;
            }
          }

          ul.desktop-submenu {
            @include list-no-style;
            position: absolute;
            z-index: 999;
            border-radius: 2px;
            top: 55px;
            right: 0;
            background-color: white;
            width: 100%;
            padding: 32px;
            justify-content: center;
            display: none;
            flex-direction: column;
            transition: all .6s ease-in-out;

            .wrapper {
              display: flex;
              flex-direction: column;

              li.desktop-submenu__item {
                display: inline-block;
                letter-spacing: 1px;
                padding: 16px 8px;

                a {
                  text-transform: capitalize;
                  white-space: nowrap;
                  text-shadow: none;
                  text-align: left;
                  display: block;
                  white-space: pre-wrap;
                  font: $weight-regular 18px/21.6px $beausite;
                  letter-spacing: .4px;
                  transition: all .5s ease-in-out;
                  color: $header-nav-link-color;
                  &:hover {
                    color: $header-nav-link-color;
                  }
                }
              }
            }

            a.bottom {
              display: block;
              margin: auto;
              padding: 16px;
              text-decoration: underline;
            }

            &.active {
              display: flex;
            }
          }

          &.products {
            .desktop-submenu{
              .wrapper {
                width: 100%;
                display: inline-flex;
                justify-content: left;
                flex-direction: row;
                flex-wrap: wrap;

                .product-category{
                  width: 20%;
                  margin: 8px;
                  position: relative;
                  
                  .wrapper-item {
                    position: relative;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 110px;
                    width: 100%;
                    margin: 1px;
                    position: relative;
                    z-index: 10;
                    @include hover-scale(1.1);

                    @media(min-width: 1300px){
                      height: 140px;
                      &[style*="Cluvo"]{
                        background-size: 150px;
                      }
                      &[style*="JAKU"]{
                        background-size: 130px;
                      }
                      &[style*="3F"]{
                        background-size: 150px;
                      }
                    }

                    @media(min-width: 1024px) and (max-width: 1300px){
                      height: 100px;
                      &[style*="Cluvo"]{
                        background-size: 120px;
                      }
                      &[style*="JAKU"]{
                        background-size: 100px;
                      }
                      &[style*="3F"]{
                        background-size: 110px;
                      }
                    }
                  }

                  a span {
                    font: $weight-regular 16px/20px $beausite;
                    text-align: center;
                    z-index: 11;
                    display: block;
                    padding-top: 5px;
                    color: $header-nav-link-color;
                    &:hover {
                      color: $header-nav-link-color;
                    }
                  }
                
                }
              }
              a.btn{
                text-decoration: none;
                padding: 14px 42px;
                margin-top: 32px;
              }
            }
          }

          &.products{
            ul.desktop-submenu{
              width: 150%;

              @media(min-width: 992px) and (max-width:1300px){
                width: 120%;
              }
            }
          }

          &.plan-design{
            ul.desktop-submenu{
              width: 100%;
              & > .row{
                align-items: flex-start;
              }
              .wrapper{
                li.desktop-submenu__item{
                  &:first-child{
                    text-align: left;
                    color: #78857F;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                  }
                }

                @media(min-width:1200px){
                  padding-top: 32px;
                }
              }
              @media(min-width: 1200px){
                width: 135%;
              }
            }
          }

          &.about{
            ul.desktop-submenu{
              width: 55%;
              left: 255px;
            }
          }

          &.service-support{
            ul.desktop-submenu{
              width: 50%;
              left: 335px
            }
          }

          &.about,
          &.plan-design{
            .wrapper + a{
              transition: all .5s ease-in;
              text-align: left;
              padding-left: 8px;
              font-size: 20px;
              color: $acqua;
              display: block;
              margin-top: 40px;
              &:hover{
                svg{
                  transform: translateX(3px);
                }
              }
              svg,g{
                fill: $acqua;
                transition: all .5s ease-in-out;
              }
            }
          }
          &.service-support,
          &.products {
            .btn {
              margin-right: auto;
              margin-left: 8px;
              text-decoration: none;
              padding: 14px 16px;
              margin-top: 16px;
              text-transform: uppercase;
            }
          }
        }
      }
      .product-touts {
        border-left: 1px solid $border-color;
        .product-tout {
          padding: 24px 16px;
          text-align: center;
          font: $weight-regular 14px/17px $beausite;
          p {
            text-align: center;
            font: $weight-regular 14px/17px $beausite;
            margin-bottom: 0;
          }
          img {
            margin-bottom: 16px;
            max-width: 90px;
            height: auto;
          }
        }
      }

      .plan-design-touts {
        background: rgba(211, 220, 215,0.2);
        height: 100%;
        padding: 32px;

        & > h4{
          font-weight: 600;
          font-size: 14px;
          color: #78857F;
          text-transform: uppercase;
          padding: 15px;
          text-align: left;
          letter-spacing: 1px;
        }

        @media(min-width:1200px){
          & > div{
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }

        .plan-design-tout {
          padding: 16px;
          font: $weight-regular 14px/17px $beausite;
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: center;
          .tout-img {
            width: 80px;
            min-width: 80px;
            img {
              max-width: 43px;
              height: auto;
              margin: 8px;
            }
          }
          h4 {
            font: $weight-regular 18px/21px $beausite;
          }
          p {
            font: $weight-regular 14px/17px $beausite;
            margin-bottom: 0;
            color: #78857F;
          }
        }
      }

      .search-form {
        border-bottom: 1px solid $input-border-color;
        margin: 0;

        input {
          border: 0;
          padding: 2px 10px;
          margin: 0;
          width: 130px;

          @media(min-width: 1300px){
            width: 140px;
          }
        }

        i {
          color: $gray-6;
          cursor: pointer;
        }

        @media(min-width: 767px){
          i{
            position: absolute;
            right: 0;
            top: 5px;
          }
        }
      }
    }
    @media(max-width: 767px){
      margin-top: 16px;
      svg{
        width: 140px;
      }
    }
  }



  //mobile nav

  .mobile-menu-container {
    background-color: $navy;
    z-index: 999;
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 180px;
    left: 0;
    overflow-x: hidden;
    transition: all 0.5s ease-in-out;
    height: 0;

    @media(max-width:767px){
      top: 125px;
    }

    &.open {
      height: 950px;
      transition: all 0.5s ease-in-out;
    }

    .mobile-menu {
      @include list-no-style;
      flex-direction: column;
      display: flex;

      .row {
        align-items: flex-start;

        .quick-links{
          position: relative;
          &:after{
            content:"";
            position: absolute;
            width: 90%;
            height: 2px;
            background: rgba(255,255,255,.3);
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
          .mobile-menu__item{
            color: $white;
            &:not(:first-child){
              width: 49%;
              display: inline-block;
              margin-top: 0;
              vertical-align: middle;
            }
            &:nth-child(2){
              padding-right: 4px;
              a{
                border-color: $white;
              }
            }
            &:nth-child(3){
              padding-left: 4px;
            }
            &:nth-child(4){
              padding-right: 4px;
              margin-bottom: 16px;
            }

            [href="/how-to-buy"]{
              
                color: $navy;
                svg{
                  fill: $navy;
                  stroke: $navy;
                  path,circle,rect{
                    fill: $navy;
                    stroke: $navy;
                  }
                }
              
            }

            [href="/furnish-my-office"]{
              color: $white !important;
              border-color: $white;
            }
          }
        }
      }

      li.mobile-menu__item {
        font-weight: $weight-bold;
        padding: $space-sm $space-md;
        margin: 0;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: .2px;
        text-transform: uppercase;
        margin-top: 10px;
        line-height: 17px;

        [href*="/dealers-admin"]{
          // width: fit-content;
          // padding: 8px 16px;
          border-color: #fff;
        }

        a {
          @include link-style($white);
        }

        &.active a {
          @include link-style($gray-6);
        }

        form{
          margin: 0;
          position: relative;
          i{
            position: absolute;
            top: 10px;
            right: 10px;
            color: $white;
          }
          input{
            background-color: $black;
            border: none;
            color: $white;
            transition: all .25s ease-in-out;
            width: 100%;
            border-radius: 0;
            box-shadow: none;
            &::placeholder{
              color: $gray-8;
            }
            &:focus{
              border-bottom: 1px solid $acqua;
            }

          }
        }
        &.child {
          font-weight: $weight-regular !important;
          padding-left: $space-md !important;
          font-size: 15px !important;
          letter-spacing: .2px !important;
          margin-top: 0px !important;

          a{
            text-transform: capitalize;
            color: $gray-4;

          }
        }
      }
    }
    .btn {
      width: 100%;
    }
  }

  #mobile-nav-toggle {
    width: 37px;
    height: 30px;
    float: right;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $gray-3;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 40%;
      }

      &:nth-child(3) {
        top: 40%;
      }

      &:nth-child(4) {
        top: 80%;
      }
    }

    &.open span {
      &:nth-child(1) {
        top: 20%;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }

  #nav-snippet {
    background-color: $gray-8;
    width: 100%;
    display: flex;
    align-items: center;
    @media(min-width:767px) {
      padding: 2px 0px 2px 0;
      min-height: 37px;
    }
    
    .container {
      // position: relative;

      .message {
        text-align: center;
        font-size: 16px;
        text-align: left;

        @media(max-width:767px) {
          padding: 2px 0px 2px 0;
          min-height: 37px;
        }
        @media(min-width:769px){
          margin-right: 128px;
          display: flex;
          align-items: center;
        }
        span{
          display: block;
          @media(min-width: 767px){
            display: inline-block;
            white-space: pre;
          }
        }
        span,
        a {
          color: $orange;
          font-weight: $weight-bold;
          padding-right: 5px;
          font-size: 14px;

          @media(max-width:767px){
            font-size: 12px;
          }

          svg {
            fill: $orange;
            width: 14px;
            transition: all .5s ease-in-out;

            g {
              fill: $orange;
            }
          }
          &:hover{
            svg{
              transform: translateX(3px);
            }
          }
          & + p{
            display: inline-block;
            @media(min-width:768px) and (max-width:1300px){
              white-space: pre;
              text-overflow: ellipsis;
              overflow: hidden;
              vertical-align: middle;
              min-height: 20px;
              padding-top: 3px;
              transition: width 1s ease-in-out;
            }

            @media(min-width:768px) and (max-width:992px){
              max-width: 270px;
            }

            @media(min-width:992px)and (max-width:1300px){
              max-width: 511px;
            }

            &.show{
              max-width: initial;
              overflow: initial;
              white-space: initial;
            }
          }
        }
        p {
          font-size: 14px;
          margin: 4px 0;
          line-height: 13px;
        }
        @media(max-width: 767px){
          p{
            font-size: 12px;
          }
        }

        &.show{
          width: 100%;
        }
      }

      ul {
        text-align: right;
        @include list-no-style;
        display: flex;
        align-items: center;
        margin-left: auto;
        position: absolute;
        right: 9px;
        top: 4px;
        transition: all .5s ease-in-out;

        &.move{
          transform: translateY(-150%);
        }

        li {
          a {
            font-size: 12px;
            color: $gray-4;
            text-transform: uppercase;
            transition: all .25s ease-in-out;
            &[href="/dealers-login"]{
              padding: 6px 8px 5px 8px;
              color:$navy;
              border: 2px solid transparent;
              line-height: 13px;
              position: relative;
              top: -1px;
              &:hover{
                border-color: #78857F;
                color: $navy;
              }
            }
            &[href="/how-to-buy"]{
              svg{
                height: 11px;
                transform: scale(1.5);
              }
            }
            &:hover{
              color: $blue;
            }
            &.btn {
              font-size: 11px;
              line-height: 13px;
              color: $white;
              padding: 6px 14px 5px 5px;
              
              svg {
                margin-bottom: 3px;
              }
            }
          }

          &:first-child {
/*            border-right: 1px solid #C8C8C8;*/
            padding: 0 8px;
          }

          &:last-child {
            padding: 0 8px;
          }

          &.colored{
            padding: 0 12px;
            padding-left: 4px;
            a{
              svg{
                margin-right: 4px;
              }
              color: $white;
              font-size: 11px;
              letter-spacing: .5px;
              font-family: $weight-bold;
            }

            &.orange{
              background: $orange;
            }
          }
        }
        @media(max-width: 767px){
          display: none;
        }
      }
    }
  }

  

  &.logged-in{
    #nav-snippet{
      background: #03354C;
      .message{
        color: $white !important;
        span{
          color: $white !important;
        }
      }
      p{
        color: rgba(255,255,255,.7);
      }
      a{
        
        &[href="/dealers-login"]{
          background-color: $white;
          color: #03354C;
          font-size: 11px;
          line-height: 13px;
          padding: 8px 16px;
          top: -2px;
          position: relative;
        }

        &.btn{
          background-color: transparent !important;
          color: $white !important;
          padding: 8px 0px !important;
          border: none;
          svg{
            display: none !important;
          }
        }
      }
    }
  }
}