body.revitFiles{
  .application{
    margin-bottom: 32px;
    .img{
      aspect-ratio: 1 / 1;
      img{
        max-width: 100%;
        object-fit: contain;
        object-position: center;
        aspect-ratio: 1 / 1;
      }
    }
    .application-wrapper{
      position: relative;
      padding-bottom: 50px;
    }
    ul{
      position: absolute;
      background: $white;
      z-index: 10;
      bottom: initial;
      left: 0;
      width: 100%;
      list-style: none;
      padding: 0;
      overflow: hidden;
      max-height: 50px;
      border: 1px solid #9EACA5;
      cursor: pointer;
      li{
        margin-bottom: 0px;
        padding: 12px 16px;
        
        &:first-child{
          svg{
            float: right;
            position: relative;
            top: 2px;
          }
        }

        &:not(:first-child){
          cursor: pointer;
          border-bottom: 1px solid $gray-6;
          width: 100%;        
          margin: 0;
          i,svg{
            margin-right: 8px;
          }

          span{
            @media(min-width:1300px){
              float: right;
            }
          }
          &:hover{
            background-color: #EBEDEC;
          }
        }

        &:last-child{
          border-bottom: 0;
        }
      }

      &.is-open{
        max-height: 100%;
        li{
          &:first-child{
            font-weight: 700;
          }
        }
      }
    }
  }

  #revit-search{
    margin-bottom: 64px;
    width: 80%;
    border: none;
    border-bottom: 1px solid #9EACA5;
  }

  .search-wrapper{
    i{
      color: #D3DCD7;
    }
  }

  .filter-group {
    max-width: 250px;

    label {
      display: flex;
      align-items: flex-start;
      position: relative;
      // font-weight: $weight-bold;
      letter-spacing: .4px;
      font-size: 16px;
      color: $brand-gray-1;
      z-index: 1;
      cursor: pointer;

      @media(min-width: 1300px){
        align-items: center;
      }

      span {
        word-break: break-all;

        &.clear {
          opacity: 0;
          text-transform: uppercase;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 14px;
          letter-spacing: .35px;
          font-weight: $weight-regular;
          color: $gray-3;
          transition: all .25s ease-in-out;
          z-index: 99;
        }
      }

      p {
        margin: 0;
      }

      input {
        margin-right: 8px;
        margin-top: 0px;
        -webkit-appearance: none;
        background-color: transparent;
        border-bottom: 2px solid $gray-5;
        padding: 9px;
        border-radius: 0px;
        display: inline-block;
        position: relative;
        outline: 0;

        &:checked {
          &+span+span.clear {
            opacity: 1;
            cursor: pointer;
          }

          background-color: $acqua;
          border: 2px solid $gray-3;
          color: $acqua;
          box-shadow: inset 0 0 0 2px #DCDCDC;

          &+span {
            font-weight: $weight-bold;
          }
        }

        &:checked:active {
          background-color: $acqua;
        }
      }
    }
  }

  .clear-filters {
    cursor: pointer;
    float: right;
    display: block;
    margin-bottom: 16px;
  }

  .clear-tag {
    padding: 4px 8px;
    border: 1px solid $gray-5;
    cursor: pointer;
    text-transform: capitalize;
    margin: 4px 0;
    margin-right: 8px;
    display: inline-block;
    max-width: 100%;
    word-break: break-all;

    .clear {
      font-weight: 700;
    }
  }

  .pagination{
    display: block;
    margin: auto;
    display: flex;
    justify-content: center;
    ul{
      display: flex;
      justify-content: center;
      list-style: none;
      li{
        border: 1px solid $gray-5;
        a{
          padding: 0 8px;
        }
        &.active{
          background-color: $acqua;
          border-color: $acqua;
          color: $white;
          a{
            color: $white;
          }
        }
      }
    }
  }
}