
@use "../base/typography";
@use "../base/variables";

body.articleLanding{

  .row{
    padding-bottom: 20px;
    p{
      span{
        display: inline;
        margin-left: 100px;
      }
    }
    .article-title{
      & > div{
        color: variables.$gray-2;
        height: 100%;
        position: relative;
        h3{
          @include typography.h2;
          margin-bottom: 24px;
        }
        .img{
          background-size: cover;
          background-position: center;
          width: 100%;
          aspect-ratio: 16 / 10;
        }
      }
    }
    .article-description{
      padding-left: 0;
      position: relative;
      span.post-date{
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        display: block;
        line-height: 16px;
        letter-spacing: .85px;
        display: block;
        margin-bottom: 16px;
        color: variables.$gray-2;
        font-weight: 400;
      }
      .body{
        display: -webkit-box; 
        -webkit-box-orient: vertical;
        overflow: hidden; 
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        margin-bottom: 10px;
      }
      & > div{
        // min-height: 242px;
        height: 242px;
        padding: 16px 30px;
        font-size: 18px;
        letter-spacing: .2px;
        line-height: 28px;
        position: relative;
        color: variables.$gray-3;
        position: relative;
        
        &:after{
          content: "";
          display: block;
          clear: both;
          position: absolute;
          height: 3px;
          background-color: variables.$gray-7;
          width: 100%;
          bottom: -6px;
          left: 0;
        }
        h3{
          color: variables.$navy;
          font-size: 30px;
          line-height: 36px;
          letter-spacing: -0.36px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        a{
          display: block;
          margin-top: 16px;
          font-size: 16px;
          color: variables.$gray-3;
          font-weight: 400;
          letter-spacing: .4px;
          position: absolute;
          bottom: 15px;
          left: 15px;
          @media(min-width:992px){
            bottom: 10px;
            left: 30px;
          }
          svg{
            margin-left: 4px;
          }
        }
      }
    }
    @media(max-width:992px){
      margin-bottom: 32px;

      .article-description{
        & > div{
          height: auto;
          padding: 15px;
          // display: -webkit-box;
          // text-overflow: ellipsis;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          &:after{
            position: relative;
          }
        }
      }
    }
  }

  .filters-row{
    margin: 64px 0;
    position: relative;
    border: 1px solid #58595B;
    width: 300px;
    display: inline-block;
    margin-right: 16px;
    select{
      border: 1px solid #979797;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
      font-weight: typography.$weight-bold;
      color: #58595B;
      font-size: 16px;
      line-height: 19px;
      option{
        padding: 10px 13px;
      }
    }
  }

  /* filters */

	/*the container must be positioned relative:*/
	.filters-row select {
		display: none ; /*hide original SELECT element:*/
	}
	.select-selected{
    color: #58595B;
		width: 250px;
    display: inline-block;
    border: 1px solid #58595B;
    &:after {
      position: absolute;
      content: "";
      top: 18px;
      right: 8px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: #778F9B transparent transparent transparent;
      transition: all .4s ease-in-out;
    }
    &:before{
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width:100%;
      height: 100%;
    }
  }

	/*style the items (options), including the selected item:*/
	.select-items div,.select-selected {
		padding: 8px 16px;
		border: 1px solid transparent;
		cursor: pointer;
		user-select: none;
		font-size: 16px;
    font-weight: typography.$weight-regular;
	}
	.select-items div{
		padding: 8px 24px;
	}
	/*style items (options):*/
	.select-items {
		position: absolute;
		top: 93%;
		left: -1px;
		right: 0;
		z-index: 99999999;
		background-color: white;
		font-size: 16px;
		border: 1px solid variables.$gray-2;
		border-top: none;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
		width: 100.7%;
	}
	/*hide the items when the select box is closed:*/
	.select-hide {
		display: none;
	}
	.select-items div:hover, .same-as-selected {
		background-color:variables.$gray-2;
		color: variables.$white !important;
	}
	
	@media (max-width: 768px) {
		.filters-row, .filters {
			flex-direction: column;
		}
		.filters-row{
			margin: 4px;
			width: 46%;
			display: inline-block;
			vertical-align: top;
			a{
				padding: 3px 20px;
			}
		} 
	}
}