
@use "../base/typography";
@use "../base/variables";

section.map{
  padding: 2*variables.$space-xl 0;
  position: relative;

  h2{
    text-align: center;
    margin-bottom: variables.$space-lg;
  }
  img{
    width: 100%;
  }
  height: 500px;
  @media(min-width:413px){
    min-height: 550px;
  }
  @media(min-width:993px){
    min-height: 1000px;
  }
  @media(max-width:400px){
    height: 350px;
    margin-bottom: 120px;
  }
  #map{
    height: 100%;
    padding: 64px;
    position: relative;
    background-color: variables.$blue !important;
    select{
      display: none;
    }
    svg{
      max-width: 940px;
      display: block;
      margin: auto;
      path{
        &:hover{
          stroke: #2a4668;
          cursor: pointer;
        }
      }
    }
    @media(max-width:993px){
      padding: 0;
      background-image:url('/assets/images/map-2.png');
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      div.select-wrapper{
        position: relative;
        z-index: 10;
        &:after {
          position: absolute;
          // content: "";
          top: 21px;
          right: 8px;
          width: 0;
          height: 0;
          border: 6px solid transparent;
          border-color: variables.$gray-4 transparent transparent transparent;
          transition: all .4s ease-in-out;
        }
    
        select{
          display: block;
          border: 1px solid variables.$gray-4;
          border-radius: 0;
          padding: 12px 24px;
          position: relative;
          font-size: 18px;
          font-weight: typography.$weight-regular;
  
        }
      }
    }

    @media(max-width:767px){
      background-position: center;
    }
  }
  .row{
    @media(min-width:767px){
      flex-wrap: nowrap;
    }
    .col-12{
      display: none;
      margin-bottom: variables.$space-lg;
      width: auto;
      &.active{
        display: block;
        border-left: 1px solid variables.$gray-4;
        padding-left: 32px;

        @media(max-width: 767px){
          &:not(:last-child){
            border-bottom: 1px solid #DEDEDE;
            padding-bottom: 32px;
          }
        }
      }
      &.v-line{
        border-left: none;
        padding-left: 15px;
      }
    }
    &.expand{
      .col-12{
        flex: 0 0 33%;
        max-width: 33%;
      }
    }
    &.default-rep{
      flex-direction: row-reverse;
    }
  }
  #reps{
    position: absolute;
    padding-top: 64px;
    display: none;
    top: 250px;
    z-index: 99;
    left: 0;
    right: 0;
    margin: auto;
    height: auto;
    max-width: 350px;
    background-color: rgba(255,255,255,.8);
    border-radius: 4px;
    box-shadow: 1px 1px 40px rgba(0,0,0,.25);
    padding: 32px;
    h2{
      text-align: left;
      font-weight: typography.$weight-bold;
    }
    .close{
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 10;
      i{
        color: variables.$black;
      }
    }
    .member{

      p{
        min-height: 56px;
        margin-bottom: 8px;
      }
      span{
        font-weight: typography.$weight-bold;
        display: block;
        margin-bottom: 16px;
        span{
          font-weight: typography.$weight-regular;
          color: variables.$gray-4;
        }
      }
      a.email{
        color: variables.$acqua;
      }
      &.tara-wagner{
        p{
          margin-bottom: 16px;
        }
        span{
          display: none;
        }
      }
    }
    &.two-reps{
      max-width: 600px;
    }
    &.three-reps{
      max-width: 980px;

    }

    @media(max-width:767px){
      top: 100px;
      background-color: rgba(255,255,255,.95);
      max-width: initial;
    }
  }
  @media(max-width:993px){
    padding: variables.$space-lg 0;
  }
}