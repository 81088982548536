.openPositions {
  section {
    margin: 64px 0;

    h2 {
      margin-bottom: 32px;
    }

    a {
      &:hover {
        svg {
          g {
            fill: $acqua;
          }
        }
      }

      svg {
        transform: rotate(180deg);

        g {
          fill: $gray-2;
          transition: all .25s ease-in-out;
        }
      }
    }
  }
}