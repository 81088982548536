/* ON/OFF Switch */
.onoffswitch {
  position: relative;
  width: 127px;
  display: inline-block;
  font-size: 14px;
}
.onoffswitch .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 90%);
  margin: 0;
  border-radius: 15px;
  margin-bottom: 10px;
}
.onoffswitch .onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.25s ease-in-out;
  font-weight: $weight-bold;
}
.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 31px;
  padding: 0;
  line-height: 31px;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
  box-sizing: border-box;
  font-size: 14px;
}
.onoffswitch .onoffswitch-inner:before {
  content: "Included";
  padding-left: 25px;
  background-color: $acqua;
  color: hsl(0, 0%, 100%);
}
.onoffswitch .onoffswitch-inner:after {
  content: "Not Included";
  padding-right: 10px;
  background-color: hsl(0, 0%, 98%);
  color: hsl(0, 0%, 24%);
  text-align: right;
}

.onoffswitch.options .onoffswitch-inner:before {
  content: "Added";
  padding-left: 25px;
  background-color: $acqua;
  color: hsl(0, 0%, 100%);
}
.onoffswitch.options .onoffswitch-inner:after {
  content: "Not Added";
  padding-right: 10px;
  background-color: hsl(0, 0%, 98%);
  color: hsl(0, 0%, 24%);
  text-align: right;
}

.onoffswitch .onoffswitch-switch {
  width: 22px;
  height: 22px;
  margin: 0;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 5px;
  left: 4px;
  bottom: 0;
  right: 78px;
  transition: all .15s ease-in-out;
  border-radius: 11px;
}
.toggle-group {
  position: relative;
  height: 35px;
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  /* top: 50%; */
  /* left: 20px; */
  /* margin-top: -13.5px; */
  overflow: hidden;
  width: 130px;
  display: inline-block;
  vertical-align: middle;
  & + .price{
    font-weight: $weight-bold;
  }
}
.toggle-group input[type=checkbox] {
  position: absolute;
  left: 10px;
  visibility: hidden;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
  right: 6px;
  left: initial;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.toggle-group input[type=checkbox]:focus ~ .onoffswitch {
  outline: thin dotted #333;
  outline: 0;
}
.toggle-group label {
  position: absolute;
  cursor: pointer;
  padding-left: 65px;
  display: inline-block;
  text-align: left;
  line-height: 31px;
  width: 100%;
  z-index: 1;
  height: 31px;
  font-weight: 200;
}
/* ==== Accessibility ===== */
.aural {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.aural:focus {
  clip: rect(0, 0, 0, 0);
  font-size: 1em;
  height: auto;
  outline: thin dotted;
  position: static !important;
  width: auto;
  overflow: visible;
}