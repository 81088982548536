
@use "../base/typography";
@use "../base/variables";

.dealer-search {
  background-color: variables.$navy;
  padding: 60px 0;
  margin-bottom: 0 !important;
  .submitted-message{
    p{
      @include typography.h3;
    }
  }
  img{
    max-width: 100%;
  }
  h1.page-title {
    color: #fff;

    font-size: 46px;
    line-height: 55px;
    letter-spacing: -0.76px;
    text-align: left;
    position: relative;
    z-index: 2;
    font-weight: 600;
    top: -10px;

    & + span{
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      opacity: .8;
      /* identical to box height */
      color: variables.$white;
      letter-spacing: -0.34px;
    }
  }

  .col-lg-4{
    @media(min-width: 767px){
      padding: 32px 0;
    }
  }

  .stores-type-container {
    h3 {
      @include typography.h3;
    }
  }

  .dealer-locator-content {
    max-width: 900px;
    margin: 0 auto 8rem;
  }

  .dealer-locator-table {
    width: 100%;
    margin-bottom: 11rem;
    color: #000;

    tr {
      border-bottom: solid 2px #EAEAEA;
    }

    td {
      padding: 16px 16px 16px 0;
    }

    thead {
      th {
        font-size: 1.4rem;
        line-height: 1.75em;
        color: #787878;
        padding-bottom: 0.5rem;
        vertical-align: top;
      }
    }

    tbody {
      font: typography.$weight-regular 19px/34px typography.$beausite;


      td {
        font-size: 19px;
        line-height: 22px;
        vertical-align: top;

        // &:first-child {
        //     font: $weight-bold 14px/14px $beausite;

        // }
      }
    }

    @media (min-width: 992px) {
      .dealer-locator-table-col-title {
        width: 25%;
      }

      .dealer-locator-table-col-email {
        width: 35%;
      }

      .dealer-locator-table-col-phone {
        width: 20%;
      }

      .dealer-locator-table-col-job-title {
        width: 20%;
      }
    }
  }

  .bg-has-overlay {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      content: '';
    }
  }

  .loading-spinner {
    display: inline-block;
    border: 4px solid #253667;
    border-left-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: 'fa-spin'1.2s infinite linear;
  }

}

.dealer-locator {
  #hsForm_2acfb9ef-0602-4af9-8860-f06709b643af{
    &:after,
    &:before{
      display: none !important;
    }
    padding-top: 0 !important;
  }
  background-color: variables.$white;
  padding:41px;
  position: relative;
  min-height: 500px;
  .container{
    padding: 0;
  }
  .dealer-msg{
    @media(max-width:767px){
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .start-over{
    margin-bottom: 20px;
    @media(min-width:767px){
      position: absolute;
      top: 41px; 
      right: 41px;
    }
  }

  .dealer{
    margin-bottom: 32px;
  }

  h2 {
    font: typography.$weight-bold 14px/14px typography.$beausite;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #121212;
    letter-spacing: -0.28px;
  }

  p{
    margin-bottom: 145px;
  }

  .state-selector-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  .styled-custom-select-option {
    min-width: 20rem;
    margin: 0 auto;
  }

  .dealer-form {
    margin-bottom: 0;

    &__inputs{
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: auto auto;
    }


    .form-group{
      // width: 48%;
    }

    input[type="text"] {
      // border-radius: 50px;
      border: 1px solid variables.$black;
      margin-right: 8px;
    }

    input {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      padding: 8px 16px;

      &.btn{
        width: auto;
        font-size: 16px;
        padding: 4px 16px;
      }
    }

  }

  @media(max-width:767px){
    padding: 30px;
    margin-top: 32px;
  }
}

.dealer-locator-grid{
  p{
    margin-bottom: 0;
  }

  .dealer-title{
    font-weight: typography.$weight-bold;
    color: variables.$navy
  }
}