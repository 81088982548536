@use "../base/variables";

body.news{
  footer{
    margin-top: 0;
  }
  img{
    width: 100%;
    // aspect-ratio: 16 / 9;
    // object-fit: contain;
  }
  .post-date{
    text-transform: uppercase;
    letter-spacing: .85px;
    color: variables.$gray-2;
    margin: 16px 0;
    font-size: 12px;
    font-weight: 600;
  }
  p{
    color: variables.$gray-2;
  }
  hr{
    background-color: variables.$gray-7;
    height: 3px;
  }
  ul,ol{
    li{
      color: variables.$gray-2;
      font-size: 18px;
    }
  }

  .more-news{
    background-color: #EBEDEC;
    padding: 32px 0;
    margin-top: 64px;
    padding-bottom: 64px;
    h2{
      color: variables.$navy;
      margin-bottom: 32px;
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: -.34px;
    }
    .articles-wrapper{
      justify-content: space-between;
      @media(min-width:992px){
        display: flex;
      }
      .article{
        flex: 0 0 48%;
        position: relative;
        h3{
          color: variables.$navy;
          margin-bottom: 8px;
          font-size: 22px;
        }
        
        color: variables.$gray-2;
        font-size: 18px;
        
        span{
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 12px;
          display: block;
          line-height: 16px;
          letter-spacing: .85px;
          display: block;
          margin-bottom: 20px;
          color: variables.$gray-3;
          margin-top: 0;
          font-weight: 400;
        }
        @media(min-width:992px){
          &:first-child{
            position: relative;
            &:after{
              content:"";
              position: absolute;
              right: -16px;
              width: 1px;
              height: 100%;
              top: 0;
              background-color: variables.$gray-4;
            }
          }
        }

        @media(max-width:992px){
          margin-bottom: 40px;
          &:first-child{
            position: relative;
            &:after{
              content:"";
              position: absolute;
              right: 0;
              width: 100%;
              height: 1px;
              bottom: -20px;
              background-color: variables.$gray-4;
            }
          }
        }
      }
    }
  }
}