
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

body.resources{
  .banner{
    min-height: 300px;
    background-size: cover;
    margin-bottom: 128px;
  }
  main{
    h2{
      margin-bottom: variables.$space-lg;
    }
    .container{
      position: relative;
      margin-bottom: variables.$space-xl;
      .assetGroup{
        h3 {
          margin-top: variables.$space-lg2;
        }
        ul.resource-list{
          margin-bottom: variables.$space-lg;
          @include mixins.list-no-style;
          li{
            margin-bottom: variables.$space-md;
            svg{
              margin-right: 16px;
              vertical-align: middle;
            }
            a{
              text-decoration: none;
            }
          }
        }
      }
    }

    .dealer-tools{
      margin-top: variables.$space-lg;
      width: 100%;
      & > p, & > span{
        font-size: 12px;
        line-height: 17px;
        margin: 0;
        padding: 0 16px;
        a{
          text-decoration: underline;
        }
      }
      .dealer-tools__block{
        background-color: variables.$gray-2;
        padding: 32px;
        margin-bottom: 32px;
        color: variables.$white;
        h3,p{
          font-weight: typography.$weight-regular;
          color: variables.$white;
        }
        p{
          margin-bottom: 64px;
        }
      }
      a{
        text-decoration: none;
      }

      @media(min-width: 767px){
        position: absolute;
        top:0;
        right: 0;
        width: 350px;
      }
    }
  }
}