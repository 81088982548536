.planningQuiz {
  h6{
    text-align: center;
    color: $gray-1;
    margin-bottom: 16px;
  }

  main{
    padding: 20px 0;
  }

  .hbspt-form {
    display: none;
  }

  .row.question{
    margin-bottom: 32px;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0;
    display: none;

    &.animated{
      display: flex;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    
    h2{
      @include h1;
      color: $gray-1;
      text-align: center;
      margin-bottom: 32px;

      @media(min-width:992px){
        max-width: 700px;
        margin: auto;
        margin-bottom: 32px;
      }
    }
    .img{
      display: flex;
      align-items: center;
    }
    input{
      &.email-input{
        width: 100%;
        border-color: $blue;
        text-align: center;
        color: #1B3733;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.34px;
        max-width: 515px;
        margin: auto;
        margin-bottom: 32px;
        border-radius: 3px;
        display: block;
        @media(max-width: 767px){
          max-width: 100%;
        }

      }
    }

    select {
      outline: 0;
      border: 1px solid hsl(216, 2%, 51%);
      transition: all .25s ease-in-out;
      width: 100%;
      border-radius: 0;
      padding: 8px 10px;
      min-height: 42px;
      width: 34%;
      font-size: 30px;
      font-weight: $weight-bold;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1805.18'%3E%3Cpath fill='%23595d64' d='M461.6,643.4L10.9,79.9C-14.9,47.7,8,0,49.3,0h901.5c41.2,0,64.1,47.7,38.4,79.9L538.4,643.4	C518.7,668,481.3,668,461.6,643.4z' transform='rotate(180 500 902.59) translate(0 1143.28)'%3E%3C/path%3E%3Cpath fill='%23595d64' d='M461.6,643.4L10.9,79.9C-14.9,47.7,8,0,49.3,0h901.5c41.2,0,64.1,47.7,38.4,79.9L538.4,643.4	C518.7,668,481.3,668,461.6,643.4z' transform='translate(0 1143.28)'%3E%3C/path%3E%3C/svg%3E");
      background-position: right 7px top 50%;
      background-size: .5em;
      background-repeat: no-repeat;
      display: block;
      margin: auto;
      margin-bottom: 32px;

      @media(max-width:1300px){
        width: 45%;
      }
      @media(max-width:767px){
        width: 100%;
      }
    }

    .form-content{
      img{
        display: block;
        margin: auto;
      }
    }

    ul.inputs{
      list-style: none;
      padding: 0;
      &.center{
        justify-content: center !important;
        li{
          margin-right: 16px;
        }
      }
      li{
        @include h3;
        position: relative;
        width: 100%;
        color: $gray-1;
        background-color: $lightgray;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        border: 2px solid $lightgray;
        margin-bottom: 21px;
        cursor: pointer;
        text-transform: lowercase;
        position: relative;
        transition: all 0.5s ease-in-out;

        img {
          position: absolute;
          max-width: 80%;
          height: auto;
          bottom: 90%;
          left: 10%;
          z-index:-1;
          opacity: 0;
          box-shadow: 0px 5px 15px rgba(0, 34, 31, 0.25);
          transition: all 0.5s ease-in-out;
        }

        svg{
          background-color: $blue;
          border-radius: 2.66px;
          padding: 2px;
          height: 28px;
          width: 28px;
          position: absolute;
          left: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          display: none;
        }

        &:hover{
          border: 2px solid $blue;
          img {
            opacity: 1;
            z-index: 99;
          }
        }
  
        &.active{
          border: 2px solid $blue;
          svg{
            display: block;
          }
        }
      }
    }
  
    .next-btn{
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      padding: 11px 24px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 12px;
      text-align: center;
      display: flex;
      justify-content: center;
      font-weight: $weight-bold;

      svg{
        margin-left: 8px;
        margin-top: 0px;
      }

      &.submit{
        svg{
          height: 25px;
        }
        max-height: 48px;
      }
    }
  
    &.items{
      ul.inputs{
        @media(min-width: 992px){
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        li{
          padding: 12px 45px;

          @media(min-width:992px){
            max-width: 32%;
            margin-bottom: 37px;

          }
        }
      }

      form{
        max-width: 515px;
        margin: auto;

        & + div{
          max-width: 515px;
          margin: auto;
        }
      }
  
      .next-btn{
        @media(min-width:992px){
          max-width: 32%;
          margin: auto;
        }
      }
    }
  
    &.itemsWithImage{
      .next-btn{
        margin-top: 32px;
      }
      img{
        max-width: 100%;
      }

      @media(max-width:767px){
        ul.inputs li{
          padding: 12px 45px;
        }
        .img{
          margin-bottom: 32px;
        }
      }
    }
  }
}