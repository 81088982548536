.howToBuy{
  footer{
    margin-top: 0;
  }
  .how-to-buy-form-modal-launch{
    cursor: pointer;
  }
  section{
    &.intro{
      .ready-to-buy{
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        p{
          margin-bottom: 0;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          letter-spacing: .4px;
          &:first-child{
            text-decoration: underline;
          }
        }
        svg{
          float: right;
          margin-left: 16px;
        }

        @media(max-width:992px){
          margin-bottom: 8px;
        }
      }
    }

    &.banner{
      .container{
        position: relative;
        .bg{
          background-size: cover;
          min-height: 450px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-position: center;
        }

        .tout{
          width: 100%;
          height: fit-content;
          padding: 32px;
          
          background-color: $cream;
          color: #78857F;
          a{
            margin-top: 32px;
          }

          @media(min-width:992px){
            max-width: 400px;
            margin: auto;
            padding: 64px;
            position: absolute;
            right: 40px;
            top: 0;
            bottom: 0;
          }
        }
      }
    }

    &.number{
      padding: 64px 0;
      @media(min-width:992px){
        padding: 32px 0;
        &:first-child{
          padding-bottom: 0;
        }
      }

      &.gray{
        background-color: #F5F5F5;
        @media(min-width:992px){
          margin-top: 64px;
        }
      }
      .copy{
        display: flex;
        align-items: center;
        & > div{
          position: relative;
          .circle{
            display: inline;
            height: 50px;
            width: 50px;
            background-color: #039EFF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            color: $white;
            font-size: 35px;
            margin-bottom: 16px;

            @media(min-width:992px){
              height: 80px;
              width: 80px;
              position: absolute;
              left: -120px;
              top: 0;
              font-size: 40px;
            }
          }
        }

        a{
          &.btn{
            text-decoration: none;
            &:hover{
              color: $white;
            }
          }
        }
      }
      .image{
        img{
          margin-bottom: 16px;
          width: 100%;
          @media(min-width:992px){
            max-width: 70%;
            margin-bottom: 0;
          }
        }
      }
      &.image-right{
        .row{
          flex-direction: row-reverse;
        }
      }

      &.bg-image{
        background-position: bottom left;
        background-size: 30%;
        background-repeat: no-repeat;
        @media(max-width:992px){
          background-image: none !important;
          padding: 32px 15px;
        }
        @media(min-width:992px){
          padding: 64px 0;
          .image{
            img{
              display: none;
            }
          }
        }
      }

      &.animation{
        @media(min-width:768px){
          .image{
            img{
              transform: translateX(-200%);
              transition: all 3s ease-in-out;
              max-width: 100%;
            }
  
            &.show{
              img{
                transform: translateX(-5%);
              }
            }
          }
        }
      }
    }

    &.featured-product-lines{
      padding-bottom: 64px;
    }

    &.contact-us{
      padding: 128px 0;
      .row{
        svg,
        img{
          margin-right: 8px;
        }
        p{
          margin-bottom: 0;
          font-weight: 400;
        }
        a{
          text-decoration: underline;
        }
        justify-content: center;
        align-items: center;
      }
    }
  }
  #find-a-dealer{
    #content{
      position: relative;
    }
    .submitted-message{
      display:none !important;
    }

    .start-over{
      margin-bottom: 20px;
      @media(min-width:767px){
        position: absolute;
        top: 25px; 
        right: 25px;
      }
    }
  } 

}