.homepage {
  section{
    padding-top: $space-xl;
    &:last-child{
      padding-bottom: $space-xl;
    }
    &.banner {
      min-height: 620px;
      padding-top: 0;
      .flickity-page-dots {
        bottom: 30px;
        @media(max-width: 958px){
          display: none;
        }
      }
  
      .carousel {
        video{
          position: absolute;
          left: 0;
          top: -1px;
          width: 100%;
          height: 101%;
          object-fit: cover;
        }
        .flickity-viewport {
          height: 30vh !important;
          min-height: 620px;
          .carousel-block{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            &[aria-hidden="true"]{
              a{
                display: none !important;
              }
            }
            @media(min-width: 959px){
              flex-direction: row;
            }
            .img {
              height: 400px;
              position: relative;
              @media(min-width: 959px){
                min-width: 50%;
                flex: 1 0 50%;
                height: 100%;
              }
            }
            .tout{
              height: auto;
              color: $white;
              bottom:0;
              padding: 16px;
              @media(min-width: 959px){
                height: 100%;
                min-width: 50%;
                flex: 1 0 50%;
              }
              h2{
                @include tout-title;
                margin-bottom: 14px;

                @media(min-width: 959px) and (max-width: 1300px){
                  font-size: 78px;
                  line-height: 1.1em;
                }
              }
              p, a{
                color: $white;
              }
              p{
                @include tout-copy;
                opacity: .7;
              }
              a{
                display: flex;
                align-items: center;
                font-size: 19px;
                line-height: 30px;

                svg{
                  margin-left: 8px;
                  padding-top: 1px;
                  transition: all .5s ease-in-out;
                }
                &:hover{
                  svg{
                    transform: translateX(3px);
                  }
                }
              }
              @media(max-width: 768px) and (min-width: 300px){
                padding: 18px;
              }
              @media(max-width: 1023px) and (min-width: 769px){
                padding: 24px;
              }
              @media(min-width: 1024px){
                padding: 32px;
              }
            }
          }
          @media(min-width: 1024px){
            height: 30vw !important;
          }
        }
      }
    }
    &.fullWidthImage{
      position: relative;
      padding-top: 64px;
      & + .map{
        padding-top: 64px;
      }
      .container{
        height: 45vw;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    
      }
      a{
        @include overlay;
      }
      @include media-breakpoint-down(md){
        padding-top: 64px;
      }

      @media(min-width: 1500px) {
        .container{
          height: 35vw;
        }
      }
    }
    &.support{
      padding: 2*$space-xl 0 0 0;
      @media(max-width:767px){
        padding:0;
      }

      h2{
        text-align: center;
        margin-bottom: $space-lg;
      }
      .row{
        justify-content: center;
      }
      .link-block{
        margin-bottom: $space-lg;
        position: relative;
        @include hover-scale(1.05);

        svg{
          margin-left: 8px;
        }
        img{
          width: 100%;
          height: auto;
          overflow: hidden;
          margin-bottom: 8px;
        }
        span{
          display: flex;
          align-items: center;
        }
        a{
          @include link-overlay;
        }
      }
    }
  }
}
.homepage-touts {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    .col-12 {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .tout-heading {
    display: flex;
    flex-direction: column;
    padding: 32px;
    color: $white;
    min-height: 280px;
    margin-bottom: 12px;
    margin-top: 16px;

    @media(min-width: 959px){
      min-height: 320px;
      margin-bottom: 32px;
      margin-top: 0;
    }
    h2 {
      color: $white;
      font: $weight-bold 50px/50px $beausite;
    }
    svg {
      color: inherit;
    }
  }
  .bg-img {
    max-height: 120px;
    max-width: 120px;
    width: auto;
    height: auto;
  }
}