body.sideBars{
  .body-content{
    padding-right: 64px;
    & > p{
      @include h3;
      color: $text-color;
    }
    img{
      width: 100%;
      margin-bottom: 64px;
    }
  }

  footer{
    margin-top: 0;
  }
}