.new{
  #footer{
    margin-top: 0;
  }
  .intro{
    h1{
      margin-bottom: 0;
    }
    h2{
      font-weight: 400;
      color: $brand-gray-3;
      letter-spacing: -.34px;
    }
    p{
      color: $brand-gray-1;
    }
    margin-bottom: 32px;
  }
  .anchors{
    ul{
      list-style: none;
      padding: 0;
      display: grid;
      gap: 0 16px;
      grid-template-columns: repeat(1, 1fr);
      li{

        display: inline-block;
        a{
          color: $navy;
          font-weight: 700;
          @media(min-width: 580px){
            word-spacing:9999px;
          }

          svg{
            margin-left: 8px;
            path{
              fill: $blue;
            }
          }
        }
      }

      @media(min-width:580px){
        grid-template-columns: repeat(4, 1fr);
      }

      @media(min-width:767px){
        grid-template-columns: repeat(5, 1fr);
      }

      @media(min-width:992px){
        grid-template-columns: repeat(7, 1fr);
      }
    }

    @media(max-width:767px){
      display: none;
    }
  }
  .whatsnew-section{
    img{
      width: 100%;
    }
    ul{
      list-style: none;
      padding: 0;
    }

    h2{
      margin-bottom: 32px;
      color: $navy;
      

      a{
        color: $navy;
        position: relative;
        svg{
          position: absolute;
          right: -32px;
          top: 12px;
  
          path{
            fill: $navy;
          }
        }
      }
    }

    padding: 32px 0;
    &.newproducts{
      .new{
        background-color: $orange;
        color: $white !important;
        display: inline-block;
        font-size: 11px;
        font-weight: $weight-regular;
        letter-spacing: .4px;
        padding: 0 8px;
        border-radius: 3px;
        line-height: 17px;
        position: relative;
        margin-right: 3px;
        position: absolute;
        top: 65%;
        left: 20px;
      }
      background-color: #F5F5F5;
      .grid{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        gap: 10px 20px;
        
        .product{
          position: relative;
          background-color: $white;
          padding: 20px;
          img{
            object-fit: contain;
            min-height: 185px;
            margin-bottom: 16px;
          }
          h4{
            margin-bottom: 0px;
            line-height: 22px;
            font-weight: 700;
          }
          span{
            font-size: 12px;
            color: $gray-3;
          }
        }

        @media(min-width: 768px){
          grid-template-columns: 1fr 1fr;
        }

        @media(min-width: 992px){
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 20px 40px;
        }
      }

    }

    &.newtools{
      .col-lg-4{
        position: relative;
        &:not(:last-child){
          &:after{
            content:"";
            position: absolute;
            top: 0;
            right: 0px;
            height: 100%;
            width: 1px;
            background-color: $gray-5;
          }
        }
      }
      .tool{
        .col-9{
          padding-right: 40px;
          h2{
            a{
              color: $navy;
              font-weight: 600;
              font-size: 32px;
              line-height: 34px;
            }
          }
          p{
            font-size: 18px;
            line-height: 28px;
            letter-spacing: .2px;
            font-weight: 400;
          }
        }
      }
    }

    &.newtypicals{
      background-color: $gray-8;
      padding: 64px 0;
      .grid{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        gap: 20px;
        
        .typical{
          background-color: $white;
          padding: 20px;
          h4{
            color: $navy;
            margin-bottom: 16px;
            letter-spacing: .2px;
            text-transform: capitalize;
          }
          h5{
            color: $gray-3;
            font-size: 13px;
            line-height: 34px;
            letter-spacing: .4px;
          }
          ul{
            li{
              margin-bottom: 8px;
              svg{
                margin-right: 8px;
                position: relative;
                top: 3px;
              }
            }
          }
        }

        @media(min-width:992px){
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    &.newapplications{
      padding: 64px 0;
      .grid{
        display: grid;
        gap: 0 10px;
        grid-template-columns: 1fr;

        .application{
          img{
            width: 100%;
            margin-bottom: 32px;
          }

          h4{
            color: $brand-gray-2;
            letter-spacing: .2px;
            font-weight: 600;
            font-size: 18px;
          }

          h5{
            color: $brand-gray-3;
            font-size: 13px;
            line-height: 34px;
            letter-spacing: .4px;
          }

          ul{
            li{
              margin-bottom: 16px;
            }
          }
        }

        @media(min-width:768px){
          grid-template-columns: 1fr 1fr;
        }

        @media(min-width:992px){
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 0 20px;
        }
      }
    }

    &.singlecolumn{
      padding: 50px 0;
      h4,p{
        color: $white;
      }
      &.white-bg{
        h4,p{
          color: $navy;
        }
      }
      .row{

        .copy{
          display: flex;
          align-items: center;
        }

        &.imageRight{
          flex-direction: row-reverse;
        }
      }
    }

    &.column{
      h2{
        margin-bottom: 32px;
      }
      .row{
        & > div{
          h2{
            margin-bottom: 20px;
          }
          img{
            margin-bottom: 32px;
          }
          p{
            font-size: 18px;
            line-height: 28px;
            color: $brand-gray-2;
          }
        }

        .right-content{
          @media(min-width:992px){
            padding-left: 16px;
          }
        }

        .left-content{
          @media(min-width:992px){
            padding-right: 16px;
          }
        }
      }
      ul.list-style{
        list-style: initial;
        padding-left: 20px;
        li{
          a{
            text-decoration: underline;
          }
        }
      }
    }
  }
}