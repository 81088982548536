@use "../base/variables";

.additionalModule{
  margin-bottom: 32px;
  &.displayAssets{
    .img{
      width: 100%;
      cursor: pointer;
      height: 120px;
      background-size: contain;
    }
    figcaption{
      color: variables.$acqua;
      text-align: center;
      margin-top: variables.$space-sm;
    }
  }
}
