@use "../base/variables";

.joeyAwardsProgram {
  main{
    .container{
      padding-bottom: 32px !important;
    }
  }

  nav{
    margin-top: 0 !important;
  }

  #about-modules{
    .titleText{
      margin-bottom: 0;
      padding-bottom: 0 !important;
    }
  }
  
  .carousel {
    .flickity-viewport {
      max-height: 325px !important;

      @media(min-width:768px){
        .carousel-cell{
          aspect-ratio: initial !important;
          max-height: 325px !important;
          min-height: 325px !important;
          img{
            max-height: 325px !important;
          }
        }
      }

      @media(max-width: 767px){
        height: 100px !important;
      }
    }
  }

  .carouselGallery{
    @media(max-width: 767px){
      padding-bottom: 32px !important;
    }
  }

  .joey-calculator {
    form {
      margin-bottom: 32px;
    }

    .col-md-2,
    .col-md-1 {
      padding: 0 5px;

      select {
        width: 100%;
        padding: 8px 10px;
      }
    }

    .display-value {
      padding: 8px 10px;
      display: block;
      text-align: center;
    }

    .item-total {
      background-color: #f2f2f2;
    }

    .joey-coins {
      background-color: variables.$gray-6;
    }

    .add-item {
      cursor: pointer;

      span {
        width: 28px;
        display: inline-block;
        border: 1px solid black;
        border-radius: 100%;
        padding: 2px 9px;
        margin-right: 8px;
        font-weight: 700;
      }
    }

    .total-joey-coins {
      float: right;

      .display-value {
        background-color: variables.$gray-6;
        font-size: 24px;
      }
    }

  }
}