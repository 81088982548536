
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

.dealersPortal,
.dealersLogin,
.joeyLogin {
  h2 {
    margin-bottom: variables.$space-md;
  }

  h3 {
    margin-bottom: 13px;
  }

  .banner {
    padding: 64px 0;

    .login {
      background-color: variables.$white;
      padding: 24px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .06);
      h2{
        @include typography.h4;
      }
      form {
        margin: 24px 0;

        label,
        input {
          display: block;
          margin-bottom: 8px;
        }

        input {

          &#loginName,
          &#password,
          &#email {
            border: none;
            border-bottom: 1px solid variables.$gray-6;
          }

          &#access-code,
          &#email-access {
            width: 100%;
          }
        }

        .btn {
          margin-bottom: variables.$space-md;
        }
      }
    }

    span {
      font-size: 16px;
      color: #808285;
      line-height: 28px;
      letter-spacing: .2px;
    }
  }

  .tout-block {
    &>.container {
      padding: 64px 15px;

      .tout-block__block {
        height: 250px;

        p {
          text-align: center;
          position: absolute;
          bottom: 8px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
}

.dealersLogin {}

.dealersPortal {

  // link resources bottom of page
  .links {
    .col-12 {
      @media(min-width:992px) {
        padding: 32px;
      }

      &.assets {
        &>div {
          background-color: variables.$gray-8;
          padding: 40px;

          // @media(max-width:767px){
          //   padding: 20px 40px;
          // }
        }

      }

      h3 {
        margin-bottom: 40px;
      }
    }
  }

  .banner {
    min-height: 300px;
    margin-bottom: variables.$space-lg;
    background-size: cover;
    background-position: center;
  }

  .note {
    margin: 32px 0;

    .container {
      background-color: rgba(244, 255, 57, .1);
      padding: 16px 32px;
    }

    p {
      margin-bottom: 0;

      strong {
        color: variables.$brand-gray-1;
      }
    }

    span {
      background-color: variables.$yellow;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
      display: inline-block;
      padding: 15px;
      width: 100%;

      @media(max-width:992px) {
        margin-bottom: 16px;
      }
    }
  }

  .card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 24px;
    height: 285px;
    color: variables.$white;
    margin-bottom: variables.$space-lg;
    border-radius: 0;
    border-width: 0;

    @media (max-width: 768px) {
      height: auto;
    }

    @media(min-width:992px) and (max-width: 1300px){
      height: 215px;
    }

    h2 {
      text-align: left;
      color: variables.$white !important;
      @include typography.h1;
      @media(min-width:992px) and (max-width:1300px) {
        font-size: 25px;
        line-height: 32px;
      }
    }

    .btn {
      color: variables.$black;
      background-color: variables.$white !important;
      border-color: variables.$brand-gray-1;
      text-transform: uppercase;
      width: fit-content;
      margin-top: auto;
      z-index: 1;
      padding: 14px 32px 14px 32px;
      width: 100%;

      &.with-label {
        padding-right: 16px;

        span {
          display: inline-block;
          margin-left: 20px;
          top: -1px;
        }
      }

      &:hover {
        color: variables.$white !important;
        background-color: variables.$gray-3 !important;
      }

      @media(max-width:1286px) {
        width: auto;
        white-space: initial;
      }

    }
  }

  .tout-blocks {
    margin-bottom: 64px;
  }

  .links {
    ul {
      margin-bottom: variables.$space-xl;
      @include mixins.list-no-style;
      @media (max-width: 768px) {
        margin-bottom: variables.$space-lg;
      }

      li {
        padding: 8px 0 8px 0;
        line-height: 1.3em;
        border-bottom: 1px solid variables.$gray-6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 35px;

        &.archive{
          display: block;
          cursor: pointer;
          border-bottom: 0;
          & > div{
            padding: 8px 0 8px 0;
            line-height: 1.3em;
            border-bottom: 1px solid variables.$gray-6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 35px;

            a{
              width: 100%;
              display: block;
            }

            span{
              display: block;
              padding-right: 17px;
              font-size: 25px;
              cursor: pointer;
            }

            &.active{
              span{
                padding-right: 20px;
              }
            }
          }

          ul{
            max-height: 0;
            overflow: hidden;
            transition: max-height .5s ease-in-out;
            margin-bottom: 0;
            background: variables.$gray-8;
            padding: 2px 0 0px 12px;

            li{
              display: flex;
              align-items: center;
              justify-content: space-between;
              min-height: 35px;
              border-bottom: 0;
            }

            &.active{
              max-height: 1000px;
              margin-bottom: 12px;
            }
          }
        }

        svg {
          margin-right: variables.$space-md;
          vertical-align: middle;
          max-width: 18px;
          color: variables.$gray-6;

          &.tool {
            background-color: rgba(3, 158, 255, .3);
            padding: 2px;
            width: 20px;
            height: 20px;
            border-radius: 2px;
          }
        }

        span {
          color: variables.$gray-6;
          margin-left: 8px;
          transform: translateY(1px);
        }
      }
    }
  }

  span {
    &.new {
      background-color: variables.$blue;
      color: variables.$white !important;
      display: inline-block;
      font-size: 9px;
      font-weight: typography.$weight-regular;
      letter-spacing: .4px;
      padding: 0 8px;
      border-radius: 3px;
      line-height: 17px;
      top: -3px;
      position: relative;
      margin-right: 3px;
    }

    &.updated {
      background: transparent;
      border: 1px solid variables.$blue;
      color: variables.$blue !important;
      font-size: 9px;
      font-weight: typography.$weight-regular;
      letter-spacing: .4px;
      padding: 0 8px;
      display: inline-block;
      border-radius: 3px;
      line-height: 17px;
      top: -3px;
      position: relative;
    }

  }
}

.dealersImages {
  footer {
    margin: 0;
  }
}

.dealers-images {
  .row {
    &>div {
      margin-bottom: variables.$space-lg;
      margin-top: variables.$space-lg;
    }
  }

  a {
    display: flex;
    align-items: center;

    i {
      margin-bottom: variables.$space-md;
      text-align: center;
      color: variables.$gray-4;
      margin-right: variables.$space-md;
    }

    h2 {
      text-align: center;
      font-size: 18px;
      color: variables.$gray-3;
    }

    margin-bottom: variables.$space-lg;

    &:hover {
      color: variables.$acqua;

      h2 {
        color: variables.$acqua;
      }

      i {
        color: variables.$acqua;
      }
    }
  }
}

.dealerTypicals {
  main {
    .container {
      position: relative;
    }

    .toggle {
      margin: variables.$space-lg 0;
      display: flex;

      a {
        display: inline-block;
        padding: variables.$space-md variables.$space-lg;
        margin-right: variables.$space-md;
        border-bottom: 2px solid variables.$gray-6;
        font-size: 18px;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
          border-bottom: 2px solid variables.$acqua;
        }

        &.active {
          border-bottom: 2px solid variables.$acqua;
          font-weight: typography.$weight-bold;
        }

        @media(min-width: 767px) {
          padding: variables.$space-md 64px;

        }
      }
    }

    .typicals {
      position: relative;
      display: none;
      opacity: 0;
      transition: all .5s ease-in-out;
      transition-delay: 1s;

      &>div {
        a {
          display: block;
          margin-bottom: variables.$space-md;

          p {
            font-size: variables.$space-md;
            padding: 0px;
            margin: 0;
          }

          img {
            border: 1px solid variables.$gray-6;
            transition: all .5s ease-in-out;
          }

          &:hover {
            p {
              color: variables.$acqua;
            }

            img {
              border: 1px solid variables.$acqua;
            }
          }
        }
      }

      img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 8px;
      }

      &.fade {
        opacity: 1;
      }

      &.active {
        display: flex;
      }
    }
  }

}

.dealers-gallery {
  a {
    cursor: pointer;

    &#download-all {
      display: block;

      @media(min-width: 767px) {
        text-align: right;
        float: right;

        &+p {
          text-align: right;
        }
      }

      svg {
        margin-right: 8px;
      }

      &:hover {
        color: variables.$acqua;
      }
    }

    &.back-btn {
      display: inline-block;
      color: variables.$gray-2;
    }
  }

  .row {
    flex-wrap: wrap;

    &>div {
      margin-bottom: variables.$space-lg;
      margin-top: variables.$space-lg;
    }

    a {
      height: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;

    }

    p {
      font-family: MONACO, Courier, san-serif;
      font-size: 12px;
      color: variables.$gray-2;
      letter-spacing: 0px;
    }

    img {
      width: 100%;
      margin-bottom: 8px;
    }

    span {
      text-align: center;
    }
  }
}

.dealerApplicationLanding {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #58595B;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #B3AFB3;
  }

  ::-webkit-scrollbar-track {
    background: #DCDCDC;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }

  .products-main {
    @media (max-width: 767px){
      .filter-form{
        display: none;
      }
    }
    .content {
      .finish-card {
        background-color: variables.$white;
        padding: 0;
        opacity: 0;
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width:767px){
          margin-bottom: 0;
        }

        img {
          margin: 0;
          transition: box-shadow .4s ease-in-out !important;
        }

        &:hover {
          // box-shadow: none;
          // transform: scale(1);
          // background-color: transparent;
        }
      }
    }
  }
  
  .intro{
    h2{
      letter-spacing: -0.34px;
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      color: variables.$gray-3;
    }

    p{
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;      
      letter-spacing: 0.2px;
    }
  }

  .products-main {
    .back{
      text-align: right;
      display: flex;
      flex-direction: row-reverse;
      svg{
        // transform: rotate(180deg);
        color: variables.$navy;
        position: relative;
        top: 4px;
        left: -8px;
      }
    }
    @media (max-width: 767px) {
      .filter-form {
        display: none;
      }
    }

    .content {

      a{
        display: block;
      }
      
      img {
        margin: 0;
        transition: box-shadow .4s ease-in-out !important;
        width: 100%;
        @media(max-width:767px){
          height: 200px;
          object-fit: contain;
        }
      }

    }

    .category-nav {
      h3 {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.open {
          i {
            transform: rotate(180deg);
          }

          &+.filter-group {
            height: auto;
            overflow: auto;
          }
        }
      }

      i {
        float: right;
        font-weight: 100;
        color: variables.$gray-5;
      }

      .filter-group {
        height: 0;
        overflow-y: scroll;
        position: relative;

        span{
          color: #495B55;
          word-break: normal;
          line-height: 20px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        // &:after{
        //   content:"";
        //   height: 10px;
        //   width: 100%;
        //   position: absolute;
        //   left: 0;
        //   width: 100%;
        //   background: linear-gradient(0%, #F5F5F5, 100%, #F2F2F2);
        // }
      }

      .clear-filters {
        cursor: pointer;
        float: right;
        display: block;
        margin-bottom: 16px;
      }

      .clear-tag {
        padding: 4px 8px;
        border: 1px solid variables.$gray-5;
        cursor: pointer;
        text-transform: capitalize;
        margin: 4px 0;
        margin-right: 8px;
        display: inline-block;
        max-width: 100%;
        word-break: break-all;

        .clear {
          font-weight: 700;
        }
      }

      @media(max-width:992px){
        display: none;
      }
    }

    .application {
      form {
        margin-bottom: 0;
      }

      p{
        color: variables.$brand-gray-3;
        letter-spacing: 0.2px;
        font-size: 18px;
        line-height: 28px;
        margin-top: 16px;
        font-weight: 400;
      }

      img{
        &[src="/assets/images/no-image.svg"]{
          object-fit: none;
        }
        aspect-ratio: 1 / 1;
      }

      .new{
          background-color: variables.$orange;
          color: variables.$white !important;
          display: inline-block;
          font-size: 11px;
          font-weight: typography.$weight-regular;
          letter-spacing: .4px;
          padding: 0 8px;
          border-radius: 3px;
          line-height: 17px;
          position: absolute;
          bottom: 80px;
          left: 16px;
      }

      cursor: pointer;
    }
  }

  .category-nav {
    h3 {
      margin-top: 64px;
      color: #495B55;
      font-size: 18px;
      font-weight: typography.$weight-bold;
    }

    .select-items {
      top: 40px;
      width: 100%;
      max-height: 500px;
      overflow-y: scroll;

      div[data-type="product-category"] {
        color: variables.$brand-gray-1;
        text-transform: uppercase;
        font-weight: typography.$weight-bold;
        pointer-events: none;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: -8px;
      }

      div.product {
        font-size: 16px;
        color: variables.$gray-2;
      }
    }

    .filter-group {
      max-width: 250px;

      label {
        display: flex;
        align-items: flex-start;
        position: relative;
        // font-weight: $weight-bold;
        letter-spacing: .4px;
        font-size: 16px;
        color: variables.$brand-gray-1;
        z-index: 1;
        cursor: pointer;

        @media(min-width: 1300px){
          align-items: center;
        }

        span {
          word-break: break-all;

          &.clear {
            opacity: 0;
            text-transform: uppercase;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 14px;
            letter-spacing: .35px;
            font-weight: typography.$weight-regular;
            color: variables.$gray-3;
            transition: all .25s ease-in-out;
            z-index: 99;
          }
        }

        p {
          margin: 0;
        }

        input {
          margin-right: 8px;
          margin-top: 0px;
          -webkit-appearance: none;
          background-color: transparent;
          border: 2px solid variables.$gray-5;
          padding: 9px;
          border-radius: 0px;
          display: inline-block;
          position: relative;
          outline: 0;

          &:checked {
            &+span+span.clear {
              opacity: 1;
              cursor: pointer;
            }

            background-color: variables.$acqua;
            border: 2px solid variables.$gray-3;
            color: variables.$acqua;
            box-shadow: inset 0 0 0 2px #DCDCDC;

            &+span {
              font-weight: typography.$weight-bold;
            }
          }

          &:checked:active {
            background-color: variables.$acqua;
          }
        }
      }
    }

  }
}

.dealerApplication {
  .save-to-project-btn{
    display: none;
  }
  .sc-threekit-zoom,
  .sc-threekit-fullscreen{
    display: none;
  }
  .breadcrumbs{
    display: flex;
    span{
      display: inline-block;
      margin-right: 16px;
      font-size: 12px;
      position: relative;
      top: 4px;
      @media(max-width:767px){
        font-size: 12px;
      }
    }
    label{
      font-size: 16px;
      margin-right: 16px;
      &:first-child{
        color: variables.$gray-2;
      }
      &:last-child{
        color: variables.$gray-4;
      }

      @media(max-width:767px){
        font-size: 12px;

        a{
          font-size: 12px;
        }
      }
    }
  }
  .viewer-toggle{
    display:inline-flex;
    align-items: center;

    @media(min-width:992px){
      position: absolute;
      top:  -75px;
      left: 15px;
    }

    span{
      border-radius: 4px;
      padding: 10px 16px;
      color: variables.$gray-1;
      opacity: .5;
      cursor: pointer;
      // &:first-child{
      //   margin-right: 8px;
      // }
      // &:last-child{
      //   margin-left: 8px;
      // }
      &.active{
        opacity: 1;
        background: variables.$gray-7;
      }
    }
  }
  
  .download-pdf{
    height: 53px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .currency-toggle{

    display:flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 3px;
    margin-right: 10px;

    span{
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
      font-weight: 400;
      font-family: 'Lato', Arial, Helvetica, sans-serif;
      color: variables.$gray-2;

      div{
        &::after{
          content:"";
          background-color: variables.$white;
          height: 6px;
          width: 6px;
          border-radius: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
        }
        position: relative;
        height: 14px;
        width: 14px;
        border-radius: 100%;
        background-color: #DBDBDB;
        color: #252525;
        margin-right: 4px;
      }

      &.active{
        div{
          background-color: #044F76;
        }
      }
      &:hover{
        div{
          background-color: #044F76;
        }
      }
    }

    @media(min-width:992px){
      position: absolute;
      top: 0px;
      left: -60px;
    }

    // @media(min-width:1200px){
    //   position: absolute;
    //   top: 0px;
    //   left: -60px;
    // }

  }
  .cet-download{
    // position: relative;
    float: right;
    margin-top: 0;

    @media(min-width:992px){
      position: absolute;
      top:  -65px;
      left: 140px;
      float: initial;
    }

    button{
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      img{
        width: 10px;
        margin-top: -5px;
        margin-left: 8px;
        filter: brightness(100);
      }
    }

    img{
      cursor: pointer;
    }
    span{
      &:first-child{
        margin-bottom: 8px;
        cursor: pointer;
      }
      &:last-child{
        text-align: right;
      }
    }
  }

  .download-options{
    position: relative;
    ul{
      background: transparent;
      list-style: none;
      padding: 0;
      position: absolute;
      bottom: -14px;
      left: 195px;
      margin-left: 10px;
      z-index: 10;
      background-color: variables.$white;
      max-height: 0;
      overflow: hidden;
      border: none;
      &.active{
        max-height: 500px;
        border: 1px solid variables.$navy;
      }
      li{
        padding: 10px 14px;
        height: auto !important;
        letter-spacing: .5px;
        max-width: 171px;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;

        img{
          width: 10px;
          margin-left: 8px;
        }
        
        &:not(:last-child){
          font: 600 13px / 15px "Beausite Classic", Arial, Helvetica, sans-serif;      
          color: #03354C;
          letter-spacing: initial;

          &:hover{
            background: variables.$navy;
            color: variables.$white !important;
            cursor: pointer;
            img{
              filter: brightness(100);
            }
          }
        }
        &:last-child{
          display: block;
          div{
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font: 600 13px / 15px "Beausite Classic", Arial, Helvetica, sans-serif;      
            color: #03354C;
            letter-spacing: initial;
          }

          &:hover{
            background: variables.$navy;
            color: variables.$white !important;
            cursor: pointer;
            div{
              color: variables.$white;
              img{
                filter: brightness(100);
              }
            }
          }
        }
      }
    }
  }

  .open-downloads{
    display: inline-flex;
    align-items: center;
    svg{
      fill: variables.$navy;
      margin-left: 8px;
      width: auto;
      path{
        fill: variables.$navy;
      }
    }
    &:hover{
      svg{
        fill: variables.$white;
        path{
          fill: variables.$white;
        }
      }
    }
    @media(max-width:768px){
      margin-left: auto;
    }
  }

  .cet-popup{
    position: absolute;
    bottom: 160%;
    right: 0;
    left: 10px;
    margin: auto;
    background-color: variables.$white;
    padding: 17px;
    width: 90%;
    box-shadow: 0px 10px 20px rgba(0, 34, 31, 0.25);
    height: fit-content;
    opacity: 0;
    z-index: -1;
    transform: translateY(20px);
    transition-property: transform,opacity;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
    margin: auto;

    h4{
      color: variables.$black;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: .4px;
      font-weight: 600;
    }
    p{
      margin: 32px 0;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: .4px;
      color: variables.$gray-2;
      font-weight: 400;
    }

    img.cet-image{
      width: 100%;
      height: 140px;
      object-fit: contain;
    }

    .close-icon{
      position: absolute;
      top: 16px;
      right:16px;
      cursor: pointer;
    }

    @media(min-width:992px){
      max-width: 400px;
    }

    a,button{
      text-transform: uppercase;
      width: 100% !important;
      display: inline-block;
      margin-right: 0 !important;
      display: inline-block;
      &:last-child{
        float: right;
      }
    }

    &.show{
      opacity: 1;
      transform: translateY(0);
      z-index: 99;
    }
  }
  .two-d-image{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: variables.$white;
    font-size: 38px;
    color: variables.$gray-6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &.cover{
      display: none;
    }
  }
  h1{
    color: variables.$brand-gray-1;
    text-align: left !important;
    @include typography.h1;
  }
  main{
    .application-information{
      &>.row:nth-child(1),
      &>.row:nth-child(2){
        .container{
          .row{
            &:first-child{
              @media(min-width:992px){
                min-height: 800px;
              }
            }
          }
        }
      }
    }
  }

  .col-lg-4{
    max-height: 800px;
    @media(min-width:1200px){
      max-height: 1000px;
      padding: 0;
    }
  }
  .options{
    // margin-bottom: 32px;
    height: auto;
    overflow-y: scroll;
    max-height: 100%;
    opacity: 0;
    @media(min-width:992px){
      padding-right: 10px;
    }
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #A0A0A0;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    .option-accordion{
  
      .option-header{
        cursor: pointer;
        border-bottom: 1px solid variables.$lightgray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        label{
          margin-bottom: 0;
          cursor: pointer;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.2px;
          color: variables.$brand-gray-1;
        }
        svg{
          max-width: 16px;
          color: variables.$brand-gray-1;
          fill: variables.$brand-gray-1;
          path,rect{
            fill: variables.$brand-gray-1;
          }
        }
      }
      ul.option{
        flex-wrap: wrap;
        overflow: hidden;
        // justify-content: space-between;
        padding: 0;
        // height: 0;
        margin-bottom: 0;
        display: flex;

        &[data-option-name="theme"]{
          li{
            width: 33%;
            padding-top: 6px;
          }
        }

        li{
          width: 33%;
          margin-top: 16px;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          border-radius: 7px;
          padding: 16px 8px;
          color: variables.$brand-gray-1;
          border: 1px solid transparent;
          transition: all .3s ease-in-out;
          position: relative;

          span{
            font-size: 13px;
            line-height: 15px;
            height: 30px;
          }

          span.subtitle{
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: variables.$brand-gray-1;
            text-transform: uppercase;
            display: none;
          }
          svg{
            // display: none;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            max-width: 30%;
            background-color: transparent;
            opacity: 0;
          }
          img{
            height: 90px;
            max-width: 70%;
            margin: auto;
            object-fit: contain;
            object-position: center;
            display: block;
            &[src="/assets/images/no-image-icon.svg"]{
              max-width: 40%;
            }
          }
          &.active{
            border: 1px solid #00AEEF;
          }

          &.disabled{
            opacity: .25;
            pointer-events: none;
          }

          &.loading{
            img,span{
              opacity: .3;
            }
            svg{
              opacity: 1;
            }
          }

          @media(min-width:992px){
            width: 50%;
          }
        }

        &.open{
          height: auto;
          display: flex;
          // margin-bottom: 1rem;
        }
      }

      &:last-child{
        ul{
          margin-bottom: 64px;
        }
      }
    }

  }
  .note{
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    justify-content: left;
    float: right;
    height: 100%;
    // @media(min-width:992px){
    //   margin-top: 0;
    // }

    svg{
      width: 38px;
    }

    &.logged-in{
      opacity: 0;
      pointer-events: none;
    }

    p{
      color: #495B55;
      font-weight: 400;
      letter-spacing: .2px;
      font-size: 15px;
      line-height: 18px;
      text-align: left;
      margin: 0;
    }
    
    u{
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .summary-container{
    padding: 8px 0;
    // border-top: 1px solid #BEC8C3;
    &:before{
      content:"";
      border-top: 1px solid #BEC8C3;
      position: absolute;
      top: 0;
      left: 15px;
      width: 99%;
      height: 1px;
    }
    position: sticky;
    bottom: 0;
    // padding-bottom: 32px;
    z-index: 99;
    background-color: variables.$white;

    .col-lg-8{
      display: flex;
      align-items: center;
    }

    @media(max-width:992px){
      padding-bottom: 15px;
      .download-pdf{
        margin-left: auto;
        margin-right: 15px;
        width: 38%;
      }

      & > div{
        &:last-child{
          margin-top: 16px;
        }
      }
    }
  }
  .summary{
    // @media(min-width:1200px){
    //   bottom: 0;
    //   margin-top: 8px;
    // }

    background: variables.$white;
    z-index: 10;
    .row{
      justify-content: left;
      margin: initial;
      @media(min-width:992px) and (max-width: 1300px){
        flex-wrap:  initial;
      }
    }
    .footprint,
    .list-price{
      letter-spacing: 0.4px;
      font-size: 13px;
      text-transform: uppercase;
      height: auto;
      font-weight: 600;

      span{
        padding-left: 18px;
        color: variables.$gray-2;
      }

      span.price{
        font-size: 32px;
        line-height: 24px;
        color: variables.$black;
        font-weight: 400;
        padding: 0;
        sup{
          font-size: 13px;
          margin-right: 10px;
          // top: -10px;
          font-weight: 600;
          letter-spacing: 0.4px;
          color: variables.$brand-gray-2;
        }
      }

      @media(min-width:992px){
        position: relative;
        bottom: -1px;
      }
    }

    a {
      display: block;
      padding: 10px;
      width: 40%;
      display: block;
      font-size: 12px;
      // margin-bottom: 10px;
      margin-top: 6px;
      margin-left: 32px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      border: 0;
      // color: $black;
      margin-left: auto;
      padding: 8px 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0;

      @media(min-width:992px) and (max-width:1300px){
        font-size: 9.5px;
        svg{
          position: relative;
          left: -5px;
        }
      }

      &:hover{
        color: variables.$white;
      }
      & + a{
        float: right;
      }
      svg{
        // margin-right: 16px;
        vertical-align: middle;
        display: inline;
        g,path{
          &:first-child{
            fill: rgb(255, 87, 33);
          }
          &:nth-child(2){
            fill: variables.$white;
          }
        }
      }
      span {
        display: inline;
      }
      input {
        opacity: 0;
        position: absolute;
        font-size: 0.8em;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      &.enabled{
        pointer-events: initial;
      }
      &.disabled {
        opacity: 0.5;
      }

      @media(min-width:992px) and (max-width: 1300px){
        margin-left: 16px;
      }

      @media(min-width:992px){
        width: 60%;
        height: 53px;
      }
      @media(max-width:767px){
        padding: 12px 16px;
      }
    }

    @media(max-width:992px){
      // padding: 0 15px;
      a{
        width: 40%;
        margin-left: auto;
        svg{
          display: none;
        }
        // margin-top: 16px;
      }
      .row{
        justify-content: left;
      }
    }
  }

  #threekitPlayerContainer{
    #player{
      width: 100%;
      height: 50vh;
      // padding-bottom: 32px;
      background-size: cover;
      background-position: center;

      @media(min-width:1200px){
        height: 65vh;
        padding: 0;
      }
    }
  }

  .sc-threekit-zoom {
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 999;
  }

  .sc-threekit-fullscreen {
    position: absolute;
    bottom: 0px;
    right: 10px;
    height: 36px;
  }

  .sc-threekit-fullscreen-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: none !important;
  }

  .steelcase-threekit-fullscreen-icon {
    background-image: url(../../threekit-icons/FullScreen_Icon.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .steelcase-threekit-exit-fullscreen-icon {
    background-image: url(../../threekit-icons/Normal_Screen_Icon.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .steelcase-threekit-help-icon svg {
    background-image: url(../../threekit-icons/Help_Icon.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    width: 20px !important;
    height: 20px !important;
  }

  /*
.steelcase-threekit-ar-icon svg {
    background-image: url(../../threekit-icons/AR_Icon.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    //margin-top: 2px !important;
    margin-bottom: 2px !important;
    height: 30px !important;
    width: 26px !important;
}
*/

  .steelcase-threekit-zoom-in-icon svg {
    background-image: url(../../threekit-icons/Zoom_In_Icon.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .steelcase-threekit-zoom-out-icon svg {
    background-image: url(../../threekit-icons/Zoom_Out_Icon.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .threekitIcon path {
    display: none !important;
  }

  .threekit-help-button {
    position: absolute;
    right: 45px !important;
    bottom: 0px !important;
  }

  .sc-threekitButton:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 25%);
    background-color: rgba(240, 240, 240, 0.75);
  }

  .sc-threekitButton-border {
    border: solid #000000 !important;
    border-width: 1.5px !important;
  }

  .sc-threekitButton {
    visibility: hidden;
    margin-left: 7px;
    margin-bottom: 7px;
    color: #000;
    border-radius: 100px;
    width: 28px;
    height: 28px;
    box-sizing: content-box;
    padding: 4px;
    cursor: pointer;
    transition: all 0.16s ease-in-out;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(251, 251, 251, 0.5);
  }

  .sc-threekitButton-leftPane {
    margin-bottom: 28px !important
  }

  .sc-threekitButton-bottomPane {
    margin-right: 28px !important
  }

  .sc-threekitButton svg {
    width: 20px;
    height: 20px;
  }

  /*
.steelcase-threekit-ar-button {
    height: 50px !important;
    border-radius: 5px !important;
    width: 205px !important
}

.steelcase-threekit-ar-button-text div {
    color: #000000 !important;
    font-family: Arial !important;
    margin-top: 8px !important;
    font-size: .95em !important
}
*/
}