body.basic{
  .banner{
    min-height: 300px;
    background-size: cover;
    margin-bottom: 128px;
    background-position: center;
  }

  main{
    form{
      &:before,
      &:after{
        content: none !important;
        display: none !important;
      }
    }
    p{
      text-align: left;
    }
    .body-content{
      .block{
        &.gridCopy{
          .col-12{
            margin-bottom: 0 !important;
  
          }
          p{
            margin-bottom: 16px !important;
          }
        }
      }
    }

    .dealer-locator{
      padding-top: 0;
      .start-over{
        top: -70px;
      }
      & > p{
        margin-bottom: 16px;
      }
    }

    .hbspt-form{
      p{
        margin-bottom: 16px;
      }
    }
  }
}