
@use "../base/typography";
@use "../base/variables";

body {
  .form-msg {
    &.red {
      color: variables.$error;
    }

    &.green {
      color: variables.$green;
    }
  }

  .fsForm {
    &+p {
      display: none !important;
    }
  }

  .hbspt-form {
    .hs-form-required,span{
      display: inline-block !important;
    }
    label {
      color: variables.$gray-2;
      font: typography.$weight-regular 14px/14px typography.$beausite;
      margin-bottom: 3px;

      &.active {
        color: variables.$blue !important;
      }
    }

    legend {
      color: variables.$gray-2;
      font: typography.$weight-regular 14px/14px typography.$beausite;
      font-style: italic;
    }

    .field {
      margin-bottom: 20px;
      font-family: typography.$beausite;

      &:focus-within {
        label {
          color: variables.$blue;
        }
      }
    }

    textarea {
      width: 100%;
      padding: 16px;
      min-height: 150px;
      border-color: variables.$input-border-color;

      &:focus {
        border: 1px solid variables.$blue !important;
        box-shadow: inset 0 0 4px 0 rgba(0, 174, 239, 75%);
      }
    }

    input {

      &[type="text"],
      &[type="email"],
      &[type="tel"],
      &[type="number"] {
        outline: 0;
        border: 1px solid variables.$input-border-color;
        transition: all .25s ease-in-out;
        width: 100%;
        border-radius: 0;
        padding: 8px 10px;
        min-height: 42px;
        font-size: 16px;
        color: #000;
      }

      &:focus {
        border: 1px solid variables.$blue !important;
        box-shadow: inset 0 0 4px 0 rgba(0, 174, 239, 75%);
      }

      &[type="checkbox"] {
        height: 18px;
        width: 18px;
        top: 0px;
        padding-top: 3px;
        margin: 0;

        &:before {
          position: relative;
          top: -4px;
        }

        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      &[type="radio"] {
        height: 18px;
        width: 18px;
        padding-top: 3px;
        position: relative;
        top: 3px;

        &:before {
          position: relative;
          top: -4px;
          left: 3px;
        }

        &:focus {
          border: none;
          box-shadow: none;
        }
      }

    }

    .hs-button {
      background-color: variables.$form-submit-color !important;
      font: typography.$weight-bold 12px/15px typography.$beausite !important;
      padding: 15px 32px;
      border: none;
      cursor: pointer;
      letter-spacing: .5px;
      color: #fff !important;
      text-transform: uppercase;
      border-radius: 0 !important;
      min-height: 46px;
      transition: all .25s ease-in-out;
      margin-bottom: 8px !important;

      &:hover {
        background-color: variables.$black !important;
      }

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .hs-button {
      text-align: left;
      margin: initial;
      margin-right: auto !important;
      display: block !important;
    }

    select {
      outline: 0;
      border: 1px solid variables.$input-border-color;
      transition: all .25s ease-in-out;
      width: 100%;
      border-radius: 0;
      padding: 8px 10px;
      min-height: 42px;
      font-size: 14px;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1805.18'%3E%3Cpath fill='%23595d64' d='M461.6,643.4L10.9,79.9C-14.9,47.7,8,0,49.3,0h901.5c41.2,0,64.1,47.7,38.4,79.9L538.4,643.4	C518.7,668,481.3,668,461.6,643.4z' transform='rotate(180 500 902.59) translate(0 1143.28)'%3E%3C/path%3E%3Cpath fill='%23595d64' d='M461.6,643.4L10.9,79.9C-14.9,47.7,8,0,49.3,0h901.5c41.2,0,64.1,47.7,38.4,79.9L538.4,643.4	C518.7,668,481.3,668,461.6,643.4z' transform='translate(0 1143.28)'%3E%3C/path%3E%3C/svg%3E");
      background-position: right 7px top 50%;
      background-size: .5em;
      background-repeat: no-repeat;

      @media(max-width:1300px) {
        width: 45%;
      }

      @media(max-width:767px) {
        width: 100%;
      }
    }

    .submitted-message {
      font-size: 28px;
      font-weight: typography.$weight-bold;
    }

    ul {
      padding-left: 20px;

      .hs-error-msg {
        background-color: #ffe8df;
        color: variables.$error
      }
    }


    .invalid.error {
      border-bottom: variables.$error solid 2px !important;
    }
  }

  // background: green;
  [data-testid="form-body"]{
    [class*="InnerFormBody"]{
      padding: 0;
    }
  }

  .fsBody {
    padding: 0;

    form.fsForm {
      padding: 0;
      font-size: 16px;
      span{
        font-size: 16px !important;
      }

      [class*="StyledSection"]{
        margin-bottom: 16px;
      }

      label{
        font-size: 16px;
      }

      .fsLabel{
        margin-bottom: 6px;
      }

      table{
        td{
          font-size: 16px !important;
          span{
            font-size: 16px !important;
          }
        }
      }

      .fsBody .fieldset-content .fsOptionLabel.horizontal {
        font-size: 20px;
        color: #333;
        margin-bottom: 5px;
      }

      .fsOptionLabel{
        font-size: 16px;
        display: flex;
        align-items: center;

        input{
          margin-top: -10px;
        }
      }

      // [fs-field-type="checkbox"]{
      //   display: flex;
      //   flex-direction: column;
      //   label {
      //     font-size: 15px;
      //     line-height: 17px;
      //     margin-bottom: 8px;
      //   }
      // }

      img {
        position: relative;
        top: 13px;
      }

      .fsFieldCell {
        select {
          outline: 0;
          border: 1px solid variables.$input-border-color;
          transition: all .25s ease-in-out;
          width: 100%;
          border-radius: 0;
          padding: 8px 10px;
          min-height: 42px;
          width: 29%;

          // &#field108172175-state{
          //   width: 100%;
          // }
        }

        &.fsSpan25 {
          select {
            width: 100%;
          }
        }
      }

      .freeform-select {
        min-height: 42px;


      }

      .select-items {
        top: 41px;
      }

      h2,
      .fsSectionHeader {
        padding: 0;
      }

      .fsSupporting {}

      label {
        &.fs-label {
          color: variables.$gray-2;
          font-size: 15px;
          margin-bottom: 3px;
        }

        &.active {
          color: variables.$blue !important;
        }
      }

      .fsFieldState {
        display: flex;
        flex-direction: column-reverse;
        width: 40%;
        flex-basis: 42%;
        flex-direction: column;
         select{
          width: 100%;
         }
      }

      .fsFieldZip{
        width: 40%;
      }

      .fsValidationError {
        background: none !important;
        box-shadow: none !important;
        border: 0 !important;
        border-bottom: variables.$error solid 2px !important;

        label {
          color: variables.$error !important;
        }

        label.active {
          color: variables.$error !important;
        }
      }

      .fsError {
        background-color: #ffe8df;
        color: variables.$error;
      }


      input {

        &[type="text"],
        &[type="email"],
        &[type="tel"],
        &[type="number"] {
          outline: 0;
          border: 1px solid variables.$input-border-color;
          transition: all .25s ease-in-out;
          width: 100%;
          border-radius: 0;
          padding: 8px 10px;
          min-height: 42px;
          font-size: 16px;
          color: #000;

          &:focus {
            border: 1px solid variables.$blue !important;
            box-shadow: inset 0 0 4px 0 rgba(0, 174, 239, 75%);
          }
        }

        &[type="checkbox"] {
          height: 18px;
          width: 18px;
          top: -1px;
          padding-top: 3px;
          margin: 0;
          position: relative;

          &:before {
            position: relative;
            top: -4px;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }

        &[type="radio"] {
          height: 18px;
          width: 18px;
          padding-top: 3px;
          position: relative;
          top: 3px;

          &:before {
            position: relative;
            top: -4px;
            left: 3px;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }

      }

      textarea {
        border-color: variables.$input-border-color;

        &:focus {
          border: 1px solid variables.$blue !important;
          box-shadow: inset 0 0 4px 0 rgba(0, 174, 239, 75%);
        }
      }

      .fsSubmitButton,
      .fsFileUploadButton {
        // background-color: $form-submit-color !important;
        padding: 15px 32px;
        border: none;
        cursor: pointer;
        font-size: 12px !important;
        letter-spacing: .5px;
        // color: #fff !important;
        font-weight: typography.$weight-bold;
        text-transform: uppercase;
        border-radius: 0 !important;
        min-height: 46px;
        margin-bottom: 8px !important;

        &:focus {
          border: none !important;
          box-shadow: none;
        }

        transition: all .3s ease-in-out;

        background-color: variables.$navy !important;
        color: variables.$white !important;
        border-color: variables.$navy !important;

        &:hover {
          background-color: variables.$blue !important;
          border-color: variables.$blue !important;
          color: variables.$white !important;
        }
      }

      .fsSubmitButton {
        text-align: left;
        margin: initial;
        margin-right: auto !important;
        display: block !important;

        &:focus {
          border: none !important;
          box-shadow: none;
        }

        transition: all .3s ease-in-out;

        background-color: variables.$navy !important;
        color: variables.$white !important;
        border-color: variables.$navy !important;

        &:hover {
          background-color: variables.$blue !important;
          border-color: variables.$blue !important;
          color: variables.$white !important;
        }
      }
    }
  }

  &.showroom,
  .snippet-form-modal,
  .how-to-buy-modal {
    fieldset {
      max-width: none;

      .input {

        .hs-input,
        select {
          width: 100% !important;
        }

        .hs-dateinput {
          padding-right: 32px;
        }

        ul.inputs-list {
          list-style: none;
          margin: 0;
          padding: 0;

          li {

            input[type=radio],
            input[type=checkbox] {
              // margin-right: 16px;
              width: auto !important;
            }
          }
        }
      }
    }
  }
}

form.fsForm {
  textarea {
    border-color: variables.$input-border-color;

    &:focus {
      border: 1px solid variables.$blue !important;
      box-shadow: inset 0 0 4px 0 rgba(0, 174, 239, 75%);
    }
  }

  &#fsForm4914140 {

    // design request form
    .fsFieldState {
      flex-direction: column;
    }

    #fsCell128877144 {
      margin-left: 0 !important;
    }
  }
}

.form-modal,
.snippet-form-modal,
.how-to-buy-modal,
.cet-form-modal,
.popup-form-modal {
  background-color: rgba(0, 0, 0, .5);
  padding: 120px;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 102%;
  height: 100%;
  z-index: -1;
  overflow-y: scroll;

  &.active {
    opacity: 1;
    z-index: 999;
    transform: scale(1);
  }

  @media(max-height: 850px) {
    padding: 100px 64px;
  }

  @media(max-width: 768px) {
    padding: 0px;
    padding-top: 120px;
    padding-bottom: 60px;
    width: 100vw;
  }

  #close {
    position: absolute;
    top: variables.$space-xl;
    right: variables.$space-xl;
    color: variables.$white;
    transform: scale(2);
    cursor: pointer;
    @media(max-width: 1024px) {
      top: variables.$space-lg;
      right: variables.$space-lg;
    }

    @media(max-width: 768px) {
      top:40px;
      right:40px;
    }
    svg {
      width: 14px;
      height: 14px;
      fill: variables.$white;

      g,
      path {
        fill: variables.$white;
      }
    }
  }

  #content {
    background-color: variables.$white;
    margin: auto;
    padding: 40px 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;

    @media(max-width: 767px) {
      padding: 20px 40px;

    }

    @media(max-height:850px) {
      padding: 20px 40px;

    }
  }
}

.cet-form-modal,
.snippet-form-modal,
.how-to-buy-modal{
  // opacity: 0;
  // transition: all .2s ease-in-out;

  &#find-a-dealer{
    h2{
      color: variables.$navy;
      font-size: 30px;
      font-weight: 700;
    }

    form{
      padding-top: 0 !important;
      &:before,
      &:after{
        content: none !important;
        display: none;
      }
    }
  }

  .dealer-locator{
    transform: translateY(20px);
    opacity: 0;
    transition: all .5s ease-in-out;

    h2{
      color: variables.$navy;
      font-size: 30px;
    }

    form{
      padding-top: 0 !important;
      margin-top: -130px !important;
      &:before,
      &:after{
        content: none !important;
        display: none;
      }
    }

    &.exit{
      transform: translateY(20px) !important;
      opacity: 0 !important;
    }
  }

  &.active{
    // opacity: 1;
    .dealer-locator{
      transform: translateX(0);
      opacity: 1;
    }
  }
}

#hsForm_680f4d4e-57e9-4d43-b4ce-5f61ec475d81 {
  &:before {
    content: "Say Hello to AMQ";
  }

  &:after {
    content: "We're looking forward to connecting";
  }
}

#hsForm_fc37753d-a53d-465a-a1de-d601348c4a1f {
  &:before {
    content: "Quote Request";
  }

  &:after {
    content: "We look forward to getting your project started";
  }
}

// find a dealer
#hsForm_2acfb9ef-0602-4af9-8860-f06709b643af{
  &:before {
    content: "Find a Dealer";
  }
}

#hsForm_8ad8924c-5a67-4e73-aea8-db1312e190e0 {

  // book showroom
  &:before {
    content: "Come Visit" !important;
  }

  &:after {
    content: "Can't wait to show you around" !important;
    left: 0;
  }
}
// sample box
#hsForm_d0a5e446-07de-4480-ab0c-f9fe9d8f674f{
  &:before {
    content: "Request a sample box" !important;
  }

  &:after {
    content: "Start your next project with a sample box containing all of our finishes" !important;
  }
}

// finance guide
#hsForm_39c704ae-b7b7-403e-aa6e-3e7bc47ccf55{
  &:before {
    content: "Let's get you financed!" !important;
  }

  &:after {
    content: "We offer financing through our parent company, Steelcase, and can show you how much lower the cost is on a monthly basis." !important;
  }
}

// lookbook ad page
#hsForm_cefed851-6019-4e5d-8959-985942144118,
#hsForm_33b0a994-ecde-467e-90a3-6ab181c862e8{
  &:before {
    content: "Get The Lookbook" !important;
  }

  // &:after {
  //   content: "Start your next project with a sample box containing all of our finishes" !important;
  // }
}
// how to buy
#hsForm_eb79b9ce-59a5-487c-8cf1-4edc696c0fe3{ 
  &:before {
    content: "Download Furniture Guide" !important;
  }
  &:after{
    content: "We’ve made it easy to get your furniture quickly!" !important;
  }
}

// design request
#hsForm_71c20a86-1f0b-44bf-8ca4-0c2a82d9ace6{ 
  &:before {
    content: "Design Services" !important;
  }
  &:after{
    content: "Use our free design services to bring your vision to life!" !important;
  }
}

// dealer showroom
#hsForm_2acfb9ef-0602-4af9-8860-f06709b643af{ 
  &:before {
    content: "Come visit a showroom location near you!" !important;
  }
  &:after{
    content: "Stop by to test drive the latest office furniture designs.  You'll be able to pick out just the right table, desk or chair for your office.  We can deliver in 12 days if needed.  Just enter your zip code to find the location closest to you." !important;
  }
}

#hsForm_680f4d4e-57e9-4d43-b4ce-5f61ec475d81,
// connect with us form
#hsForm_fc37753d-a53d-465a-a1de-d601348c4a1f,
// sample box form
#hsForm_d0a5e446-07de-4480-ab0c-f9fe9d8f674f,
// look book
#hsForm_cefed851-6019-4e5d-8959-985942144118,
// find a dealer
#hsForm_2acfb9ef-0602-4af9-8860-f06709b643af,
// get the look book
#hsForm_33b0a994-ecde-467e-90a3-6ab181c862e8,
// how to buy
#hsForm_eb79b9ce-59a5-487c-8cf1-4edc696c0fe3,
// design services
#hsForm_71c20a86-1f0b-44bf-8ca4-0c2a82d9ace6,
// finance guide
#hsForm_39c704ae-b7b7-403e-aa6e-3e7bc47ccf55
{

  // furnish // book showroom
  &:before {
    color: variables.$navy;
    position: absolute;
    top: -10px;
    line-height: 36px;
    font: 700 30px/36px "Beausite Classic", Arial, Helvetica, sans-serif;
  }

  &:after {
    color: variables.$navy;
    position: absolute;
    top: 28px;
    line-height: 36px;
    font: 400 18px/24px "Beausite Classic", Arial, Helvetica, sans-serif;
  }

  padding-top: 90px;
  position: relative;
  margin-bottom: 0;

  @media(max-width:413px) {
    padding-top: 120px;

    &:after {
      top: 64px;
    }
  }
  textarea {
    min-height: 48px;
  }

  .hs-departments {
    display: none;
  }

  .hs-button {
    background-color: variables.$navy;
    color: variables.$white;
    border-color: variables.$navy;

    &:hover {
      background-color: variables.$blue !important;
      border-color: variables.$blue !important;
      color: variables.$white !important;
    }
  }

  @media(min-width:992px) {

    // .hs-firstname,
    // .hs-lastname,
    // .hs-email,
    // .hs-phone,
    // .hs_city,
    // .hs_state_temp,
    // .hs_company,
    .form-columns-1 {
      width: 48%;
      display: inline-block;
    }

    .hs_state_temp {
      select {
        width: 100%;
      }
    }

    // .hs-lastname,
    // .hs-phone,
    // .hs_state_temp,
    // .hs_company {
    //   float: right;
    // }
  }
}

// cet form
#hsForm_ac6b8994-dda0-4161-96d0-7429dde22c8f{
  .hs-email,
  .hs-input{
    width: 100%;
    display: inline-block;
  }
}

// dealer form
#hsForm_2acfb9ef-0602-4af9-8860-f06709b643af{
  .hs_contact_with_dealer_in_area,
  .hs_postal_code_success{
    display: none;
  }
  @media(min-width:992px) {

    // .hs-firstname,
    // .hs-lastname,
    // .hs-email,
    // .hs-phone,
    // .hs_city,
    // .hs_state_temp,
    // .hs_zip_code 
    .form-columns-1{
      width: 48%;
      display: inline-block;
    }

    .hs_zip_code{
      width: 50%;
    }

    .hs_us_or_canada{
      width: 50%;
      display: inline-block;
      & > label{
        display: none !important;
      }
      .input{
        ul{
          display: flex;
          li{
            margin-left: 8px;
            input[type="radio"]{
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .hs_state_temp {
      select {
        width: 100%;
      }
    }

    ul.inputs-list{
      padding: 0;
      .hs-form-radio{
        list-style: none;
        span{
          margin-left: 8px;
        }
      }
    }

    // .hs-lastname,
    // .hs-phone,
    // .hs_state_temp {
    //   float: right;
    // }
  }

}

#hsForm_2acfb9ef-0602-4af9-8860-f06709b643af{
  padding-top: 130px !important;
  @media(max-width:767px) {
    padding-top: 300px !important;

    &:after {
      top: 105px;
    }
  }
}

#hsForm_8ad8924c-5a67-4e73-aea8-db1312e190e0 {
  // book showroom
  padding-top: 64px;
  position: relative;
  margin-bottom: 0;

  @media(max-width:413px) {
    padding-top: 120px;

    &:after {
      top: 32px;
    }
  }

  &:before {
    color: variables.$navy;
    position: absolute;
    top: -10px;
    line-height: 36px;
    font: 700 30px/36px "Beausite Classic", Arial, Helvetica, sans-serif;
  }

  &:after {
    color: variables.$navy;
    position: absolute;
    top: 28px;
    line-height: 36px;
    font: 400 18px/24px "Beausite Classic", Arial, Helvetica, sans-serif;
  }

  @media(min-width:992px) {

    &>fieldset:nth-child(1),
    &>fieldset:nth-child(2),
    &>fieldset:nth-child(3),
    &>fieldset:nth-child(4),
    &>fieldset:nth-child(5),
    &>fieldset:nth-child(6) {
      width: 48%;
      display: inline-block;
    }

    &>fieldset:nth-child(2),
    &>fieldset:nth-child(4),
    &>fieldset:nth-child(6) {
      float: right;
    }
  }
}

// sample box
#hsForm_d0a5e446-07de-4480-ab0c-f9fe9d8f674f{
  .hs-state{
    width: 48%;
    float: right;
  }
}

#hsForm_5a0411da-6a47-48c9-91a3-a909365c4398{ // ad landing
  @media(min-width:400px) {

    .form-columns-2,
    .form-columns-1 {
      max-width: 95% !important;
    }
  
    .hs-form-field {
      width: 48% !important;
  
      &.hs_email {
        width: 100% !important;
  
        .input {
          input {
            width: 100% !important;
          }
        }
      }
    }
  
    .hs_lastname {
      float: right !important;
    }
  }
}
