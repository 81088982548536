@keyframes slide {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.placeholder {
  position: absolute;
  margin: auto;
  // padding: 10px 10px 60px;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background:
      linear-gradient(to right, rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 54%) 50% 50%;
    animation: slide 1s linear infinite;
  }
}

.line-container {
  display: block;
  line-height: 0;
}

.placeholder__head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
  .line-container {
    flex: 1;
  }
}

.avatar {
  display: inline-block;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background-color: #e9e9e9;
}

.line {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}