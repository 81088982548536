
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

.product-nav {
  display: flex;
  @include mixins.list-no-style;
  padding: variables.$space-lg 0;
  margin: 0;

  .product-nav__item {
    a {
      display: block;
      background-color: variables.$white;
      border: 1px solid variables.$gray-7;
      color: variables.$gray-2;
      margin-right: 4px;
      padding: 8px 12px;
      transition: all .5s ease-in-out;

      &:hover {
        background-color: variables.$body-background-alt;
      }

      &.active {
        background-color: variables.$navy;
        border-color: variables.$navy;
        color: variables.$white;
      }
    }
  }

  @media(max-width: 992px) {
    flex-wrap: wrap;

    .product-nav__item {
      margin-bottom: 16px;
    }
  }
}

.products {
  .title {
    h1 {
      padding-left: 7px;
      font-size: 38px !important;
    }
    .col-12{
      &:last-child{
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  ul.sort{
    display: block;
    text-align: right;
    @media(max-width:768px){
      text-align: left;
      width: 100%;
      padding: 0;
    }
    li{
      list-style: none;
      display: inline-block;
      padding: 4px 20px;
      text-align: center;
      color: variables.$gray-3;
      border-radius: 4px;
      cursor: pointer;
      svg{
        padding-right: 6px;
        // fill: $navy;
        transform: scale(1.5);
        position: relative;
        top: 2px;
        // fill: $gray-3;
  
      }
      &:first-child,
      &:nth-child(2){
        margin-right: 4px;
        svg{
          path,rect{
            stroke: variables.$gray-3;
            fill: variables.$gray-3;
          }
        }

        &:hover{
          svg{
            path, rect{
              fill: variables.$navy;
            }
          }
        }
      }
      &:last-child{
        svg{
          transform: scale(1.3);
          path{
            stroke: variables.$gray-3;
          }
        }
      }
      &:hover{
        color: variables.$navy;
        svg{
          path,rect{
            stroke: variables.$navy;
            fill: variables.$navy;
          }
        }
      }
      &.active{
        background:variables.$white;
        color: variables.$navy;
        svg{
          path,rect{
            stroke: variables.$navy;
            fill: variables.$navy;
          }
        }

        &:first-child{
          svg{
            path,rect{
              fill: variables.$navy;
            }
          }
        }
      }
    }
  }
}

.products,
.productsCategory {
  footer {
    margin-top: 0;
  }
  .products-main {
    background-color: variables.$body-background-alt;
    padding: 50px 0px 200px 0px;

    .title {

      span {
        display: inline-block;
        vertical-align: bottom;

        a {
          color: variables.$acqua;

          svg {
            fill: variables.$acqua;

            g {
              fill: variables.$acqua;
            }
          }
        }

        @media(min-width: 992px) {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }
      }
    }

    img {
      width: 100%;
    }

    .grid {
      margin-top: 40px;

      .grid-sizer {
        width: 25%;

        @media(max-width:992px) {
          width: 100%;
        }
      }

      .grid-item {
        margin-bottom: 16px;

        a {
          padding: 7px;
          display: block;
          width: 100%;
          height: 100%;
        }

        &.large {
          width: 50%;
          height: 600px;

          &.before {
            &+.grid-item {
              margin-bottom: 0;

              &+.grid-item {
                margin-bottom: 0;
              }
            }
          }

          &.after {
            &+.grid-item {
              margin-bottom: 0;
            }
          }
        }

        &.half {
          width: 50%;
          height: 300px;
        }

        &.three-quarters {
          width: 75%;
          height: 300px;
        }

        &.quarter {
          width: 25%;
          height: 300px;
        }

        &.third {
          width: 33%;
          height: 300px;
        }

        .img {
          padding: 8px;
        }

        .product-card {
          background-color: variables.$white;
          margin: 8px;
          overflow: hidden;
          position: relative;
          @include mixins.hover-scale(1.03);
          @include mixins.hover-shadow;

          .product-card__img {
            height: 200px;
            width: 100%;
            background-position: center;
            background-size: cover;
            position: relative;
          }
          .product-footer {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: flex-end;
            .product-footer-content {
              display: flex;
              flex-direction: row;
              padding: 9px 11px 9px 11px;
            }
            span {
              font: typography.$weight-regular 11px/13.2px typography.$beausite;
              color: variables.$gray-1;
              background: rgba(255,255,255,0.7);
              backdrop-filter: blur(3px);
              border-radius: 2px;
              margin-left: 2px;
              padding: 2px 10px;
            }

            .new-label {
              background-color: variables.$orange;
              padding: 4px 7px 4px;
              font: typography.$weight-bold 10px/10px typography.$beausite;
              color: variables.$white;
              border-radius: 2px;
              flex: 0;
            }
          }
          .product-card__name {
            padding: 16px 8px;
            padding-bottom: 0px;

            h4 {
              font-size: 16px;
              letter-spacing: .4px;
              line-height: 19px;
              font-weight: typography.$weight-regular;
              margin-bottom: 0;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            span {
              color: variables.$gray-3;
              font-size: 14px;
              letter-spacing: .2px;
              line-height: 17px;
              min-height: 47px;
              display: block;
            }
          }

          &.tout {
            background-color: variables.$gray-2;
            padding: variables.$space-lg;
            height: 100%;

            h4 {
              font-size: 32px;
              font-weight: typography.$weight-regular;
              line-height: 38px;
              color: variables.$white;
              margin-bottom: variables.$space-md;
            }

            span {
              display: block;
              margin-bottom: 32px;
              font-size: 20px;
              color: variables.$gray-6;
              line-height: 24px;
              font-weight: typography.$weight-regular;
            }

            p {
              color: variables.$white;
              font-size: 18px;
              line-height: 30px;

              svg {
                fill: variables.$acqua;

                g {
                  fill: variables.$acqua;
                }
              }
            }
          }

          // transition: all .5s ease-in-out;
          // &:hover{
          //   box-shadow: 1px 2px 15px 0px rgba(0,0,0,.3);
          // }

          .collection-toggle{
            color: #00AEEF;
            position: absolute;
            bottom: 4px;
            font-size: 14px;
            left: 8px;
            z-index: 99;
            height: auto;
            cursor: pointer;
            svg{
              margin-right: 4px;
              position: relative;
              top: 2px;
            }
          }

          &.category-description{
            h6{
              text-transform: uppercase;
              color: variables.$gray-2;
            }
            background: transparent;
            p{
              color: variables.$gray-3;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: .4px;
            }
            &:hover{
              transform: none;
              box-shadow: none;
            }
          }
        }
        @media(min-width: 992px) {
          margin-bottom: 55px;
        }

        @media(max-width: 992px) {
          width: 100% !important;
        }
      }
    }
  }
}

.products-gallery {
  // margin-top: $space-md;

  .resource-category__product {
    margin-bottom: 2*variables.$space-xl;

    @media(max-width:992px) {
      margin-bottom: variables.$space-xl;
    }

    .img {
      min-height: 250px;
      position: relative;
      @include mixins.hover-shadow;
      &.cover{
        background-size: cover;
      }
    }
  }
}

ul.featured-resources {
  line-height: 14px;
}

.stories {

  .full-width{
    max-width: 1250px !important;
  }

  .page-header {
    margin-bottom: 64px;

    & > div{
      margin-bottom: 16px;
    }

    @media(min-width:992px){
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      margin-bottom: 0;
    }

    span {
      color: #808285;
      font-size: 28px;
    }
  }
  
  .design-btn {
    text-align: right;
    display: block;
    font-weight: typography.$weight-bold;
    padding: 8px 16px;

    svg {
      width: 18px;
      vertical-align: middle;
      margin-top: -5px;
      margin-right: 16px;
      transform: scale(1.5);
    }

    @media(max-width:767px) {
      text-align: left;
      margin: 12px 0;
    }
  }

  .module {
    margin: 36px 0;

    &:not(:nth-child(2)) {
      margin-left: auto;
      margin-right: auto;

      @media(min-width:992px) {
        max-width: 65%;
      }
    }
  }

  .media {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
    margin: auto;

    &.behind-animation{
      margin-bottom: 0 !important;
    }

    & + .tout-container{
      margin-top: 0;
      .module{
        margin-top: 0;
      }
    }

    &:nth-child(2) {
      margin-top: 0 !important
    }

    .img,
    video {
      width: 100%;
    }

    .img {
      min-height: 600px;
      background-size: cover;
      background-position: center;

      &.h-500 {
        min-height: 500px;
      }

      &.h-300 {
        min-height: 300px;
      }

      &.h-200 {
        min-height: 200px;
      }
    }

    video::cue {
      background: transparent;
      font-weight: typography.$weight-bold;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: -0.16px;
      text-align: center;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.50);
      max-width: 50% !important;
      width: 50% !important;
      margin: auto !important;
      display: inline-block !important;

      @media(min-width: 767px) {
        font-size: 28px;
        letter-spacing: -0.28px;
      }
    }

    // video::-webkit-media-text-track-display-backdrop {
    //   background: linear-gradient(180deg, rgba(0,0,0,0.53) 0%, rgba(0,0,0,0.00) 18%);
    // }

    // video::-webkit-media-text-track-display {
    //   background: linear-gradient(180deg, rgba(0,0,0,0.53) 0%, rgba(0,0,0,0.00) 18%);
    // }

    video::-webkit-media-text-track-container {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.00) 18%);
    }

    video {
      &.no-btn {
        &+.muted {
          display: none;
        }
      }
    }

    figcaption {
      color: variables.$gray-3;
      font-size: 14px;
      margin-top: 4px;
    }

    .muted {
      position: absolute;
      top: 16px;
      left: 32px;
      min-width: 141px;
      border: 1px solid #00AEEF;

      @media(max-width:992px) {
        min-width: initial;
        padding: 8px;
        top: 8px;
        left: 8px;

        img {
          width: 15px;
        }
      }
    }

    @media(max-width: 1300px) {
      .col-12 {
        padding: 0 !important;
        margin-bottom: 16px;
      }
    }

    @media(min-width:992px) {
      padding: 0;
    }
  }

  .editorial {
    span {
      display: block;
      margin-bottom: 16px;
      color: variables.$gray-4;
      font-size: 28px;
    }

    &.centerAlign {
      text-align: center;
    }

    &.leftAlign {}

    &.rightAlign {
      text-align: right;
    }
  }

  .tout-container {
    @media(min-width: 767px) {
      display: flex;
      justify-content: space-evenly;
    }

    .productTout {
      @media(min-width:767px) {
        display: inline-block;
        width: 45%;
      }

      .btn {
        // color: $gray-4;
        margin-top: 16px;
      }

      h2 {
        margin-bottom: 16px;
      }

      img {
        width: 100%;
        margin-bottom: 32px;
      }

      @media(max-width:1300px) {
        padding: 0 15px;
      }
    }
  }


  .testimonial {
    background: #F2F2F2;
    width: 100%;
    padding: 72px 0;

    .row {
      max-width: 75%;
      margin: auto;
    }

    .copy {
      position: relative;

      i {
        position: absolute;
        top: 4px;
        left: -16px;
        font-size: 18px;
        color: variables.$gray-8;
      }

      p {
        &.testimonial-author {
          font-weight: typography.$weight-bold;
          margin-bottom: 0;
          color: #000;
        }
      }
    }

    .image {
      img {
        width: 100%;
        border-radius: 100%;
        margin: auto;
      }

      @media(max-width:767px) {
        margin-top: 1em;
      }
    }
  }

  .banner {

    .flickity-page-dots {
      bottom: 30px;
    }

    &.module {
      @media(min-width:1300px) {
        max-width: 75%;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 32px;
        margin: auto;
      }
    }

    @media(min-width:767px) {
      padding: variables.$space-xl 0;
    }

    .carousel {
      .flickity-viewport {
        height: 30vh !important;
        min-height: 400px;

        .carousel-block {
          width: 100%;
          height: 100%;
          position: relative;

          .tout {
            position: absolute;
            width: 100%;
            color: variables.$white;
            bottom: 0;
            padding: 16px;

            h2 {
              // @include tout-title;
              color: variables.$white;
              margin-bottom: 14px;
            }

            p,
            a {
              color: variables.$white;
            }

            p {
              font-size: 22px;
              max-width: 450px;
              opacity: .7;
            }

            a {
              display: flex;
              align-items: center;

              svg {
                margin-left: 8px;
                padding-top: 1px;
                transition: all .5s ease-in-out;
              }

              &:hover {
                svg {
                  transform: translateX(3px);
                }
              }
            }

            @media(max-width: 768px) and (min-width: 300px) {
              bottom: 64px;
              width: 90%;
              padding: 18px;
              right: 0;
            }

            @media(max-width: 1023px) and (min-width: 769px) {
              bottom: 64px;
              width: 80%;
              padding: 24px;
              right: 0;
            }

            @media(min-width: 1024px) {
              right: 0;
              bottom: 64px;
              padding: 32px;
              height: 250px;
              width: 713px;
            }
          }
        }

        @media(min-width: 1024px) {
          height: 30vw !important;
        }
      }
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker {
    width: 100%;
    margin: variables.$space-xl 0; 
    & > p{
      font-size: 20px;
      font-weight: typography.$weight-bold;
      color: variables.$gray-2;
      margin-bottom: variables.$space-lg;
    }
    @mixin white-gradient {
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
    }

    // Styling
    .slider {
      background: white;
      // box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
      height: auto;
      margin: auto;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin: 32px 0;
      display: flex;

      // &::before,
      // &::after {
      //   @include white-gradient;
      //   content: "";
      //   height: 100px;
      //   position: absolute;
      //   width: 200px;
      //   z-index: 2;
      // }

      // &::after {
      //   right: 0;
      //   top: 0;
      //   transform: rotateZ(180deg);
      // }

      // &::before {
      //   left: 0;
      //   top: 0;
      // }

      .slide {
        height: auto;
        width: 457px;
        padding: 8px 16px;
        position: relative;
        font-size: 15px;
        line-height: 17px;
        margin-right: 64px;
        &.clone{
          animation-delay: 2.5s;
        }
        .content{
          width: 313px;
          font-size: 32px;
          line-height: 40px;
        }
        p{
          font-weight: typography.$weight-bold;
          font-size: 32px;
          color: variables.$gray-4;
          margin-top: 16px;
        }
        i {
          position: absolute;
          top: 0px;
          left: -30px;
          font-size: 32px;
          color: variables.$black;
        }
      }
    }
  }

  .resources{
    h2{
      margin-bottom: 31px;
    }
    hr{
      border-top: 1px solid variables.$gray-6;
    }
    ul{
      @include mixins.list-no-style;
      li{
        margin-bottom: variables.$space-md;
        a{
          font-weight: typography.$weight-bold;
          letter-spacing: .4px;
          font-size: 16px;
          display:flex;
          align-items: center;
          position: relative;
          padding-left: 32px;
          svg{
            &:first-child{
              position: absolute;
              top: 0;
              left: 0;
              width: 24px;
            }
          }
        }
        svg{
          margin-right: 8px;
          vertical-align: middle;
          &:not(:first-child){
            margin-left: 8px;

          }
        }
      }
    }
  }

  .btn[href="/personality-plus"],
  .btn[href="/cluvo"] {
    display: block;
    margin: auto;
  }

  &.seating-evolution{
    .tout-container{
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;

      @media(min-width:768px){
        grid-template-columns: 1fr 1fr;
      }
      
      .productTout{
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 100%;

        @media(max-width:767px){
          margin-bottom: 16px;
        }

        .btn{
          margin-left: 0;
        }
      }
    }
  }

}