body.finishes,
body.productsGallery,
body.typicals,
body.documents{
  footer {
    margin-top: 0;
  }
  main {
    background-color: $body-background-alt;
    padding-top: 32px;

    h1 {
      font-size: 40px;
      color: $navy;
      font-weight: $weight-regular;
    }
  }

  .content {
    padding-bottom: 2*$space-xl;

    @media(max-width:992px){
      display: flex;
      flex-direction: column-reverse;
    }

    &.list{
      .row{
        .col-12{
          flex: 0 0 100%;
          max-width: 100%;
          .finish-card{
            min-height: 24px;
            padding: 8px 16px;
            padding-right: 50px;
            padding-left: 8px;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            &:hover{
              background-color: rgba(255, 255, 255, 0.65);
              transform: none;
              .download-icon{
                display: flex;
              }
            }
            .download-icon{
              position: absolute;
              right: 16px;
              top: 0;
              bottom: 0;
              margin: auto;
              align-items: center;
            }
            .updated-date{
              display: block;
              margin: auto;
              margin-right: 75px;
              position: relative;
              height: 100%;
              top: 0px;
            }
            img{
              // aspect-ratio: 1 / 1;
              max-width: 34px;
              margin: 0;
              margin-right: 32px;
            }
            h4{
              margin-bottom: 0;
              display: flex;
              align-items: center;
            }
            .finish-type{
              position: absolute;
              right: 60px;
              top: 0;
              bottom: 0;
              margin: auto;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .category-nav {
    h3 {
      color: $gray-2;
      font-size: 18px;
      font-weight: $weight-bold;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      @media(min-width:992px){
        margin-top: 64px;
      }
      
      &.showroom{
        margin-top: 0;
      }
    }

    form {
      &.filter-form-product{
        margin-bottom: 32px;
      }
      .select-items {
        top: 40px;
        width: 100%;
        max-height: 500px;
        overflow-y: scroll;

        div[data-type="product-category"] {
          color: $gray-1;
          text-transform: uppercase;
          font-weight: $weight-bold;
          pointer-events: none;
          font-size: 14px;
          margin-top: 16px;
          margin-bottom: -8px;
        }

        div.product {
          font-size: 16px;
          color: $gray-2;
        }
      }

      @media(max-width:992px){
        margin-bottom: 32px;
      }

      .filter-group {
        max-height: 0;
        overflow: hidden;
        transition: all .5s ease-in-out;
        &.is-open{
          max-height: 1000px;
        }

        label {
          display: flex;
          // align-items: center;
          align-items: flex-start;
          position: relative;
          font-weight: $weight-regular;
          letter-spacing: .4px;
          font-size: 16px;
          color: $gray-2;
          z-index: 1;
          cursor: pointer;

          span {
            &.clear {
              opacity: 0;
              text-transform: uppercase;
              position: absolute;
              right: 0;
              top: 0;
              font-size: 14px;
              letter-spacing: .35px;
              font-weight: $weight-regular;
              color: $gray-3;
              transition: all .25s ease-in-out;
              z-index: 99;
            }
          }

          p {
            margin: 0;
          }

          input {
            margin-right: 8px;
            margin-top: 0px;
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid $gray-4;
            padding: 9px;
            border-radius: 0px;
            display: inline-block;
            position: relative;
            outline: 0;

            &:checked {
              &+span+span.clear {
                opacity: 1;
                cursor: pointer;
              }

              background-color: $acqua;
              border: 2px solid $gray-3;
              color: $acqua;
              box-shadow: inset 0 0 0 2px #DCDCDC;

              &+span {
                font-weight: $weight-bold;
              }
            }

            &:checked:active {
              background-color: $acqua;
            }
          }
        }
      }
    }
  }

  .content {
    .col-12 {
      padding: 0 7px;
    }

    .finish-card {
      padding: 8px;
      background-color: white;
      margin-bottom: 32px;
      cursor: pointer;
      @include hover-scale(1.03);
      @include hover-shadow;
      text-align: center;
      .img-container {
        display: block;
        text-align: center;
        width: 100%;
        // height: 177px;
        margin-bottom: 8px;
        aspect-ratio: 4 / 3;
      }

      .updated-date{
        display: none;
      }

      @media(max-width: 767px){
        margin-bottom: 8px;
      }

      .img,
      img{
        aspect-ratio: 4 / 3;
        max-width: 100%;
        margin-bottom: 8px;
      }

      h4 {
        font-size: 14px;
        letter-spacing: .4px;
        line-height: 16px;
        // padding: 0 8px;
        min-height: 32px !important;
        text-align: left;
        div{
          display: block;
          color: $error;
          font-size: 12px;
          margin-top: 2px;
        }
      }

      span {
        font-size: 14px;
        letter-spacing: .2px;
        // padding: 0 8px;
        color: $gray-4;
        line-height: 16px !important;
        text-align: left;
        display: block;
        & + span{
          float: right;
          position: relative;
          top: -16px;
        }
      }
    }
  }
}

body.productsGallery{

  .filter-form{
    margin-bottom: 32px;
  }
  .finish-card{
    h4{
      word-break: break-all;
      min-height: initial !important;
      padding: 0 !important;
    }
    .img{
      background-size: cover;
      margin-bottom: 16px;
    }
  }

  .bg {
    background-size: cover !important;
  }

  .finish-modal{
    #content{
      position: relative;
    }
    a{
      position: absolute;
      right: 40px;
      bottom: 64px;
    }
  }
}

.finish-modal, .asset-modal {
  background-color: rgba(0, 0, 0, .5);
  padding: 70px;
  @media(max-width: 768px){
    padding: 0px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  opacity: 0;
  z-index: -1;
  transform: scale(0);
  transition: all .5s ease-in-out;

  #close {
    position: absolute;
    top: $space-xl;
    right: $space-xl;
    @media(max-width: 1024px){
      top: $space-lg;
      right: $space-lg;
    }
    @media(max-width: 768px){
      top: $space-md;
      right: $space-md;
    }
    color: $white;
    transform: scale(2);
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
      fill: $white;

      g,path {
        fill: $white;
      }
    }
  }

  &>div {
    width: 900px;
    height: 900px;
    @media(max-width: 768px){
      width: 100%;
    }
    @media(max-height:900px){
      width: 700px;
      height: 700px;
    }
    @media(max-height:750px){
      width: 550px;
      height: 550px;
    }
    background-color: $white;
    margin: auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h4 {
      font-size: 32px;
      letter-spacing: .4px;
      line-height: 40px;
      min-height: 80px;
      padding: 0 16px;
      margin: 0;
      @media(max-width: 768px){
        font-size: 18px;
        line-height: 19px;
      }
    }

    span {
      font-size: 20px;
      letter-spacing: .2px;
      color: $gray-5;
      padding: 0 16px;
      display: block;
      margin-bottom: 32px;
      font-weight: 400;
      @media(max-width: 768px){
        font-size: 14px;
        line-height: 16px;
      }
    }

    .bg {
      height: 100%;
      width: 100%;
      margin: auto;
      margin-bottom: 32px;
      background-size: 200% 200%;
      background-repeat: no-repeat;
      position: relative;
      img{
        opacity: 0;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
      }
    }

  }

  &.active {
    opacity: 1;
    z-index: 999;
    transform: scale(1);
  }
}

body.documents{
  .content{
    ul.layout-toggle{
      display: block;
      text-align: right;
      li{
        list-style: none;
        display: inline-block;
        padding: 4px 20px;
        text-align: center;
        color: $gray-3;
        border-radius: 4px;
        cursor: pointer;
        svg{
          padding-right: 6px;
          fill: $gray-3;
          transform: scale(1.5);
          position: relative;
          top: 2px;
        }
        &:first-child{
          margin-right: 4px;
        }
        &:last-child{
          svg{
            transform: scale(1.3);
          }
        }
        &:hover{
          color: $navy;
          svg{
            fill: $navy;
            rect{
              fill: $navy;
            }
            path{
              stroke: $navy;
            }
          }
        }
        &.active{
          background:$white;
          color: $navy;
          svg{
            fill: $navy;
          }
        }
      }
      @media(max-width:992px){
        display: none;
      }
    }
    .finish-card{
      img{
        aspect-ratio: 3 / 4;
      }
      span{
        text-align: right;
      }
      .download-icon{
        display: none;
      }
    }
  }

}