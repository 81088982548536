.snippet{
  padding: 5rem 0;
  & + .snippet:not(.information){
    margin-bottom: 6.4rem;
  }
  &.linkBoxes{
    background-color: $gray-7;
    .intro-copy{
      margin-bottom: 16px;
    }
    .boxes{

      .box{
        position: relative;
        padding: 0 8px;

        .img{
          height: 220px;
          width: 100%;
          margin-bottom: 8px;
        }
        label{
          font-weight: $weight-bold;
        }
        .text{
          padding: 1.6rem;

          height: 220px;
          a{
            color: $white;
          }
          h2{
            font-size: 3.2rem;
            line-height: 2.5rem;
            font-weight: $weight-bold;
            color: $white;
          }
        }
      }
    }
  }

  &.information{
    background-color: $sand;
    h2{
      font-size: 4rem;
      line-height: 3.5rem;
      font-weight: $weight-bold;
    }

    h4{
      font-weight: $weight-bold;
    }
    p{
      color: $black;
    }
    img{
      border-radius: 100%;
      width: 100px;
      height: 100px;
    }
  }

  &.imageWithForm{
    img{
      width: 100%;
    }

    @media(max-width:767px){
      padding-top: 32px;
      .btn{
        margin-bottom: 16px;
      }
    }
  }

  &.inspirationTouts{
    padding: 16px 0;
    border-bottom: 2px solid $gray-6;
    margin-bottom: 32px;
    .copy{
      padding: 0 !important;
      h2{
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 20px;
      }
      p{
        margin-bottom: 32px;
        padding-right: 16px;
      }
      a{
        color: $navy;
        text-decoration: none;
      }
    }
    .image{
      padding: 0 !important;
      img{
        background-color: $white;
        padding: 5px;
        aspect-ratio: 16 / 9;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
        margin-top: 32px;

        @media(min-width:992px){
          margin-top: 0;
          margin-left: auto;
        }
      }
    }
  }
}

.snippetTout{
  padding-top: 64px;
  &>.row {
    margin: 0 -32px 0 -32px;
  }
  .tout{
    margin-bottom: 32px;
    position: relative;
    padding: 0 32px;
    .row>div {
      margin-bottom: 0 !important;
    }
    img{
      max-width: 85%;
      @media (max-width: 1023px) {
        max-width: 20%;
        margin-bottom: 16px;
      }
    }
    a{
      @include h2;
      color: $navy;
      margin-bottom: .8rem;
      display: block;
    }

    &:after{
      content:"";
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: $gray-5;
      right: 0;
      top: 0;
    }
    &:last-child:after {
      display: none;
      opacity: 0;
    }
  }
  &.vertical{
    &>.row {
      margin: 0 -15px 0 -15px;
    }
    .tout{
      margin-bottom: 0;
      padding: 32px 15px;
      &:after {
        width: calc(100% - 30px);
        height: 1px;
        bottom: 0;
        top: auto;
        right: 15px;
      }
    }
  }
}
@media (max-width: 991px) {
  .snippetTout{
    &>.row {
      margin: 0 -15px 0 -15px;
    }
    .tout{
      margin-bottom: 0;
      padding: 32px 15px;
      &:after {
        width: calc(100% - 30px);
        height: 1px;
        bottom: 0;
        top: auto;
        right: 15px;
      }
    }

  }
}

main#content {
  .snippet.information:last-child,
  .snippet.linkBoxes:last-child {
    margin-bottom: -128px;
  }
}