
@use "../base/typography";
@use "../base/variables";

body.careers{
  .row{
    margin-bottom: 40px;
    .job-position{
      div{
        padding: 30px;
        background-color: variables.$green;
        color: variables.$white;
        height: 100%;
        h3{
          @include typography.h2;
          margin-bottom: 24px;
          color: variables.$white;
        }
        p{
          color: variables.$white;
          margin: 0;
        }
      }
      
    }
    .job-description{
      padding-left: 0;
      div{
        min-height: 232px;
        padding: 30px;
        a{
          display: block;
          margin-top: 16px;
          font-size: 16px;
          color: #58595B;
          svg{
            margin-left: 8px;
          }
        }
      }
    }
  }
}