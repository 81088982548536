.jqvmap-label,
.jqvmap-pin {
  pointer-events: none
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: #fff;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000;
  padding: 3px;
  color: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center
}

.jqvmap-zoomin {
  top: 10px
}

.jqvmap-zoomout {
  top: 30px
}

.jqvmap-region {
  cursor: pointer
}

.jqvmap-ajax_response {
  width: 100%;
  height: 500px
}