
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

body.gallery,
body.videoLanding{
  footer {
    margin-top: 0;
  }
  main{
    position: relative;
    section{
      background-color: variables.$body-background-alt;
      padding-top: variables.$space-lg;
    }
    .container{
      .category-nav{
        padding-top: variables.$space-md;
        ul{
          @include mixins.list-no-style;
        }
        li{
          display: block;
          font-size: 18px;
          font-weight: typography.$weight-bold;
          margin-bottom: variables.$space-md;
          cursor: pointer;
          transition: all .5s ease-in-out;
          &:hover{
            color: variables.$acqua;
          }
        }
      }

      .video{

      }

      .grid{
        display: grid;
      }

      .resource-category{
        padding-top: variables.$space-md;
        width: 100%;
        h2{
          margin-bottom: variables.$space-lg;
          border-bottom: 1px solid variables.$gray-6;
          padding-bottom: variables.$space-lg;
        }

        .resource-category__product{
          .img{
            height: 200px;
            margin-bottom: variables.$space-lg;
            position: relative;
            background-color: variables.$white;
            background-size: cover;
            @include mixins.hover-shadow;

            // a{
            //   @include link-overlay;
            // }

            &.cover{
              background-size: cover;
            }
            &.tall{
              height:320px;
              background-size: cover;
            }
          }
          .documents{
            ul{
              @include mixins.list-no-style;
              li{
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                a{
                  cursor: pointer;
                  line-height: 18px;
                  color: variables.$gray-2;
                  &:hover {
                    color: variables.$acqua;}
                }
                svg{
                  margin-right: 16px;
                  vertical-align: middle;
                  flex: 0 0 auto;
                }
              }
            }
          }
          margin-bottom: variables.$space-xl;
        }
        &.hide{
          display: none;
        }
      }

      .video-category{
        .grid-sizer{
          width: 50%;
        }
   
        .grid-item{
          width: 93%;
          padding: 0;
          padding-bottom: 0;
          .img{
            height: auto;
            aspect-ratio: 16/9;
          }
          @media(min-width:992px){
            width: 48%;
            padding: 20px;
          }
        }
        .resource-category__product{
          margin-bottom: 16px;
        }
        .img{
          margin-bottom: 4px !important;
          cursor: pointer;
          height: 280px;
          &:hover{
            svg.icon-play{
              opacity: 0;
            }
            svg.icon-play-hover{
              opacity: 1;
            }
          }
          svg{
            position: absolute;
            transform: scale(1);
            top: 0;
            left: 0;
            right: 0;
            bottom:0;
            margin: auto;
            transition: all .3s ease-in-out;
            width: 52px;
            &.icon-play-hover{
              opacity: 0;
            }
          }
        }
      }
    }
    .video-modal {
      background-color: rgba(0,0,0,.5);
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height:100%;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 999;
      opacity: 0;
      z-index: -1;
      transform: scale(0);
      transition: all .5s ease-in-out;
  
      #close {
        position: absolute;
        top: 140px;
        right: 108px;
        color: variables.$gray-2;
        transform: scale(2);
        cursor: pointer;
  
        svg {
          width: 18px;
          height: 18px;
        }
      }
  
      &>div {
        width: 80%;
        min-height: 900px;
        display: flex;
        justify-content: center;
  
        video{
          height: 100%;
          width: 100%;
          outline: none;
        }
      }
  
      &.active {
        opacity: 1;
        z-index: 999;
        transform: scale(1);
      }
    }
  }
}

body.bannerGallery{
  .banner {
    padding-top: 0;
    position: relative;
    margin-bottom: 32px;
    .flickity-page-dots {
      bottom: 270px;
      width: 70%;
      .dot {
        height: 4px;
        width: 40px;
        margin: 0;
        border-radius: 0;
      }
      // Use line indicator on mobile
      @media(max-width: 767px){
        bottom: 215px;
        width: 100%;
      .dot{
        height: 4px;
        width: 40px;
        margin: 0;
        border-radius: 0; 
        }
      }
    }
    
    .flickity-button {
      color: rgba(255,255,255,.6);
      height: auto;
      padding: 8px;
      transition: all .25s linear;
    }
    .flickity-prev-next-button {
      border-radius: 0px;
      top: 425px;
      height: 100px;
      width: 50px;
      background-color: rgba(0,0,0,0);
      transition: all .25s linear;
      z-index: 999;
      &:hover {
        color: rgba(255,255,255,1);
        background-color: rgba(0,0,0,.2);
      }
      &.previous{
        left: 20px;
      }

      &.next{
        right: 250px;
        @media(max-width: 768px){
          right: 200px;
        }
      }
      // Hide arrows on mobile
      @media(max-width: 767px){
        display: none;
      }
    }

    .tout{
      position: absolute;
      width: 200px;
      color: variables.$gray-2;
      bottom:0;
      background-color: rgba(255,255,255,.5);
      opacity: 0;
      z-index: -1;
      right: 15px;
      transition: all 1s ease-in-out;
      &.active{
        opacity: 1;
        z-index: 99;
      }
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        margin-bottom: 1px;
        font-size: 12px;
        display: block;
        border-radius: 2px;
        color: variables.$gray-4;
        background-color: rgba(255,255,255,.75);
        &:hover{
          background-color: variables.$gray-2;
          color: variables.$white;
        }
      }
      // Hide products on mobile
      @media(max-width: 767px){
        display: none;
      }
      @media(min-width: 1024px){

        padding: 12px;
      }

    }
    .carousel {
      .flickity-viewport {
        min-height: 500px;

        .carousel-block{
          width: 100%;
          height: 700px;
          position: relative;
          .img{
            height: 500px;
            margin-bottom: 16px;
          }
          h3{
            margin-bottom: 0;
          }
        }
        @media(min-width: 1024px){
          // height: 30vw !important;
        }
      }
    }

    .info-box{
      background-color: rgba(255,255,255, .7);
      padding: 16px;
      z-index: 10;
      opacity: 0;
      transition: all 1s ease-in-out;

      a.cta{
        background-color: variables.$gray-3;
        color: variables.$white;
        font-size: 14px;
        padding: 8px 16px;
      }
      p{
        font-size: 14px;
        line-height: 24px;
      }

      &.active{
        opacity: 1;
        z-index: 99;
      }

      @media(min-width: 767px){
        position: absolute;
        top: 64px;
        left: 32px;
        width: 315px;
      }
    }

    @media(min-width: 767px){
      height: 700px;
    }
  }
}