body.landing {
  section {
    .container-fluid{
      min-height: 100vh;
      padding: 0;
    }
    .content {
      display: flex !important;
      // display: table-cell;
      // align-items: center;

      // @media(min-width:992px){
      //   width: 53%;

      // }

      // padding-bottom: 64px 32px;

      // @media(min-width:992px) {
      //   padding-left: 64px;
      // }

      .banner{
        width: 100%;
        padding: 100px 32px;
        background-size: cover;
        background-position: center;

        .col-12{
          background: rgba(255, 255, 255, 0.8);
          padding: 32px;
        }

        img{
          margin-bottom: 16px;
          max-width: 100%;
        }

        h1 {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: -0.34px;
          color: $navy;
          margin-bottom: 16px;
        }
  
        p {
          font-size: 18px;
          line-height: 28px;
          letter-spacing: .2px;
          color: $brand-gray-1;
          margin-bottom: 24px;
        }

        .btn{
          white-space: initial;
        }
      }

      .benefitsCopy{
        padding-top: 32px;
        h2{
          font-size: 46px;
          line-height: 55.2px;
          letter-spacing: -0.76;
          font-weight: 600;
        }
        h3{
          font-size: 30px;
          line-height: 36px;
          letter-spacing: -0.34;
          color: #00354B;
          font-weight: 400;
          text-align: center;
        }
        ol{
          counter-reset: item;
          li{
            position: relative;
            margin-bottom: 16px;
            &::marker{
              display: none !important;
            }
            &:before{
              content:counter(item);
              display: flex;
              align-items: center;
              justify-content: center;
              counter-increment: item;
              color: $white;
              font-weight: 20px;
              height: 30px;
              width: 30px;
              background-color: $blue;
              position: absolute;
              top: -3px;
              left: -36px;
              border-radius: 100%;
            }
          }
        }

        ul{
          list-style: none;
          margin-top: 32px;
          margin-bottom: 48px;
          padding: 0;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;

          li{
            background-color: #EBEDEC;
            padding: 32px;
            text-align: center;
            color: $black;
            width: 100%;
            p{
              color: $black;
              font-size: 18px;
              line-height: 28px;
              letter-spacing: 0.2px;
            }
          }

          @media(min-width: 767px){
            gap: 40px;
            grid-template-columns: repeat(3, 1fr);
            li{
              min-height: 245px;
            }
          }
        }


        & + .btn{

        }
      }

      .quotes{
        padding: 64px 0;
        background-color: #EBEDEC;
        .container{
          padding: 0;
        }
        .quote{
          padding: 0;
          width: 100%;
          h2{
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -2%;
            font-weight: 400;
            font-style: italic;
          }

          span{
            font-size: 19px;
            line-height: 34px;
            color: #6A7271;
          }
        }
        @media(max-width:767px){
          margin-bottom: 32px;
          .quote{
            padding: 32px 16px;
          }
        }
      }

      .imgText{
        padding-bottom: 32px;
        .row{
          min-height: initial;
        }
        img{
          max-width: 100%;
        }
        .copy{
          display: flex;
          align-items: center;
          h3{
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.34;
            color: #00354B;
            font-weight: 400;
          }
          p{
            color: $black;
          }
        }

        @media(max-width:767px){
          padding: 32px 16px;
        }
      }
    }

    img {
      margin-bottom: .5em;
    }

    .img {
      min-height: 100vh;
      background-size: cover;
      background-position: center;

      @media(max-width:992px) {
        min-height: 100vw;
        margin-bottom: 32px;
      }
    }

    .row {
      @media(max-width:992px) {
        flex-direction: column-reverse;
      }

      // display: table;
      // width: 103%;
      // table-layout: auto;
      overflow: hidden; 

      .col-lg-6.img{
        // margin-bottom: -99999px !important;
        // padding-bottom: 99999px !important;
        // float: none;
        // display: table-cell;
        // vertical-align: top;
        // @media(min-width:992px){
        //   width: 50%;

        // }
      }
    }

    form {
      margin-bottom: 32px;
      .inputs-list{
        padding: 0;
      }
      .hs-form-radio{
        list-style: url();
        span{
          padding-left: 8px;
        }
        &::marker{
          content: none;
        }
      }

      &#hsForm_8ad8924c-5a67-4e73-aea8-db1312e190e0 {
        & > .form-columns-1{
          width: 100% !important;
          float: initial;
        }
        select{
          width: 100%;
        }
        // & > .form-columns-2{
        //   width: 95% !important;
        //   float: initial;
        // }
        .hs-form-field {
          width: 100% !important;
        }
      }
    }


    a.btn.btn-orange{
      display: block;
    }

    .hs_submit.hs-submit {
      display: none;
    }
  }

  footer {
    margin-top: 0;
  }
}

body.thankYou {
  main {
    min-height: initial;
    section.thank-you-section {
      // min-height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;

      &.reduced-height{
        min-height: 35vh;
      }

      header {
        font-size: 46px;
        color: $navy;

        p,
        h1 {
          font-size: 46px;
          color: $navy;
          font-weight: 600;
          letter-spacing: -.76px;
        }
      }

      p {
        font-size: 28px;
        letter-spacing: -.2px;
        color: #78857F;
        line-height: 36px;
      }

      .hs-phone{
        float: left !important;
      }

      .hs_email,
      .hs_company,
      .hs_address {
        width: 100% !important;

        .input {
          input {
            width: 100% !important;
          }
        }
      }

    }
  }

}