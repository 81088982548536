.about{
  .col-lg-4{
    p{
      font-size: 18px !important;
    }
  }

  // .carousel{
  //   .flickity-viewport{
  //     @media(min-width:992px){
  //       aspect-ratio: 16 / 10;
  //     }
  //   }
  // }

  .carousel{
    .flickity-viewport{
      min-height: 418px;
      @media(min-width: 580px){
        min-height: 518px;
      }
      @media(min-width:992px){
        min-height: 700px;
      }
    }
  }

  .carousel-cell{
    container-type: inline-size;
  }

  @container (max-width: 931px){
    .carousel-cell .card{
      bottom: 100px !important;
    }
  }

  @container (max-width: 691px){
    .carousel-cell .card{
      bottom: 275px !important;
    }
  }
}