
@use "../base/typography";
@use "../base/variables";

.furnishMyOffice{
  section{
    margin-bottom: 6.4rem;
    &.banner{
      h1 + p{
        font-size: 22px;
        line-height: 27px;
        letter-spacing: -0.4px;
        color: variables.$gray-2;
      }
      .grid-banner{
        display: flex;
        flex-wrap: wrap;
        .grid{
          background-size: cover;
          background-position: center;
          min-height: 300px;
          width: 100%;
          padding: 40px;
          position: relative;

          @media(max-width: 500px){
            padding: 30px 20px;
          }

          @media(min-width:768px){
            width: 50%;
          }

          @media(min-width:992px){
            width: 33%;
          }

          h2{
            color: variables.$white;
            font-weight: typography.$weight-bold;
            font-size: 12vw;
            line-height: 110%;
            margin-bottom: 32px;
            letter-spacing: -0.64px;
            position: relative;

            &:after{
              content:"";
              background-color: variables.$white;
              width: 100%;
              height: 1px;
              position: absolute;
              top: -8px;
              left: 0;
            }

            @media(min-width: 768px){
              font-size: 250%;
            }

            @media(min-width: 1300px){
              font-size: 350%;
            }
          }
          p{
            color: variables.$white;
            font-size: 16px;
            line-height: 19.2px;
            display: inline;
          }
          svg{
            display: inline;
            vertical-align: middle;
          }
        }
      }
      & > p{
        @include typography.p-base;
      }
    }

    &.steps-guide{
      .intro-copy{
        margin-bottom: 1.6rem;
      }
      .steps{
        margin-bottom: 2rem;
        .block{
          .img{
            max-width: 100%;
            height: 250px;
            margin-bottom: 32px;
            background-size: cover;
          }
        }
      }
      .bottom-copy{
        p{
          color: variables.$gray-1;
          font-size: 1.6rem;
        }
      }
    }
  }

  .snippetTout{
    margin-bottom: 6.4rem;
  }

  .snippet{
    margin-bottom: 0;
  }

  footer{
    margin-top: 0;
  }
}