.container{
  @media(max-width:1300px){
    max-width: 960px;
  }
  @media(min-width:1301px){
    max-width: 1250px;
  }
}

// .form-control, body {
//   font-size: 1rem;
//   line-height: 1.5;
// }

footer#footer .logo > a{
  min-height: 56px;
}