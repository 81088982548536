body.search{
  main{
    ul.nav-list__desktop{
      @include list-no-style;
      display: flex;
      justify-content: space-between;
      margin: 64px 0px;
      li.nav-item{
        & > a{
          text-transform: uppercase;
          font-weight: $weight-bold;
          margin-bottom: 16px;
          display: block;
        }
        .desktop-submenu{
          padding: 0;
        }
      }
    }
  }
  .row{
    padding-bottom: 20px;

    p{
      span{
        display: inline;
        margin-left: 100px;
      }
    }
    .search-title{
      & > div{
        padding: 30px 0;
        color: $gray-2;
        height: 100%;
        position: relative;
        h3{
          @include h2;
          margin-bottom: 24px;
        }
        .img{
          background-size: contain;
          width: 90%;
        }
      }

      @media(max-width:992px){
        & > div{
          padding-bottom: 0;
          .img{
            display: none;
          }
          h3{
            padding-bottom: 0;
            margin-bottom: 16px !important;
          }
        }
      }
    }
    .search-description{
      padding-left: 0;
      position: relative;
      div{
        padding: 30px;
        font-size: 18px;
        letter-spacing: .2px;
        line-height: 26px;
        color: #808285;
        background-color: #F0F0F0;
        a{
          display: block;
          margin-top: 16px;
          font-size: 16px;
          color: #58595B;
          font-weight: $weight-bold;
          svg{
            margin-left: 8px;
          }
        }
      }
    }

    &.configurator,
    &.product{
      .search-description{
        & > div{
          padding-bottom: 0;
        }
      }
    }

    .download-revit-toggle{
      padding: 0px 30px;
      padding-top: 30px;
      background-color: #F0F0F0;
      overflow: hidden;
      max-height: 100px;
      transition: max-height .3s ease-in-out;

      div:first-child{
        padding-bottom: 30px;
        cursor: pointer;
        font-size: 16px;
        color: #58595B;
        font-weight: $weight-bold;
        svg{
          margin-left: 8px;
          transition: all .2s ease-in-out;
        }
      }
      .application{
        max-height: 0;
        overflow: hidden;
        transition: all .2s ease-in-out;
      }
    }

    .is-open{
      position: relative;
      background: $white;
      border: 5px solid #F0F0F0;
      border-top: 15px solid #F0F0F0;
      padding: 0px 25px;
      padding-top: 25px;
      [data-product-title]{
        padding-bottom: 16px !important;
        svg{
          transform: rotate(90deg);
        }
      }
      & >div{
        z-index: 10;
        position: relative;
      }

      max-height: 1000%;
      .application{
        max-height: 1000%;
      }
    }

    .application{
      padding: 0 !important;
      .row{
        padding: 0 !important;
      }
      .application-wrapper{
        padding: 0 !important;
        img{
          width: 100%;
        }

        p{
          font-size: 16px;
          color: $gray-2;
          letter-spacing: .4px;
          line-height: 24px;
          font-weight: 400;
        }

        [class*="col-"]{
          padding-bottom: 0;
        }

        ul{
          width: 100%;
          list-style: none;
          padding: 0;
          overflow: hidden;
          cursor: pointer;
          li{
            margin-bottom: 0px;
            padding: 12px 16px;
    
            cursor: pointer;
            border-bottom: 1px solid $gray-6;
            width: 100%;        
            margin: 0;
            font-weight: 400;
            color: $gray-2;
            i,svg{
              margin-right: 8px;
            }
  
            span{
              float: right;
            }
            &:hover{
              background-color: #EBEDEC;
            }
    
            &:last-child{
              border-bottom: 0;
            }
          }
        }
      }
    }

  }

  .filters-row{
    margin: 64px 0;
    position: relative;
    border: 1px solid #58595B;
    width: 300px;
    display: inline-block;
    margin-right: 16px;
    select{
      border: 1px solid #979797;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
      font-weight: $weight-bold;
      color: #58595B;
      font-size: 16px;
      line-height: 19px;
      option{
        padding: 10px 13px;
      }
    }
  }

  /* filters */

	/*the container must be positioned relative:*/
	.filters-row select {
		display: none ; /*hide original SELECT element:*/
	}
	.select-selected{
    color: #58595B;
		width: 250px;
    display: inline-block;
    border: 1px solid #58595B;
    &:after {
      position: absolute;
      content: "";
      top: 18px;
      right: 8px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: #778F9B transparent transparent transparent;
      transition: all .4s ease-in-out;
    }
    &:before{
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width:100%;
      height: 100%;
    }
  }

	/*style the items (options), including the selected item:*/
	.select-items div,.select-selected {
		padding: 8px 16px;
		border: 1px solid transparent;
		cursor: pointer;
		user-select: none;
		font-size: 16px;
    font-weight: $weight-regular;
	}
	.select-items div{
		padding: 8px 24px;
	}
	/*style items (options):*/
	.select-items {
		position: absolute;
		top: 93%;
		left: -1px;
		right: 0;
		z-index: 99999999;
		background-color: white;
		font-size: 16px;
		border: 1px solid $gray-2;
		border-top: none;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
		width: 100.7%;
	}
	/*hide the items when the select box is closed:*/
	.select-hide {
		display: none;
	}
	.select-items div:hover, .same-as-selected {
		background-color:$gray-2;
		color: $white !important;
	}
	
	@media (max-width: 768px) {
		.filters-row, .filters {
			flex-direction: column;
		}
		.filters-row{
			margin: 4px;
			width: 46%;
			display: inline-block;
			vertical-align: top;
			a{
				padding: 3px 20px;
			}
		} 
	}
}