
@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

footer {
  background-color: variables.$navy;
  padding: 64px 0;
  margin-top: 128px;

  &.no-scroll{
    display: none;
  }

  .row {
    justify-content: space-between;

    .col-12{
      margin-bottom: 32px;
      @media(min-width: 992px){
        padding: 0;
      }
    }

    .logo {
      strong {
        color: variables.$white;
        letter-spacing: .7px;
        line-height: 16px;
        text-transform: uppercase;
      }

      p {
        font-size: 15px;
        letter-spacing: .2px;
        color: variables.$gray-5;
        line-height: 24px;
      }

      a {
        margin-bottom: variables.$space-md;
        display: block;

        svg {
          max-width: 120px;
          color: variables.$white;
        }
      }
    }

    a {
      color: variables.$gray-4;
      font-weight: typography.$weight-regular;
      letter-spacing: .85px;
      transition: all .25s ease-in-out;
      svg {
        color: variables.$gray-6;
      }

      &.btn {
        font-weight: typography.$weight-bold;
        color: variables.$white;
        text-transform: uppercase;
        transition: all .25s ease-in-out;
        border: 2px solid variables.$gray-3;
        @media(min-width: 767px){
          padding: 14px 0;
        }
        &:hover{
          color: variables.$gray-2 !important;
          background-color: variables.$white;
          border: 2px solid variables.$white;
        }
        @media(min-width: 992px){
          position: absolute;
          top:0;
          left:0;
          width: 100%;
        }
      }
    }

    ul.nav-list {
      @include mixins.list-no-style;

      li.nav-item {
        a{
          font-size: 15px;
          letter-spacing: .2px;
          line-height: 28px;
          color: variables.$gray-5;
          transition: all .25s ease-in-out;
          &:hover{
            color: variables.$white;
          }
        }
        &.first {
          @include typography.swiss-medium;
          text-transform: uppercase;
          margin-bottom: variables.$space-md;

          a {
            color: variables.$white !important;
            font-weight: typography.$weight-bold;
          }
        }
      }
    }
  }

  .copyright {
    color: variables.$gray-5;
    font-size: 12px;
    @media(min-width: 767px){
      margin-left: -16px;
    }
  }

  .logout{
    color: variables.$white;
  }

  .social{
    .h5{
      display: block;
      padding-top: 32px;
      color: variables.$white;
      margin-bottom: 32px;
      @media(min-width: 767px){
        padding-top: 80px;
      }
    }
    ul{
      @include mixins.list-no-style;
      display: flex;
      li{
        margin-right: 32px;

        a{
          svg,path,g{
            transition: all .25s ease-in-out;
            fill: #A9AAAC;
          }
          &:hover{
            svg{
              fill: variables.$white;
              path, g{
                fill: variables.$white;
              }
            }
          }
        }
      }
    }
  }
}