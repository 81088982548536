.additionalModule{
  margin-bottom: 32px;
  &.displayAssets{
    .img{
      width: 100%;
      cursor: pointer;
      height: 120px;
      background-size: contain;
    }
    figcaption{
      color: $acqua;
      text-align: center;
      margin-top: $space-sm;
    }
  }
}
