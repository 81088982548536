@use "../base/mixins";
@use "../base/typography";
@use "../base/variables";

.productsCategory{
  footer {
    margin-top: 0;
  }
  .products-main{
    background-color: variables.$body-background-alt;
    padding: 50px 0px 200px 0px;
    .title{
      h1{
        margin-bottom: variables.$space-lg;
        width: 100%;
        font-size: 40px;
      }
      a{
        color: variables.$acqua;
      }
    }

    img{
      width: 100%;
    }

    .category-nav{
      ul{
        @include mixins.list-no-style;
        li{
          margin-bottom: variables.$space-lg;
          cursor: pointer;
          letter-spacing: .4px;
          font-size: variables.$space-md;
          svg{
            margin-left: 8px;
          }
        }
      }
    }

    .content{
      padding: 0;
      h2{
        font-weight: typography.$weight-regular;
        color: #333333;
        font-size: 28px;
        line-height: 34px;
        padding: 0 8px;
        padding-top: 12px;
      }
      .category{
        width: 100%;
        // transition: all .25s ease-in-out;
        &:not(:first-child){
          margin-top: 80px;
        }
        .grid{
          margin-top: 32px;
          .grid-item{
            @media(min-width: 992px){
              margin-bottom: 26px;
            }
            a{
              padding: 7.5px;
            }          
          }
          .grid-sizer{
            width: 33%;
            @media(max-width:992px) {
              width: 100%;
            }
          }
        }

        .popular-tout{
          text-align: center;
          padding: variables.$space-md 0px;
          margin: 0 8px;
          border-bottom: 1px solid variables.$gray-6;
          border-top: 1px solid variables.$gray-6;
          margin-top: variables.$space-md;
          letter-spacing: .2px;
          font-size: 18px;
          color: variables.$gray-2;
          svg{
            fill: variables.$acqua;
            g{
              fill: variables.$acqua;
            }
          }
          a{
            padding-left: variables.$space-md;
            font-size: 18px;
            color: #333333;
            font-weight: typography.$weight-bold;
          }
        }

        &:not(:first-child){
          &.active{
            margin-top: 0 !important;
          }
        }
      }
      .filter-configuration{
        .grid{
          margin-top: 0px;
          .grid-item{
            @media(min-width: 992px){
              margin-bottom: 26px;
            }
            a{
              padding: 7.5px;
            }          
          }
          .grid-sizer{
            width: 33%;
            @media(max-width:992px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}