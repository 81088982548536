body.showroom {
  // #footer{
  //   margin-top: 0;
  // }
  .col-12 {
    
  }
  .container{
    &.body{
      margin-top: 0;
      margin-bottom: 0;
      // margin-bottom: -64px;
      p{
        margin-bottom: 0;
        // display: none;
      }
      & >  div{
        &:first-child{
          margin: auto;
          max-width: 405px;
          .btn{
            display: block;
            margin: 64px auto;
            font-size: 24px;
            width: fit-content;
            @media(max-width: 767px){
              width: 100%;
              white-space: break-spaces;
              line-height: 32px;
            }
          }
          .note{
            text-align: center;
            margin: auto;
            display: block;
            margin-top: 8px;
            margin-bottom: 64px;
            color: $orange;
          }
        }
      }

    }

  }

  &.interactive-lookbook{
    [data-popup-form="findADealer"]{
      display: none !important;
    }
    .container.body{
      margin-bottom: 64px;
    }
  }
}